import React, {useState, useEffect, useMemo, useRef, forwardRef} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from "reactstrap"
import { TextField } from '@material-ui/core';
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from "react-table"
import { Table, Row, Col, Button as Button2, Input } from "reactstrap"

import api from '../../../../../../../../services/api'
import { getUser} from "../../../../../../../../services/auth";


import { Logo2 } from './styles';

import logo_icon from '../../../../../../../../img/icon.png';
import DialogTitle from '../../../DialogTitle';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const Filter = ({ column }) => {
    return (
      <div style={{ marginTop: 5 }}>
        {column.canFilter && column.render("Filter")}
      </div>
    )
}
export const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) => {
    return (
      <Input
        bsSize="sm"
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Procurar (${length}) ...`}
      />
    )
}
const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef()
      const resolvedRef = ref || defaultRef
  
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
)


const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));



function ModalAddAgenda(props) {
    const classes = useStyles();
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [open, setOpen] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsFolga, setSelectedRowsFolga] = useState([]);
    const [selectedRowsFerias, setSelectedRowsFerias] = useState([]);

    const [disabled, setDisabled] = useState(false);
   

    var {idTreina, onClose, altera, setAltera, empresa, contrato, filial, id, ...rest} = props;
    function salvar_pergunta(){
        var date = document.getElementById('date').value;
        if(date === ""){
            setOpen3({
                show: true,
                text: 'Obrigatorio preencher a data!',
                type: 'warning'
            });
            return false;
        }
        if(selectedFlatRows.length === 0){
            setOpen3({
                show: true,
                text: 'Obrigatorio marcar pelo menos um funcionario!',
                type: 'warning'
            });
            return false;
        }
        let func = selectedFlatRows.map((dados) => {
            return dados.original.id;
        })
        if(id === 0){
            setOpen(true);
            api.post('treinamento/agenda/presenca',
            { 
                idTreina,
                data: date,
                func, 
                user: getUser(),

            })
            .then(function(response){
                setOpen(false);
                var data = response.data;
                var {status, msg} = data;
                if(status === 1){
                    var cont = altera;
                    cont++;
                    onClose();
                    setAltera(cont);
                    
                }else{
                    setOpen3({
                        show: false,
                        text: msg,
                        type: 'warning'
                    });
                }
            });
        }else{
            setOpen(true);
            api.post('treinamento/agenda/presenca/atual',
            { 
                idTreina,
                id,
                func, 
                folgas: selectedRowsFolga,
                ferias: selectedRowsFerias,
                user: getUser(),

            })
            .then(function(response){
                setOpen(false);
                var data = response.data;
                var {status, msg} = data;
                if(status === 1){
                    var cont = altera;
                    cont++;
                    onClose();
                    setAltera(cont);
                   
                }else{
                    setOpen3({
                        show: false,
                        text: msg,
                        type: 'warning'
                    });
                }
               
            });
        }
    }
    const updateFolgaFerias = (rowid, columnid, value) => {
        if(value){
            if(columnid === "Folga"){
                const add = selectedRowsFolga;
                add.push(rowid)
                setSelectedRowsFolga(add)
            }
            if(columnid === "Ferias"){
                const add = selectedRowsFerias;
                add.push(rowid)
                setSelectedRowsFerias(add)
            }
        }else{
            if(columnid === "Folga"){
                const add = selectedRowsFolga.filter((value) => value !== rowid);
                setSelectedRowsFolga(add)
            }
            if(columnid === "Ferias"){
                const add = selectedRowsFerias.filter((value) => value !== rowid);
                setSelectedRowsFerias(add)
            }
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: "Mat",
                accessor: "CODFUNC",
            },
            {
                Header: "Nome",
                accessor: "NOMEFUNC",
            },
            {
                Header: "Função",
                accessor: "DESCFUNCAOCOMPLETA",
            },
            {
                Header: "Folga",
                accessor: "Folga",
                Cell: row => {
                    return(
                        <div style={{textAlign: "center"}}>
                            <input type="checkbox" 
                                defaultChecked={row.value} 
                                onClick={(event) => updateFolgaFerias(parseInt(row.row.original.id), row.column.id, event.target.checked)} 
                            />
                        </div>
                    )
                }
            },
            {
                Header: "Ferias",
                accessor: "Ferias",
                Cell: row => {
                    return(
                        <div style={{textAlign: "center"}}>
                            <input type="checkbox" 
                                defaultChecked={row.value} 
                                onClick={(event) => updateFolgaFerias(parseInt(row.row.original.id), row.column.id, event.target.checked)} 
                            />
                        </div>
                    )
                }
            },
        ] 
    ,[]);
    const [data, setData] = useState([])
    useEffect (() => {
        async function carregar_func(){
            setOpen(true);
            await api.get('/funcionarios/all',
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                setData(response.data)
                setOpen(false);
            });
        }
        async function carregar_agenda_func(){
            setOpen(true);
            await api.get('/treinamento/agenda/func/' + id,
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let {data, date} = response.data;
                date = date.split("/").reverse().join("-");
                document.getElementById('date').value = date;
                let where2 = [];
                let dados = data;
                let cont = 0;
                const folgas = []
                const ferias = []
                dados.forEach(row => {
                    let o2 = null;
                    if(row.selected === true){
                        o2 = { 
                            [cont]: true,
                        };
                    }
                    if(row.Folga === true){
                        folgas.push(row.id)
                    }
                    if(row.Ferias === true){
                        ferias.push(row.id)
                    }
                    where2.push(o2);
                    cont++;
                })
                setSelectedRowsFolga(folgas)
                setSelectedRowsFerias(ferias)
                
                setSelectedRows(where2);
                setData(data)
                
            });
        }
        if(id === 0){
            carregar_func();
        }else{
            carregar_agenda_func();
            setDisabled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        selectedFlatRows,
        state: { pageIndex }
        } = useTable({
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { 
                pageIndex: 0, 
                pageSize: 10,
                selectedRowIds: selectedRows,
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )
    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }
      
    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }
    
    return (<Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false} open={open}>
            {id === 0 ? 
            ("Incluir Agendamento")
            : ("Agendamento")}
        </DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={2}>
                            <Form.Group> 
                            <InputLabel className={classes.linha} 
                            htmlFor="date">Data</InputLabel>
                           <TextField
                                type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="date" 
                                placeholder='dd/mm/aaaa'
                                disabled={disabled}
                            />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {data ? (
                                <Container>
                                    <Table 
                                        striped 
                                        size="sm"
                                        {...getTableProps()}
                                    >
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps()}>
                                                            <div {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                            {column["Header"] !== "Ferias" && column["Header"] !== "Folga" && (
                                                               <Filter column={column} /> 
                                                            )}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody
                                            {...getTableBodyProps()}
                                        >
                                            {page.map(row => {
                                                prepareRow(row);
                                                return (
                                                    <tr className={classes.linha} {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
                                        <Col md={3}>
                                            <Button2
                                                color="primary"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<<"}
                                            </Button2>
                                            <Button2
                                                color="primary"
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<"}
                                            </Button2>
                                            </Col>
                                            <Col md={2} style={{ marginTop: 7 }}>
                                            Page{" "}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>
                                        </Col>
                                        <Col md={2}>
                                            <Input
                                                type="number"
                                                min={1}
                                                style={{ width: 70 }}
                                                max={pageOptions.length}
                                                defaultValue={pageIndex + 1}
                                                onChange={onChangeInInput}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Button2 color="primary" onClick={nextPage} disabled={!canNextPage}>
                                                {">"}
                                            </Button2>
                                            <Button2
                                                color="primary"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {">>"}
                                            </Button2>
                                            </Col>
                                    </Row>
                                </Container>
                            ): null}
                        </Grid>
                    </Grid>
                     
                </Grid> 
            )}
            </DialogContent>
            {open !== true ? (
                <DialogActions>
                    <Button color="secondary" onClick={props.onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => salvar_pergunta()} >Salvar</Button>
                </DialogActions>
            ): null}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default ModalAddAgenda;