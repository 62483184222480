import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import Styles from '../../Styles';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';

import DialogTitle from '../Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";
import { FormHelperText } from '@material-ui/core';
import CustomizedProgressBars from '../../../pages/Treinamentos/components/ModalAdd/components/CustomizedProgressBars';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalAddDocument(props) {
    const classes = Styles();
    let uploadObj = useRef(UploaderComponent);
    var {empresa, contrato, filial, ope, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(false);
    const [progress, setProgress] = useState(0);
    const [formPlaca, setFormPlaca] = useState({
        error: false,
        helperText: '',
    })
    const [formDocument, setFormDocument] = useState({
        error: false,
        helperText: '',
    })
    const [formData, setFormData] = useState({
        error: false,
        helperText: '',
    })
    const [placas, setPlacas] = useState([]);
    const [documents, setDocuments] = useState([]);
    useEffect (() => {
        async function carrega_dados_placa(){
            setOpen(true);
            await api.get('/placas',
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                            contrato, 
                            filial,
                            ope
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let placas = [];
                let o2 = { 
                    id: 0,
                    placa: 'Selecione...', 
                };
                
                placas.push(o2);
                let data = response.data;
                data.forEach(element => {
                    let add = { 
                        id: element.id,
                        placa: element.placa, 
                    };
                    placas.push(add);
                });
                setPlacas(placas)
                setValue(o2)
            });
        }
        async function carrega_dados_documento(){
            setOpen(true);
            await api.get('/checklist/documentotipo',
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                            contrato, 
                            filial,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let { status, data } = response.data;
                if(status === true){
                    setDocuments(data)
                }else{
                    setDocuments([])
                }
            });
        }
        carrega_dados_placa();
        carrega_dados_documento();
      }, [contrato, empresa, filial, ope]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salvar(){
        let documento = document.getElementById('documemt_tipo_add').value;
        let data = document.getElementById('data_doc_add').value;
        const filesData = uploadObj.getFilesData();
        setFormPlaca({
            error: false,
            helperText: '',
        })
        setFormDocument({
            error: false,
            helperText: '',
        })
        setFormData({
            error: false,
            helperText: '',
        })
        if(value == null){
            setFormPlaca({
                helperText: 'Selecione a placa!',
                error: true
            })
            return false;
        }
        if(value.id === 0){
            setFormPlaca({
                helperText: 'Selecione a placa!',
                error: true
            })
            return false;
        }
        if(documento === ""){
            setFormDocument({
                helperText: 'Selecione o documento!',
                error: true
            })
            return false;
        }
        if(data === ""){
            setFormData({
                helperText: 'Preencha a data!',
                error: true
            })
            return false;
        }
        let verAnexo = false;
        documents.forEach(element => {
            if(element.id == documento){
                if(element.anexo == 1){
                    verAnexo = true;
                }
            }
        });
        if(verAnexo){
            if(filesData.length == 0){
                setOpen3({
                    show: true,
                    text: "Selecione um arquivo!",
                    type: 'error'
                });
                return false;
            }
        }
        setOpen(true);
        setOpen2(true);
        var formData = new FormData();
        if(filesData.length > 0){
            formData.append('file', filesData[0].rawFile);
        }   
        formData.append('empresa', empresa);
		formData.append('contrato', contrato);
		formData.append('filial', filial);
        formData.append('user', getUser());
        formData.append('veiculo',  value.id);
        formData.append('tipo', documento);
        formData.append('data', data);
        var config = {
            onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                setProgress(percentCompleted);
            }
		};
        api.post('/checklist/documento',
        formData,
        config)
        .then(function(response){
            setOpen(false);
            setOpen2(false);
            let {status, message} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Cadastrado com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
        })
    }
    const [value, setValue] = useState(null);
    const verificaDocumentoAnexo = () => {
        let value = document.getElementById("documemt_tipo_add").value;
        let anexo = false;
        documents.forEach(documento => {
            if(documento.id == value){
                if(documento.anexo == 1){
                    anexo = true;
                }
            }
        });
        let boxAnexo = document.getElementById("box_anexo");
        if(anexo){
            boxAnexo.style.display = "block";
        }else{
            boxAnexo.style.display = "none";
        }
    }
    let onFileSelected = (args) => {
        let existingFiles = uploadObj.getFilesData();
        const filesData = uploadObj.getFilesData();
        if(filesData.length > 0){
            if(args.filesData[0].name !== filesData[0].name){
                uploadObj.filesData.splice(0, 1);
            }
        }
        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
        
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Adicionar Documento</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="placa_doc_add">Placa</InputLabel>
                            {placas && (
                                <Autocomplete
                                    id="placa_doc_add"
                                    size="small"
                                    openOnFocus
                                    options={placas}
                                    getOptionLabel={(option) => option.placa}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) =><TextField
                                    {...params} className={classes.textField} />}
                                    />
                            )}
                            <FormHelperText  error={formPlaca.error}>{formPlaca.helperText}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="documemt_tipo_add">Documento</InputLabel>
                            <Select
                                native
                                error={formDocument.error}
                                size="small"
                                id="documemt_tipo_add"
                                className={classes.textField}
                                onChange={verificaDocumentoAnexo}
                                >
                                    <option value={''}>Selecione...</option>
                                    {documents.map((dados) => (
                                        <option key={dados.id} value={dados.id}>{dados.nome}</option>
                                    ))}
                            </Select>
                            <FormHelperText  error={formDocument.error}>{formDocument.helperText}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="data_doc_add">Data</InputLabel>
                           <TextField
                                size="small"
                                error={formData.error}
                                helperText={formData.helperText}
                                id="data_doc_add"
                                type="date"
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid id="box_anexo" item xs={12} style={{display: "none"}}>
                        <Form.Group className={classes.disabled}> 
                            <UploaderComponent 
                            locale='pt-PT' 
                            id="anexo_documento_add" 
                            type = 'file'
                            ref = {upload => { uploadObj = upload; }}
                            selected={onFileSelected = onFileSelected.bind(this)}
                            allowedExtensions='.pdf, .jpg, .jpeg, .png'
                            maxFileSize= {62914560}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary"  onClick={onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => {salvar()}}>Salvar</Button>
                </DialogActions>
            )}
            {open2 && <CustomizedProgressBars progress={progress} />}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalAddDocument;