import React, {useState} from 'react';

import { Container2, Logo2,  Header } from '../../../../styles/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../components/Styles';
import ModalChat from '../ModalChat';


import {Badge as Badgemat} from '@material-ui/core';

import { getUser} from "../../../../services/auth";

import { Form } from 'react-bootstrap';

import logo_icon from '../../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import MailIcon from '@material-ui/icons/Mail';

import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react"; 

import { CSVLink } from "react-csv";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


function Body({empresas, contratos, filiais, mesdados}) {
    const classes = Styles();
    const [value, setValue] = useState(0);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
   
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [modalShow, setModalShow] = useState({
        show: false,
        id: 0,
    });
    const handleOpen2 = (nome, item, id, status, ref) => {
        setModalShow({
            show: true,
            id,
            status,
            nome,
            item,
            ref,
        });
    };
    
      const handleClose2 = () => {
        setModalShow({
            show: false,
            id: 0,
            status: "",
            nome: "",
            item: "",
            ref: "",
        });
        var {empresa, contrato, filial, mes} = state;
        api.get('/precontra/all',
        { 
            headers: { 
                    empresa, 
                    mes, 
                    contrato,
                    filial,
                    user: getUser(),
                } 
            }
        )
        .then(function (response) {
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    delete column.msg_new;
                    delete column.Mensagens;
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
        });
      };
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        mes: "",
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    function consultar_pre(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        handleToggle();
        var {empresa, contrato, filial, mes} = state;
        api.get('/precontra/all',
        { 
            headers: { 
                    empresa, 
                    mes, 
                    contrato,
                    filial,
                    user: getUser(),
                } 
            }
        )
        .then(function (response) {
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    delete column.msg_new;
                    delete column.Mensagens;
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }
   
   
   


  return ( 
  <Container2>
    <Header>
        Pré-contracheque
    </Header>
    <Tabs
    value={value}
    onChange={handleChange}
    variant="scrollable"
    scrollButtons="on"
    indicatorColor="primary"
    textColor="primary"
    aria-label="scrollable force tabs example"
    >
        <Tab label="Criticas"  {...a11yProps(0)} />
    </Tabs>
    <TabPanel value={value} index={0}>
    <Form>
        <Grid container spacing={3}>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                <Select
                native
                value={state.empresa}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'empresa',
                    id: 'abast_empresa',
                }}
                >
                    {empresas.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                    
                </Select>
            </Grid>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                <Select
                native
                value={state.contrato}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'contrato',
                    id: 'abast_contrato',
                }}
                >
                    {contratos.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                </Select>
            </Grid>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                <Select
                native
                value={state.filial}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'filial',
                    id: 'abast_filial',
                }}
                >
                    {filiais.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                </Select>
            </Grid>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="mes_ref">Mês</InputLabel>
                <Select
                native
                value={state.mes}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'mes',
                    id: 'mes_ref',
                }}
                >
                    <option value="">Selecione...</option>
                    {mesdados ? (
                        mesdados.map((dados, index) => (<option key={dados.id} value={dados.id}>{dados.descr}</option>))
                    ) : (
                        null
                    )}
                </Select>
            </Grid>
            
            <Grid item xs="auto">
                <Tooltip title="Consultar">
                    <IconButton aria-label="Consultar" onClick={() => consultar_pre()} 
                    color="primary" variant="contained">
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs="auto">
                <CSVLink data={exportCsv} filename={"socorro.csv"}>     
                    <Tooltip title="Exportar csv">
                        <IconButton aria-label="Exportar csv" variant="contained">
                            <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                        </IconButton>
                    </Tooltip>
                </CSVLink>
            </Grid> 
        </Grid>
    </Form>
    <Box component="span" m={1}>
        {open ? (
            <Logo2>
                <img src={logo_icon} alt="" />
                <CircularProgress />
            </Logo2>
        ) : (
            <>
                {lista && (
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (<Table.ColHeader className={classes.th} key={dados.text}>{dados.text}</Table.ColHeader>);
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                if(name === "Status"){
                                                    if(dados[name] === "Finalizado"){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                             <div className={classes.linha2}>
                                                                <Badge color="success" className="mr-1">
                                                                    Finalizado
                                                                </Badge>
                                                            </div>
                                                        </Table.Col>);
                                                    }else{
                                                        if(dados[name] === "Aprovado"){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <div className={classes.linha2}>
                                                                    <Badge color="success" className="mr-1">
                                                                        Aprovado
                                                                    </Badge>
                                                                </div>
                                                            </Table.Col>);
                                                        }else{
                                                            if(dados[name] === "Reprovado"){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <div className={classes.linha2}>
                                                                        <Badge color="danger" className="mr-1">
                                                                            Reprovado
                                                                        </Badge>
                                                                    </div>
                                                                </Table.Col>);
                                                               
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <div className={classes.linha2}>
                                                                        <Badge color="warning" className="mr-1">
                                                                            Em andamento
                                                                        </Badge>
                                                                    </div>
                                                                </Table.Col>);
                                                            }
                                                        }
                                                    }
                                                }else{
                                                    if(name === "Mensagens"){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                            <div className={classes.linha2}>
                                                                <IconButton aria-label="Mensagem" size="small" onClick={() => handleOpen2(dados["Nome"], dados["Item"], dados["id"], dados["Status"], dados["Mês"])}>
                                                                    <Badgemat badgeContent={dados[name]} max={99} color="error">
                                                                        <MailIcon color="primary" fontSize="small" />
                                                                    </Badgemat>
                                                                </IconButton>
                                                            </div>
                                                        </Table.Col>);
                                                        
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}><div className={classes.linha2}>{dados[name]}</div></Table.Col>);
                                                    }
                                                }
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                )}
            </>
        )}
        </Box>
    </TabPanel>
    <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity={open3.type}>
            {open3.text}
        </Alert>
    </Snackbar>
    {modalShow.show ? (
        <ModalChat
            open={modalShow.show}
            onClose={() => handleClose2()}
            maxWidth="sm"
            fullWidth={true}
            id_coment={modalShow.id}
            status={modalShow.status}
            item={modalShow.item}
            nome={modalShow.nome}
            mes={modalShow.ref}
        />
    ):null}
</Container2>);
}

export default Body;