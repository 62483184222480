/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import Styles from '../../../../components/Styles';
import DialogTitle from '../../../../components/BoardCheck/Components/DialogTitle'
import { getMat, getUser} from "../../../../services/auth";
import { Divider, IconButton } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit(props) {
    const [open2, setOpen2] = useState(false);
    const classes = Styles();
    var {id, consulta, onClose, empresa, contrato, filial, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [arquivos, setArquivos] = useState([]);
    const [classificacoes, setClassificacoes] = useState([]);
    const [ preenchido, setPreenchido] = useState('');
    async function carrega_dados(){
        setOpen(true);
        await api.get('/absenteismo/show/' + id,
            { 
                headers: { 
                        user: getMat(),
                        tipo: 1,
                    } 
                }
            )
        .then(function (response) {
            setOpen(false);
            var { status, data } = response.data;
            if(status){
                let { periodo, funcionario, classificacao, comentario, arquivos, classificacoes, data_preenchimento_funcionario} = data;
                document.getElementById('entrevista_periodo').value = periodo;
                document.getElementById('entrevista_colaborador').value = funcionario['NOMEFUNC'];
                document.getElementById('entrevista_funcao').value = funcionario['DESCFUNCAOCOMPLETA'];
                document.getElementById('entrevista_classificacao').value = classificacao['tipo'];
                document.getElementById('entrevista_tipo').value = classificacao['nome'];
                document.getElementById('entrevista_comentario').value = comentario;
                setArquivos(arquivos);
                setClassificacoes(classificacoes)
                setPreenchido(data_preenchimento_funcionario);
            }
        });
    }
    const [usuario, setUsuario] = useState([]);
    async function carrega_func(){
        setOpen(true);
        await api.get('/usuarios',
            { 
                headers: { 
                        empresa,
                        contrato,
                        filial
                    } 
                }
            )
        .then(function (response) {
            setOpen(false);
            let data = response.data;
            if(data != null){
                setUsuario(data);
            }
        });
    }

    useEffect (() => {
        carrega_dados();
        carrega_func()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const [validaMuda, setValidaMuda] = useState(false);
    const [valida, setValida] = useState("");
    const handleChange3 = (event) => {
        const value = event.target.value;
        // eslint-disable-next-line eqeqeq
        if(value == 0){
            setValidaMuda(true)
        }else{
            setValidaMuda(false)
        }
        setValida(value);
        
    };
    const [mudaClassificacao, setMudaClassificacao] = useState("");
    const [mudaTipo, setMudaTipo] = useState("");
    const handleChangeMudaClassificacao = (event) => {
        const value = event.target.value;
        setMudaClassificacao(value);
        let temp = null;
        let array1 = [{
            id: 0,
            tipo: '',
            nome: 'Selecione...'
        }]
        if(value == 1){
            temp = classificacoes.filter(element => {
                return element.tipo === 'Justificada'
            });
        }
        if(value == 2){
            temp = classificacoes.filter(element => {
                return element.tipo === 'Não Justificada'
            });
        }
        if(value == 3){
            temp = classificacoes.filter(element => {
                return element.tipo === 'Prevista Legislação'
            });
        }
        const mergeResult = [...array1, ...temp];
        setMudaTipo(mergeResult);
    }
    const [mudaTipoValue, setMudaTipoValue] = useState("");
    const handleChangeMudaTipoValue = (event) => {
        const value = event.target.value;
        setMudaTipoValue(value);
    }
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [formValidar, setValidar] = useState({
        error: false,
        helperText: '',
    })
    const [formOcorrencia, setOcorrencia] = useState({
        error: false,
        helperText: '',
    })
    const [formComunicacao, setComunicacao] = useState({
        error: false,
        helperText: '',
    })
    const [formApresentacao, setApresentacao] = useState({
        error: false,
        helperText: '',
    })
    const [formGestor, setGestor] = useState({
        error: false,
        helperText: '',
    })
    const [formClassificacao, setFormClassificacao] = useState({
        error: false,
        helperText: '',
    })
    const [formTipo, setFormTipo] = useState({
        error: false,
        helperText: '',
    })
    function salva_etapas(){
        let valida = document.getElementById('entrevista_rh_valida').value;
        let ocorrencia = document.getElementById('pergunta1').value;
        let comunicacao = document.getElementById('pergunta2').value;
        let apresentacao = document.getElementById('pergunta3').value;
        let gestor = document.getElementById('entrevista_gestor_validar').value;
        setValidar({
            error: false,
            helperText: '',
        })
        setOcorrencia({
            error: false,
            helperText: '',
        })
        setComunicacao({
            error: false,
            helperText: '',
        })
        setApresentacao({
            error: false,
            helperText: '',
        })
        setGestor({
            error: false,
            helperText: '',
        })
        setFormClassificacao({
            error: false,
            helperText: '',
        })
        setFormTipo({
            error: false,
            helperText: '',
        })
        
        if(valida === ""){
            setValidar({
                helperText: 'Selecione!',
                error: true
            })
            return false;
        }
        if(ocorrencia === ""){
            setOcorrencia({
                helperText: 'Selecione!',
                error: true
            })
            return false;
        }
        if(comunicacao === ""){
            setComunicacao({
                helperText: 'Selecione!',
                error: true
            })
            return false;
        } 
        if(apresentacao === ""){
            setApresentacao({
                helperText: 'Selecione!',
                error: true
            })
            return false;
        } 
        if(gestor === ""){
            setGestor({
                helperText: 'Selecione!',
                error: true
            })
            return false;
        } 
        let classificacao = null;
        let tipo = null;
        if(valida == 0){
            classificacao = mudaClassificacao;
            tipo = mudaTipoValue;
            if(classificacao === ""){
                setFormClassificacao({
                    helperText: 'Selecione!',
                    error: true
                })
                return false;
            } 
            if(tipo === ""){
                setFormTipo({
                    helperText: 'Selecione!',
                    error: true
                })
                return false;
            } 
        }
        setOpen2(true);
        api.get('/absenteismo/' + id + "/edit",
        { 
            headers: {
                valida,
                ocorrencia,
                comunicacao,
                apresentacao,
                gestor,
                tipo,
                user: getUser()
            }
        })
        .then(function(response){
            console.log(response)
            var {status, erro} = response.data;
            if(status){
                onClose();
                setOpen3({
                    show: true,
                    text: 'Validado com sucesso!',
                    type: "success"
                });
                consulta()
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
            }
            setOpen2(false);
        }) 
    }
    function handleCloase(){
        onClose();
    }
    const openLink = (link) => {
        let url =  "https://www.cilog.com.br/backend/public/documentos/" + link;
        window.open(url, '_blank', 'noreferrer');
    }
   
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={handleCloase} open4={false} open={open}>Validar Entrevista Absenteismo</DialogTitle>
            <DialogContent className={classes.root}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="entrevista_colaborador">Colaborador</InputLabel>
                           <TextField
                            size="small"
                                id="entrevista_colaborador"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="entrevista_periodo">Periodo</InputLabel>
                           <TextField
                                size="small"
                                id="entrevista_periodo"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="entrevista_funcao">Função</InputLabel>
                           <TextField
                                size="small"
                                id="entrevista_funcao"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    {!validaMuda ? (
                        <>
                            <Grid item xs={4}>
                                <Form.Group className={classes.disabled}> 
                                    <InputLabel className={classes.linha} htmlFor="entrevista_classificacao">Classificação</InputLabel>
                                   <TextField
                                        size="small"
                                        id="entrevista_classificacao"
                                        type="text"
                                        disabled
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={4}>
                                <Form.Group className={classes.disabled}> 
                                    <InputLabel className={classes.linha} htmlFor="entrevista_tipo">Tipo</InputLabel>
                                   <TextField
                                        size="small"
                                        id="entrevista_tipo"
                                        type="text"
                                        disabled
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={4}>
                                <InputLabel className={classes.linha} htmlFor="entrevista_classificacao_muda">Classificação</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                error={formClassificacao.error}
                                inputProps={{
                                    name: 'entrevista_classificacao_muda',
                                    id: 'entrevista_classificacao_muda',
                                }}
                                value={mudaClassificacao}
                                onChange={handleChangeMudaClassificacao}
                                >
                                    <option value="">Selecione...</option>
                                    <option value="1">Justificada</option>
                                    <option value="2">Não Justificada</option>
                                    <option value="3">Prevista Legislação</option>
                                </Select>
                                <FormHelperText  error={formClassificacao.error}>{formClassificacao.helperText}</FormHelperText>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel className={classes.linha} htmlFor="entrevista_tipo_muda">Tipo</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                error={formTipo.error}
                                inputProps={{
                                    name: 'entrevista_tipo_muda',
                                    id: 'entrevista_tipo_muda',
                                }}
                                value={mudaTipoValue}
                                onChange={handleChangeMudaTipoValue}
                                >
                                    { mudaTipo &&
                                        mudaTipo.map((dados, index) => (
                                            <option key={index} value={dados.id}>{dados.nome}</option>
                                        ))
                                    }
                                </Select>
                                <FormHelperText  error={formTipo.error}>{formTipo.helperText}</FormHelperText>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={3}>
                        <InputLabel className={classes.linha} htmlFor="entrevista_tipo">Arquivos</InputLabel>
                        { arquivos && 
                            arquivos.map((dados, index) => (
                                <IconButton
                                key={index}
                                style={{backgroundColor: 'var(--warning)', margin: 5}} 
                                size="small" 
                                onClick={() => openLink(dados.nome_real)}
                                aria-label="Arquivos" 
                                variant="warning">
                                    {dados.tipo === 'jpg' ? (
                                        <InsertPhotoIcon />
                                    ) : (
                                        dados.tipo === 'pdf' && (
                                            <PictureAsPdfIcon />
                                        )
                                    )}
                                </IconButton>
                            ))
                        }
                    </Grid>
                    <Grid item xs={1}>
                        <InputLabel className={classes.linha} htmlFor="entrevista_tipo">Preenchido pelo Colaborador {preenchido}</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="entrevista_comentario">Comentario</InputLabel>
                           <TextField
                                size="small"
                                id="entrevista_comentario"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Divider />
                    <Grid item xs={3}>
                        <InputLabel className={classes.linha} htmlFor="entrevista_rh_valida">Validar</InputLabel>
                        <Select
                        native
                        className={classes.textField}
                        error={formValidar.error}
                        inputProps={{
                            name: 'entrevista_rh_valida',
                            id: 'entrevista_rh_valida',
                        }}
                        value={valida}
                        onChange={handleChange3}
                        >
                            <option value="">Selecione...</option>
                            <option value={1}>SIM</option>
                            <option value={0}>NÃO</option>
                        </Select>
                        <FormHelperText  error={formValidar.error}>{formValidar.helperText}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.linha} htmlFor="pergunta1">Ocorrência de falta(s) anterior sem justicativa?</InputLabel>
                        <Select
                        native
                        className={classes.textField}
                        error={formOcorrencia.error}
                        inputProps={{
                            name: 'pergunta1',
                            id: 'pergunta1',
                        }}
                        >
                            <option value="">Selecione...</option>
                            <option value={1}>SIM</option>
                            <option value={0}>NÃO</option>
                        </Select>
                        <FormHelperText  error={formOcorrencia.error}>{formOcorrencia.helperText}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.linha} htmlFor="pergunta2">Comunicação prêvia desta(s) falta(s) a empresa?</InputLabel>
                        <Select
                        native
                        className={classes.textField}
                        error={formComunicacao.error}
                        inputProps={{
                            name: 'pergunta2',
                            id: 'pergunta2',
                        }}
                        >
                            <option value="">Selecione...</option>
                            <option value={1}>SIM</option>
                            <option value={0}>NÃO</option>
                        </Select>
                        <FormHelperText  error={formComunicacao.error}>{formComunicacao.helperText}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.linha} htmlFor="pergunta3">Apresenção de justificativa compativel?</InputLabel>
                        <Select
                        native
                        className={classes.textField}
                        error={formApresentacao.error}
                        inputProps={{
                            name: 'pergunta3',
                            id: 'pergunta3',
                        }}
                        >
                            <option value="">Selecione...</option>
                            <option value={1}>SIM</option>
                            <option value={0}>NÃO</option>
                        </Select>
                        <FormHelperText  error={formApresentacao.error}>{formApresentacao.helperText}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.linha} htmlFor="entrevista_gestor_validar">Gestor</InputLabel>
                        <Select
                        native
                        className={classes.textField}
                        error={formGestor.error}
                        inputProps={{
                            name: 'gestor',
                            id: 'entrevista_gestor_validar',
                        }}
                        >
                            <option value="">Selecione...</option>
                            { usuario &&
                                usuario.map((dados, index) => (
                                    <option key={dados.id} value={dados.id}>{dados.Mat} - {dados.Nome} - {dados.Função}</option>
                                ))
                            }
                        </Select>
                        <FormHelperText  error={formGestor.error}>{formGestor.helperText}</FormHelperText>
                    </Grid>
                </Grid>
            </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={open2} onClick={handleCloase}>Cancelar</Button>
                    <div className={classes.wrapper}>
                        <Button color="primary" disabled={open2} onClick={() => {salva_etapas()}} variant="contained">Salvar</Button>
                        {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;