import React, {useState} from 'react';
import { Container2, Logo2,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Styles from '../../../components/Styles';
import { getUser, getusertype} from "../../../services/auth";
import { TextField } from '@material-ui/core';
import { Form } from 'react-bootstrap';

import logo_icon from '../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import ModalAdd from './ModalAdd';

import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { CSVLink } from "react-csv";
import Tab1 from './Tab1';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Body({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns2, setColumns2] = useState(null);
    const [products2, setProducts2] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [lista2, setLista2] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [mesini, setMesini] = useState("");
    const [mesfin, setMesfin] = useState("");
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    function consultarRelatorio(){
        for(let dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(mesini === "" || mesfin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        setOpen2(true);
        let {empresa, contrato, filial} = state;
        api.get('/financeiro/faturamento/relatorio',
            { 
                headers: { 
                        empresa, 
                        mesini, 
                        mesfin,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let {data} = response.data;
            let json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                let uniques = [];
                let itemsFound = {};
                for(let i = 0, l = where2.length; i < l; i++) {
                    let stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns2(uniques);
                setProducts2(json);
                
                let export_temp = data.map((column)  => {
                    delete column.id;
                    let name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv(export_temp);
                setLista2(true);
            }else{
                setLista2(false);
                setProducts2([]);
            }
            setOpen2(false);
        });
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const type = getusertype();
    return (
    <Container2>
        <Header>
            Faturamento
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Acompanhamento"  {...a11yProps(0)} />
            <Tab label="Relatorio"  {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Tab1 empresas={empresas} contratos={contratos} filiais={filiais} type={type} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="mesfim">Mês Inicial</InputLabel>
                        <TextField
                           type="month"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="mesfim" 
                            value={mesini}
                            onChange={(event) => setMesini(event.target.value)}
                            inputProps={{
                                name: 'mesfim',
                                id: 'mesfim',
                            }}
                            placeholder='mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="mesfim">Mês final</InputLabel>
                        <TextField
                            type="month"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="mesfim" 
                            value={mesfin}
                            onChange={(event) => setMesfin(event.target.value)}
                            inputProps={{
                                name: 'mesfim',
                                id: 'mesfim',
                            }}
                            placeholder='mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultarRelatorio()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open2 ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista2 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns2.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products2.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns2.map((column, index) => {
                                                let name = column.text;
                                                if(name !== "id"){
                                                    switch (name) {
                                                        case 'Data doc':
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Venc Líquid':
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Compensaç.':
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name] != null && dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Dt. lçto.':
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Mont. em MI':
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {((dados[name])/1).toLocaleString('pt-br',{   
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                            </Table.Col>);
                                                        default:
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name]}
                                                            </Table.Col>);
                                                    }
                                                }
                                                return false;
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
        </TabPanel>
        {modalShow && (
            <ModalAdd
                open={modalShow}
                onClose={() => setModalShow(false)}
                maxWidth="sm"
                fullWidth={true}
                empresa={state.empresa}
                contrato={state.contrato}
                filial={state.filial}
            />
        )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    
    </Container2>
    );
}

export default Body;