import React, {useState, useEffect} from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import logo_icon from '../../img/icon.png';

import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';
import BuildOutlined from '@material-ui/icons/SyncProblem';
import RecentActors from '@material-ui/icons/RecentActors'

import { IconContext } from "react-icons";
import { FaCarCrash } from 'react-icons/fa';
import { HiPresentationChartLine } from 'react-icons/hi';
import { GiFlatTire, GiSiren } from 'react-icons/gi';
import { BiHappyAlt } from 'react-icons/bi';
import Home from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench} from '@fortawesome/free-solid-svg-icons'

import {getModulos} from "../../services/auth";
import { RecentActorsOutlined } from '@material-ui/icons';

const drawerWidth = 63;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    appBar: {
        background: '#f8a23d',
        color: '#FFF',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbar2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        width: '100%',
    },
    toolbaricon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 31,
        height: 31,
    },
    content: {
        height: '100%',
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
    tabs: {
        maxWidth: drawerWidth,
    },
}));

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        maxWidth: drawerWidth,
        minWidth: drawerWidth,
    },
    indicator: {
      backgroundColor: '#004e95',
    },
})(Tabs);
const TabSt = withStyles({
    root: {
        maxWidth: drawerWidth,
        minWidth: drawerWidth,
        fontSize: 12,
    },
})(Tab);


function Menu({history, modulos}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [login, setLogin] = useState([]);
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function a11yProps(index) {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    function treinamentos(){
        history.push('/treinamentos');
    }
    function home(){
        history.push('/home');
    }
    function painel_os(){
        history.push('/os');
    }
    function checklist(){
        history.push('/checklist');
    }
    function socorro(){
        history.push('/socorro');
    }
    function abastecimento(){
        history.push('/abastecimento');
    }
    function precontra(){
        history.push('/precontra');
    }
    function calibragem(){
        history.push('/pneus');
    }
    function faturamento(){
        history.push('/faturamento');
    }
    function atualizarOS(){
        history.push('/atualizaros');
    }
    function absenteismo(){
        history.push('/absenteismo');
    }
    function psicologico(){
        history.push('/psicologico');
    }
    function criticidade(){
        history.push('/criticidade');
    }
    function skap(){
        history.push('/skap');
    }

    function select_tab(value){
        var cont = value;
        cont++;
        
        return cont
    }
    useEffect (() => {
        var teste = getModulos();
        setLogin(JSON.parse(teste))
        var modulos = JSON.parse(teste);
        var valor = 1;
        var modulo_id = {
            home: 0,
            treinamentos: 0,
            checklist: 0,
            socorro: 0,
            abastecimento: 0,
            precontra: 0,
            pneus: 0,
            faturamento: 0,
            atualizaros: 0,
            absenteismo: 0,
            criticidade: 0,
            psicologico: 0,
            skap: 0,
        }
         // eslint-disable-next-line array-callback-return
        modulos.map((modulo, index) => {
            if(modulo.modulo === '1'){
                modulo_id = {
                    ...modulo_id,
                    checklist: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '4'){
                modulo_id = {
                    ...modulo_id,
                    abastecimento: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '2'){
                modulo_id = {
                    ...modulo_id,
                    socorro: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '3'){
                modulo_id = {
                    ...modulo_id,
                    treinamentos: valor
                  };
                valor = select_tab(valor) 
            }
            if(modulo.modulo === '17'){
                modulo_id = {
                    ...modulo_id,
                    os: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '20'){
                modulo_id = {
                    ...modulo_id,
                    precontra: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '5'){
                modulo_id = {
                    ...modulo_id,
                    calibragem: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '21'){
                modulo_id = {
                    ...modulo_id,
                    faturamento: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '23'){
                modulo_id = {
                    ...modulo_id,
                    atualizaros: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '24'){
                modulo_id = {
                    ...modulo_id,
                    absenteismo: valor
                  };
                valor = select_tab(valor)
            }
            if(modulo.modulo === '26'){
                modulo_id = {
                    ...modulo_id,
                    psicologico: valor
                  };
                valor = select_tab(valor)
                
            }
            if(modulo.modulo === '27'){
                modulo_id = {
                    ...modulo_id,
                    criticidade: valor
                  };
                valor = select_tab(valor)
                
            }
            if(modulo.modulo === '31'){
                modulo_id = {
                    ...modulo_id,
                    skap: valor
                  };
                valor = select_tab(valor)
                
            }
        });
        if(history.location.pathname === "/home"){
            setValue(modulo_id.home);
        }
        if(history.location.pathname === "/treinamentos"){
            setValue(modulo_id.treinamentos);
        }
        
        if(history.location.pathname === "/checklist"){
            setValue(modulo_id.checklist);
        }
        if(history.location.pathname === "/socorro"){
            setValue(modulo_id.socorro);
        }
        if(history.location.pathname === "/abastecimento"){
            setValue(modulo_id.abastecimento);
        }
        if(history.location.pathname === "/aviso"){
            setValue(null);
        }
        if(history.location.pathname === "/precontra"){
            setValue(modulo_id.precontra);
        }
        if(history.location.pathname === "/pneus"){
            setValue(modulo_id.calibragem);
        }
        if(history.location.pathname === "/faturamento"){
            setValue(modulo_id.faturamento);
        }
        if(history.location.pathname === "/atualizaros"){
            setValue(modulo_id.atualizaros);
        }
        if(history.location.pathname === "/absenteismo"){
            setValue(modulo_id.absenteismo);
        }
        if(history.location.pathname === "/psicologico"){
            setValue(modulo_id.psicologico);
        }
        if(history.location.pathname === "/criticidade"){
            setValue(modulo_id.criticidade);
        }
        if(history.location.pathname === "/skap"){
            setValue(modulo_id.skap);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);

  return(
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left">
            <div className={classes.toolbar}>
                <div className={classes.toolbar2}>
                    <img src={logo_icon} alt="" className={classes.toolbaricon} />
                </div>
            </div>
            <Divider />
            <AntTabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                className={classes.tabs}
                >
                <Tooltip title="Home">
                    <TabSt icon={<Home />} 
                    value={0}
                    onClick={()=>home()} 
                    {...a11yProps(0)}  />
                </Tooltip>
                {login.map((modulo, index) => {
                    /*if(modulo.modulo === '9'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="PLANO DE AÇÃO"  onClick={Plano} />
                    }*/
                    if(modulo.modulo === '1'){
                        return (<Tooltip 
                                title="Check List" 
                                key={modulo.modulo}>
                                    <TabSt icon={<AssignmentTurnedIn />} 
                                    value={1}
                                    onClick={()=>checklist()}
                                    {...a11yProps(1)} />
                                </Tooltip>)
                    }
                    if(modulo.modulo === '4'){
                        return (<Tooltip 
                                title="Abastecimento" 
                                key={modulo.modulo}>
                                    <TabSt 
                                    icon={<LocalGasStationIcon />} 
                                    onClick={() => abastecimento()}
                                    value={2}
                                    {...a11yProps(2)}/>
                                </Tooltip>)
                    }
                    if(modulo.modulo === '2'){
                        return (<Tooltip title="Socorro em Rota" key={modulo.modulo}>
                                    <TabSt icon={
                                        <IconContext.Provider value={{ size: "2em"}}>
                                            <FaCarCrash/>
                                        </IconContext.Provider>} 
                                        value={4}
                                        onClick={() => socorro()}
                                        {...a11yProps(4)}/>
                                </Tooltip>)
                    }
                    if(modulo.modulo === '3'){
                        return (<Tooltip title="Treinamentos" key={modulo.modulo}>
                                    <TabSt icon={
                                        <IconContext.Provider value={{ size: "2em"}}>
                                            <HiPresentationChartLine/>
                                        </IconContext.Provider>} 
                                        value={3}
                                        onClick={()=>treinamentos()} 
                                        {...a11yProps(3)}/>
                                </Tooltip>)
                    }
                    
                    
                    if(modulo.modulo === '5'){
                        return (<Tooltip title="Pneu" key={modulo.modulo}>
                                    <TabSt icon={
                                        <IconContext.Provider value={{ size: "2em"}}>
                                            <GiFlatTire/>
                                        </IconContext.Provider>} 
                                        value={5}
                                        onClick={()=>calibragem()} 
                                        {...a11yProps(5)}/>
                                </Tooltip>)
                    }
                    /*if(modulo.modulo === '3'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="TREINAMENTO" />
                    }
                    if(modulo.modulo === '7'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="INVENTARIO" />
                    }
                    if(modulo.modulo === '8'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="LAVAGEM" />
                    }
                    if(modulo.modulo === '10'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="MANUTENÇÃO" />
                    }
                    if(modulo.modulo === '6'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="5S" />
                    }
                    if(modulo.modulo === '11'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="SAFETY" />
                    }
                    if(modulo.modulo === '12'){
                        return <TabSt icon={<AddIcon />} key={modulo.modulo} label="PDV" />
                    }
                    */
                    if(modulo.modulo === '17'){
                        return (<Tooltip 
                                title="Painel de OS" 
                                key={modulo.modulo}>
                                    <TabSt 
                                    icon={<FontAwesomeIcon 
                                        icon={faWrench} 
                                        size="lg"/>} 
                                    onClick={()=>painel_os()}
                                     {...a11yProps(6)} 
                                     key={modulo.modulo}
                                     value={6}/>
                                </Tooltip>)
                    }
                    /*if(modulo.modulo === '15'){
                        return (<Tooltip 
                                title="Ponto" 
                                key={modulo.modulo}>
                                    <TabSt 
                                    icon={<FontAwesomeIcon icon={faBusinessTime} size="lg"/>} 
                                    {...a11yProps(5)} 
                                    key={modulo.modulo}
                                    value={2}/>
                                </Tooltip>)
                    }*/
                    if(modulo.modulo === '20'){
                        return (<Tooltip 
                            title="Pré-ContraCheque" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<LocalAtmIcon />} 
                                onClick={()=>precontra()}
                                    {...a11yProps(7)} 
                                    key={modulo.modulo}
                                    value={7}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '21'){
                        return (<Tooltip 
                            title="Faturamento" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<PaymentIcon />} 
                                onClick={()=>faturamento()}
                                    {...a11yProps(8)} 
                                    key={modulo.modulo}
                                    value={8}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '23'){
                        return (<Tooltip 
                            title="Atualizar OS" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<BuildOutlined />} 
                                onClick={()=>atualizarOS()}
                                    {...a11yProps(9)} 
                                    key={modulo.modulo}
                                    value={9}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '24'){
                        return (<Tooltip 
                            title="Absenteismo" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<RecentActors />} 
                                onClick={()=>absenteismo()}
                                    {...a11yProps(9)} 
                                    key={modulo.modulo}
                                    value={9}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '26'){
                        return (<Tooltip 
                            title="Segurança Psicologico" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<IconContext.Provider value={{ size: "2em"}}>
                                    <BiHappyAlt/>
                                </IconContext.Provider>} 
                                onClick={()=>psicologico()}
                                    {...a11yProps(9)} 
                                    key={modulo.modulo}
                                    value={9}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '27'){
                        return (<Tooltip 
                            title="Criticidade" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<IconContext.Provider value={{ size: "2em"}}>
                                    <GiSiren/>
                                </IconContext.Provider>} 
                                onClick={()=>criticidade()}
                                    {...a11yProps(9)} 
                                    key={modulo.modulo}
                                    value={9}/>
                        </Tooltip>)
                    }
                    if(modulo.modulo === '31'){
                        return (<Tooltip 
                            title="SKAP" 
                            key={modulo.modulo}>
                                <TabSt 
                                icon={<IconContext.Provider value={{ size: "2em"}}>
                                    <RecentActorsOutlined />
                                </IconContext.Provider>} 
                                onClick={()=>skap()}
                                    {...a11yProps(9)} 
                                    key={modulo.modulo}
                                    value={9}/>
                        </Tooltip>)
                    }
                    
                    return false;
                })}
            </AntTabs>
        </Drawer>
  );
}

export default Menu;