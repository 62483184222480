import styled from 'styled-components';


export const Priva = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
    font: 18px 'Roboto', sans-serif;
    width: 100%;
    min-height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-Items: center;
    background: #313131;
    img {
        height: 50px;
        width: 50px;
        justify-Content: center;
        align-Items: center;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-Content: center;
    padding: 10px;
    font-weight: bold;
    font: 30px 'Roboto', sans-serif;
    color: #FFF;
`;

export const Container = styled.div`
    background: #212121;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    min-height: 100%;
    padding-bottom: 25px;
    box-shadow: 0 1px 5px 0 #212121;
`;
export const Logo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;

    background: #212121;

    img {
        height: 80px;
        width: 80px;
    }
    
`;

