import styled from 'styled-components';

var style_sistema = "height: 15px; font-size: 10px; color: #999; text-align: center;";
var style_user = 'font-size: 14px; border: 1px solid #f3f3f3; border-radius: 10px; background: #FFF; color: #000; display: inline-block;box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);position: relative;padding-bottom: 5px;';
var style_user2 = 'font-size: 14px; border: 1px solid #f3f3f3; border-radius: 10px; background: #004e95; color: #FFF; display: inline-block;box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);position: relative;padding-bottom: 5px; float: right';
export const Container = styled.div`
    margin: 5px 10px;
    width: 70%;
    overflow: hidden;
    ${props => (props.sistema === 1 && 
            style_sistema ) 
            || 
            (
                (props.user === true && style_user2) || style_user
            )};
    img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-top: 5px;
    }
    .col1{
        width: 12%;
        float: left;
    }
    .col2{
        width: 66%;
        float: left;
    }
    .col3{
        width: 22%;
        float: left;
    }
    .colfoto {
        height: 100%;
        position: absolute;
        width: 14%;
        float: left;
        text-align: center;
    }
    .colcoment {
        margin-left: 20px;
        margin-top: 5px;
        //width: 100%;
        float: left;
    }
    .coldata {
        font-size: 10px;
        margin-top: 5px;
        color: #999;
        float: left;
    }
    .colcoment2 {
        margin-left: 20px;
        margin-top: 5px;
        //width: 100%;
        float: left;
        margin-right: 10px;
    }
    .coldata2 {
        font-size: 10px;
        margin-top: 5px;
        color: #FFF;
        float: left;
    }
    .colnome{
        
    }
    
`;
export const Colnome = styled.div`
    font-size: 10px;
    color: #999;
    margin-top: 5px;
    float: left;
    margin-left: 10px;
    width: 100%;
`;

export const Coldata = styled.div`
    ${props =>  (props.user === true && 
            "font-size: 10px; color: #999; margin-bottom: 15px; float: right; margin-right: 10px; width: 100%; text-align: right;"
        ) || (
            "font-size: 10px; color: #999; margin-bottom: 15px; float: left; margin-left: 10px; width: 100%; text-align: left;"
        )};
    
`;