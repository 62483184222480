/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import Styles from '../../../../components/Styles';
import DialogTitle from '../../../../components/BoardCheck/Components/DialogTitle'
import { getUser} from "../../../../services/auth";

import { Badge } from "tabler-react"; 
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit(props) {
    const [open2, setOpen2] = useState(false);
    const classes = Styles();
    const {id, consulta, onClose, empresa, contrato, filial, data, data_agendamento, mat, nome, psicologo, status, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [formData, setDataForm] = useState({
        error: false,
        helperText: '',
    })

    function salvar(){
        let data = document.getElementById('agendamento_data_incluir').value;
        let mensagem = document.getElementById('mensagem_incluir').value;
        setDataForm({
            error: false,
            helperText: '',
        })
        if(data === ""){
            setDataForm({
                helperText: 'Preencha a data!',
                error: true
            })
            return false;
        }
       
        setOpen2(true);
        api.get('/psicologica/agendamento/' + id + "/edit",
        { 
            headers: {
                data,
                mensagem,
                user: getUser()
            }
        })
        .then(function(response){
            var {status, message} = response.data;
            if(status){
                onClose();
                setOpen3({
                    show: true,
                    text: message,
                    type: "success"
                });
                consulta()
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
            setOpen2(false);
        }) 
    }
    function handleCloase(){
        onClose();
    }
    useEffect (() => {
        visualizado(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const visualizado = (id) => {
        api.put('/psicologica/agendamento/' + id ,
        { 
            headers: {
                user: getUser()
            }
        })
        .then(function(response){
        }) 
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={handleCloase} open4={false} open={open}>Agendar Atendimento</DialogTitle>
            <DialogContent className={classes.root}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={1}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="mat_colaborador">Mat</InputLabel>
                           <TextField
                                size="small"
                                id="mat_colaborador"
                                type="text"
                                value={mat}
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="colaborador">Colaborador</InputLabel>
                           <TextField
                                size="small"
                                id="colaborador"
                                value={nome}
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="data">Data Solicitação</InputLabel>
                           <TextField
                                size="small"
                                id="data"
                                type="text"
                                value={data}
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={3}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="status">Status</InputLabel>
                            {status === 'Agendado' ? (
                                <Badge color="success" className="mr-1">
                                    {status}
                                </Badge>
                            ) : (
                                <Badge color="warning" className="mr-1">
                                    {status}
                                </Badge>
                            )}
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="colaborador">Psicologo</InputLabel>
                           <TextField
                                size="small"
                                id="Psicologo"
                                value={psicologo}
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="agendamento_data_incluir">Data Agendamento</InputLabel>
                           <TextField
                        type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="agendamento_data_incluir" 
                                placeholder='dd/mm/aaaa'
                            />
                            <FormHelperText  error={formData.erro}>{formData.helperText}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="mensagem_incluir">Mensagem</InputLabel>
                           <TextField
                        type="texxt"
                                size="small"
                                id="mensagem_incluir"
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={open2} onClick={handleCloase}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button color="primary" disabled={open2} onClick={() => {salvar()}} variant="contained">Salvar</Button>
                    {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;