import React from 'react';

import logo_icon from '../img/icon.png';
import logo_logo from '../img/logo.png';

import { Priva, Title, Text, Container, Text2 } from './styles';

export default function Privacy() {
  return (
    <Priva>
        <Container>
            <Title><img src={logo_icon} alt="CILog" />
            <img src={logo_logo} alt="CILog" /></Title>
            <Title>POLÍTICA DE PRIVACIDADE</Title>
            <Text>A política de privacidade dos nossos produtos foi elaborada visando a total transparência
            para com nossos clientes. O intuito deste documento é esclarecer quais informações são coletadas 
            dos usuários de nosso site e aplicativos.</Text>
            <Text2>SEÇÃO 1 – Sobre a coleta de informações </Text2>
            <Text>Através de nosso site e aplicativos, as informações são coletadas da seguinte forma:</Text>
            <Text> – As informações fornecidas por você por qualquer funcionalidade, são enviadas seguramente
                até nossos servidores e algumas armazenadas localmente no aparelho. Informações fornecidas 
                por você no site</Text>
            <Text>– As informações fornecidas por você no site, como cadastro de novos usuários, são enviadas
                seguramente até nossos servidores. Informações coletadas pelos nossos aplicativos</Text>
            <Text>– Quando você utiliza os aplicativos, informações como acesso, quais funções você acessou, 
                localização, bem como relatórios sobre erros, são automaticamente coletados e enviados anonimamente 
                para nossos servidores. Informações de navegação no site</Text>
            <Text>– Durante sua visita em nosso site, é mantido um cookie em seu navegador que é automaticamente 
                removido quando a sessão se encerra.</Text>
            <Text2>SEÇÃO 2 – O que fazemos com as suas informações?</Text2>
            <Text>– As informações de uso e relatórios de erro do aplicativo e site, que são coletados automaticamente,
                poderão ser usados para pesquisa e estatística.</Text>
            <Text>– E-mail (quando aplicável): Podemos lhe enviar e-mails algumas novas funcionalidades, atualizações e avisos.</Text>
            <Text2>SEÇÃO 3 – Divulgação</Text2>
            <Text>– Podemos divulgar suas informações pessoais se formos obrigados por lei a fazê-lo. 
                Porém nenhuma informação pessoal sua será publicamente divulgada.</Text>
            <Text2>SEÇÃO 4 – Sobre o acesso às informações</Text2>
            <Text>– As informações fornecidas por você no aplicativos e site serão acessíveis apenas para pessoas responsáveis
                por gerenciar essas informações em sua empresa (como responsáveis pelo RH, por exemplo) e que tenham a 
                autorização necessária.</Text>
            <Text>– As informações que são salvas localmente no aplicativo são acessíveis apenas por você, 
                sendo apagadas quando você realiza o logout.</Text>
            <Text2>SEÇÃO 5 – ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</Text2>
            <Text>– Reservamos o direito de modificar essa política de privacidade a qualquer momento. 
                Portanto, por favor, leia-a com frequência. As alterações e esclarecimentos surtem efeito 
                imediatamente após serem publicadas no site. Caso façamos alterações na política de privacidade, 
                iremos notificá-lo sobre a atualização. Assim, você saberá quais informações coletamos, 
                como as usamos, e sob que circunstâncias, caso aplicável, as utilizaremos e/ou divulgaremos.</Text>
            <Text>– Caso ocorra a fusão da nossa empresa com outra empresa, suas informações podem ser transferidas
                    para os novos proprietários para que possamos continuar disponibilizando nossos produtos para você.</Text>
            
            <Text2>PERGUNTAS E INFORMAÇÕES DE CONTATO</Text2>
            <Text>– Se você gostaria de acessar, corrigir, alterar ou excluir quaisquer informações pessoais que 
                temos sobre você, registre uma queixa, ou simplesmente peça mais informações 
                de contato a nosso Diretor de privacidade através do diegoguterresdasilva@gmail.com ou pelo correio:</Text>
            <Text>Guterres Sistemas</Text>
            <Text>[Re: Diretor de privacidade]</Text>
            <Text>[Rua captao gaspar soares, 227, NOVA IGUAÇU, RJ, 26255-040, Brazil]</Text>
        </Container>
    </Priva>
  );
}
