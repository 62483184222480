import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../../../../../../../services/api'
import { getUser} from "../../../../../../../../services/auth";


import { Logo2 } from './styles';

import logo_icon from '../../../../../../../../img/icon.png';
import DialogTitle from '../../../DialogTitle';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial",
        '&:hover': {
            backgroundColor: 'transparent',
          },
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
    },
            'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

function ModalAddQuiz(props) {
    const classes = useStyles();
    const [open4, setOpen4] = useState(false);
    const handleClose4 = () => {
        setOpen4(false);
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [open, setOpen] = useState(false);
    const [formPergunta, setPergunta] = useState({
        error: false,
        helperText: '',
    })
    const [formResposta1, setResposta1] = useState({
        error: false,
        helperText: '',
    })
    const [formResposta2, setResposta2] = useState({
        error: false,
        helperText: '',
    })
    const [formResposta3, setResposta3] = useState({
        error: false,
        helperText: '',
    })
    const [selectedValue, setSelectedValue] = useState("");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    var {idTreina, onClose, altera, setAltera, id, ...rest} = props;
    function salvar_pergunta(){
        setPergunta({
            error: false,
            helperText: '',
        });
        setResposta1({
            error: false,
            helperText: '',
        });
        setResposta2({
            error: false,
            helperText: '',
        });
        setResposta3({
            error: false,
            helperText: '',
        });
        var pergunta = document.getElementById('pergunta_incluir').value;
        if(pergunta === ""){
            setPergunta({
                error: true,
                helperText: 'Pergunta não pode ser vazio!',
            });
            return false;
        }
        var opcao1 = document.getElementById('resposta1_incluir').value;
        if(opcao1 === ""){
            setResposta1({
                error: true,
                helperText: 'Resposta 1 não pode ser vazio!',
            });
            return false;
        }
        var opcao2 = document.getElementById('resposta2_incluir').value;
        if(opcao2 === ""){
            setResposta2({
                error: true,
                helperText: 'Resposta 2 não pode ser vazio!',
            });
            return false;
        }
        var opcao3 = document.getElementById('resposta3_incluir').value;
        if(opcao3 === ""){
            setResposta3({
                error: true,
                helperText: 'Resposta 3 não pode ser vazio!',
            });
            return false;
        }
        if(selectedValue === ""){
            setOpen3({
                show: true,
                text: 'Por favor marque a opção que é a correta!',
                type: 'info'
            });
            return false;
        }
        setOpen(true)
        api.post('perguntaTreinamaento',
		{ 
			pergunta,
			opcao1,
			opcao2,
			opcao3,
			id_treina: idTreina,
			resposta: selectedValue,
			user: getUser(),
		})
		.then(function(response){
            setOpen(false)
            var cont = altera;
            cont = cont + 1;
            setAltera(cont)
            onClose();
		});
    }
    
    return (<Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={open4} open={open}>Incluir Pergunta</DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="pergunta_incluir">Pergunta</InputLabel>
                               <TextField
                                    size="small"
                                    error={formPergunta.error}
                                    id="pergunta_incluir"
                                    type="text"
                                    className={classes.textField}
                                />
                                <FormHelperText  error={formPergunta.error}>{formPergunta.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            Opções
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            Correta
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta1_incluir">Resposta 1</InputLabel>
                               <TextField
                                    size="small"
                                    id="resposta1_incluir"
                                    type="text"
                                    className={classes.textField}
                                    error={formResposta1.error}

                                />
                                <FormHelperText  error={formResposta1.error}>{formResposta1.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "1"}
                                onChange={handleChange}
                                value="1"
                                name="radio-button-correta"
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta2_incluir">Resposta 2</InputLabel>
                               <TextField
                                    size="small"
                                    id="resposta2_incluir"
                                    type="text"
                                    className={classes.textField}
                                    error={formResposta2.error}

                                />
                                <FormHelperText  error={formResposta2.error}>{formResposta2.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "2"}
                                onChange={handleChange}
                                value="2"
                                name="radio-button-correta"
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta3_incluir">Resposta 3</InputLabel>
                               <TextField
                                    size="small"
                                    id="resposta3_incluir"
                                    type="text"
                                    className={classes.textField}
                                    error={formResposta3.error}

                                />
                                <FormHelperText  error={formResposta3.error}>{formResposta3.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "3"}
                                onChange={handleChange}
                                value="3"
                                name="radio-button-correta"
                            />
                        </Grid>
                    </Grid>    
                </Grid> 
            )}
            </DialogContent>
            {open !== true ? (
                <DialogActions>
                    <Button color="secondary" onClick={handleClose4}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => salvar_pergunta()} >Salvar</Button>
                </DialogActions>
            ): null}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default ModalAddQuiz;