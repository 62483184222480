import React from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

const styles2 = StyleSheet.create({
    tab: {
        width: "94%",
        flexDirection: "row",
        borderBottom: 0.5,
        borderColor: "#a3a3a3",
        padding: 5,
        justifyContent: "space-around",
    },
    text: {
        fontSize: 10,
        width: "80%",
    },
    text2: {
        fontSize: 10,
        width: "20%",
    }
    
});


function Body({dados}) {
  return (
    <View style={styles2.tab}>
         <Text style={styles2.text2}>{dados.quantidade}</Text>
         <Text style={styles2.text}>{dados.descricao}</Text>
    </View>
);
}

export default Body;