import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput from '../../../functions/CurrencyInput'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../Styles';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput
        {...other}
        
      />
    );
}

function ModalEdit(props) {
    const classes = Styles();
    var {empresa, contrato, filial, id, onClose, consulta, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [carro, setCarro] = useState(0);
    const [visible, setVisible] = useState(false);
    const [formData, setData] = useState({
        errorData: false,
        helperTextData: '',
    })
    const [formKm, setKm] = useState({
        errorKm: false,
        helperTextKm: '',
    })
    const [formLitro, setLitro] = useState({
        errorLitro: false,
        helperTextLitro: '',
    })
    const [formMot, setMot] = useState({
        errorMot: false,
        helperTextMot: '',
    })
    const [formManobra, setManobra] = useState({
        error: false,
        helperText: '',
    })

    useEffect (() => {
        async function carrega_abastecimento(){
            setOpen(true);
            await api.get('/abastecimento/' + id,
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                            type: 3,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let {abastecimento, ant} = response.data;
                let {carros, hodometo, litros, motoristas, data, tipo, manobrista} = abastecimento;
                // eslint-disable-next-line eqeqeq
                if(tipo == "E"){
                    setVisible(true)
                    if(manobrista != null){
                        let {CODFUNC, NOMEFUNC} = manobrista;
                        document.getElementById('abast_mat_manobra_incluir').value = CODFUNC;
                        document.getElementById('abast_nome_manobra_incluir').value = NOMEFUNC;
                    }
                }
                let {CODFUNC, NOMEFUNC} = motoristas;
                litros = litros.replace(".", ",");
                document.getElementById('abast_placa_edit').value = carros.placa;
                document.getElementById('abast_cap_editr').value = carros.capacidade;
                document.getElementById('abast_hodometro_editr').value = hodometo;
                document.getElementById('abast_litros_editr').value = litros;
                document.getElementById('abast_mat_mot_editr').value = CODFUNC;
                document.getElementById('abast_hod_ant_editr').value = ant;
                document.getElementById('abast_data_editr').value = data;
                document.getElementById('abast_nome_editr').value = NOMEFUNC;
                setCarro(carros.id);
                calc_kml();
            });
        }
        carrega_abastecimento();
      }, [empresa, contrato, filial, id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    function carrega_km_ant(){
        var placa = carro;
        if(placa !== ""){
            var data = document.getElementById('abast_data_editr').value;
            data = data.split("-");
            data = data[2] + '/'+ data[1]+ '/'+ data[0];
            api.get('/abastecimento/' + placa,
                { 
                    headers: { 
                            user: getUser(),
                            type: 2,
                            data,  
                        } 
                    }
                )
            .then(function (response) {
                var hodo_ant = response.data;
                document.getElementById('abast_hod_ant_editr').value = hodo_ant;
            });
        }
    }
    function calc_kml(){
        var kmant = document.getElementById('abast_hod_ant_editr').value;
        var km = document.getElementById('abast_hodometro_editr').value;
        var litros = document.getElementById('abast_litros_editr').value;
        litros = litros.replace(".", "")
        litros = litros.replace(",", ".");
        var kml1 = parseFloat(km) - parseFloat(kmant);
        var kml = parseFloat(kml1) / parseFloat(litros);
        if(!Number.isNaN(kml)){
            document.getElementById('abast_kml_editr').value = kml.toFixed(2).replace(".", ",");
        }
        
    }
    function get_func(mat, campo){
        var mat_mot = document.getElementById(mat).value;
        if(mat_mot !== "" && mat_mot !== "0"){
            api.get('/funcionarios/' + mat_mot,
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                        } 
                    }
                )
            .then(function (response) {
                var {NOMEFUNC} = response.data;
                document.getElementById(campo).value = NOMEFUNC;
            });
        }else{
            document.getElementById(campo).value = "";
        }
    }

    function salva_abast(){
        var data = document.getElementById('abast_data_editr').value;
        var km = document.getElementById('abast_hodometro_editr').value;
        var litros = document.getElementById('abast_litros_editr').value;
        var matmot = document.getElementById('abast_mat_mot_editr').value;
        setData({
            errorData: false,
            helperTextData: '',
        })
        setKm({
            errorKm: false,
            helperTextKm: '',
        })
        setMot({
            errorMot: false,
            helperTextMot: '',
        })
        setLitro({
            errorLitro: false,
            helperTextLitro: '',
        })
        if(data === ""){
            setData({
                helperTextData: 'Selecione uma data.',
                  errorData: true
              })
            return false;
        }
        if(km === ""){
            setKm({
                helperTextKm: 'Digite o Hodometro',
                errorKm: true
              })
            return false;
        }
        if(litros === ""){
            setLitro({
                errorLitro: true,
                helperTextLitro: 'Digite os litros.',
            })
            return false;
        }
        if(matmot === ""){
            setMot({
                helperTextMot: 'Digite a matricula do Motorista.',
                errorMot: true
            })
            return false;
        }
        var mat_manobra = null;
        if(visible){
            mat_manobra = document.getElementById('abast_mat_manobra_incluir').value;
            if(mat_manobra === ""){
                setManobra({
                    error: true,
                    helperText: 'Digite a matricula do Manobra.',
                })
                return false;
            }
        }
        var kml2 = document.getElementById('abast_kml_editr').value.replace(",", ".");
		if(kml2 < 0){
            setOpen3({
                show: true,
                text: 'km/L não pode ser negativo!',
                type: "warning"
            });
            return false;
        }
        var cap = document.getElementById('abast_cap_editr').value;
        cap = cap/1;
        if(cap !== 0 && cap !== ""){
            var litros2 = litros.replace(".", "");
            litros2 = litros2.replace(",", ".");
            litros2 = litros2/1;
            if(litros2 > cap){
                setOpen3({
                    show: true,
                    text: 'Quantidade de litros abastecido não pode ser maior que a capacidade do tanque!',
                    type: "warning"
                });
                return false;
            }
        }
        var km_ant = document.getElementById('abast_hod_ant_editr').value;
        km_ant = km_ant/1;
        km = km/1;
        if(km_ant > km){
            setOpen3({
                show: true,
                text: 'Hodometro atual menor que o anterior!',
                type: "warning"
            });
            return false;
        }
        data = data.split("-");
        data = data[2] + '/'+ data[1]+ '/'+ data[0];
        api.get('/abastecimento/'+id+'/edit',
        { 
            headers: { 
                user: getUser(),
                empresa,
                contrato,
                filial,
                data, 
                km, 
                litros,
                matmot,
                matmanobra: mat_manobra
            } 
        })
        .then(function(response){
            var {status, erro} = response.data;
            if(status === 1){
                setOpen3({
                    show: true,
                    text: 'Alteração salva com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
            }
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Alterar Abastecimento</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="abast_placa_edit">Placa</InputLabel>
                               <TextField
                                    size="small"
                                    id="abast_placa_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="abast_cap_editr">Cap. tanque</InputLabel>
                               <TextField
                                size="small"
                                    id="abast_cap_editr"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="abast_hod_ant_editr">Hodometro ant</InputLabel>
                               <TextField
                                size="small"
                                    id="abast_hod_ant_editr"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_data_editr">Data</InputLabel>
                               <TextField
                                type="date"
                                size="small"
                                error={formData.errorData}
                                helperText={formData.helperTextData}
                                    id="abast_data_editr"
                                    onChange={() => {carrega_km_ant()}}
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_hodometro_editr">Hodometro</InputLabel>
                               <TextField
                                size="small"
                                error={formKm.errorKm}
                                helperText={formKm.helperTextKm}
                                    id="abast_hodometro_editr"
                                    type="number"
                                    onChange={() => calc_kml()}
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_litros_editr">Litros</InputLabel>
                                <Input
                                size="small"
                                id="abast_litros_editr"
                                error={formLitro.errorLitro}
                                type="text"
                                onChange={() => calc_kml()}
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formLitro.errorLitro}>{formLitro.helperTextLitro}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="abast_kml_editr">km/L</InputLabel>
                               <TextField
                                size="small"
                                    id="abast_kml_editr"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_mat_mot_editr">Motorista</InputLabel>
                               <TextField
                                size="small"
                                error={formMot.errorMot}
                                helperText={formMot.helperTextMot}
                                    id="abast_mat_mot_editr"
                                    type="number"
                                    className={classes.textField}
                                    onChange={() => get_func('abast_mat_mot_editr', 'abast_nome_editr')}
                                />
                            </Form.Group>
                        </Grid>
                        { /*<Grid item xs={1}>
                            <FontAwesomeIcon icon={faBinoculars} size="2x"/>
                        </Grid>*/ }
                        <Grid item xs={9}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="abast_nome_editr">Nome</InputLabel>
                               <TextField
                                size="small"
                                    id="abast_nome_editr"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        {visible ? (
                            <>
                                <Grid item xs={3}>
                                    <Form.Group> 
                                        <InputLabel className={classes.linha} htmlFor="abast_mat_manobra_incluir">Manobra</InputLabel>
                                       <TextField
                                        size="small"
                                        error={formManobra.error}
                                        helperText={formManobra.helperText}
                                            id="abast_mat_manobra_incluir"
                                            type="number"
                                            className={classes.textField}
                                            onChange={() => get_func("abast_mat_manobra_incluir", "abast_nome_manobra_incluir")}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={9}>
                                    <Form.Group className={classes.disabled}> 
                                        <InputLabel className={classes.linha}  htmlFor="abast_nome_manobra_incluir">Nome</InputLabel>
                                       <TextField
                                        size="small"
                                            id="abast_nome_manobra_incluir"
                                            type="text"
                                            className={classes.textField}
                                            disabled
                                        />
                                    </Form.Group>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => {salva_abast()}}>Salvar</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;