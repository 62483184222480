import React, {useState, useEffect} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Table } from "tabler-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconContext } from "react-icons";
import { FaCheck } from 'react-icons/fa';

import api from '../../../../../../services/api'
import { getUser} from "../../../../../../services/auth";

import { makeStyles } from '@material-ui/core/styles';

import { Logo2 } from './styles';

import logo_icon from '../../../../../../img/icon.png';
import DialogTitle from '../DialogTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    linha: {
        fontSize: 12,
        padding: "5px !important"
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));



function ModalLista(props) {
    const [open, setOpen] = useState(true);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const open4 = false;
    var {empresa, contrato, filial, id, onClose, url, ...rest} = props;
    const classes = useStyles();


    useEffect (() => {
        setOpen(true);
        async function carrega_lista(){
            if(id !== 0){
                api.get(url + id,
                { 
                    headers: { 
                            user: getUser(),
                        } 
                    }
                )
                .then(function (response) {
                    var data = response.data;
                    var json = data;
                    data = JSON.stringify(json);
                    data = JSON.parse(data);
                    if(json !== null && json !== ''  && json !== []){
                        let where2 = [];
                        var dados = json[0];
                        for(var colunas in dados) {
                            let o2 = { 
                                dataField: colunas,
                                text: colunas, 
                            };
                            where2.push(o2);
                        }
                        setColumns(where2);
                        setProducts(json);
                        setLista(true);
                    }else{
                        setLista(false);
                        setProducts(null);
                    }
                    setOpen(false);
                });
            }
        }
        carrega_lista();
      }, [empresa, contrato, filial, id, url]);

  return (<Dialog
    {...rest}
    aria-labelledby="form-dialog-title"
    onClose={onClose}
    >
        <DialogTitle onClose={onClose} open={open} open4={open4}>
            Presenças
        </DialogTitle>
        <DialogContent className={classes.root}>
        {open ? (
            <Logo2>
                <img src={logo_icon} alt="" />
                <CircularProgress />
            </Logo2>
        ) : (
            <>
                {lista && (
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                if(name === "Av. Treina" || name === "Av. Instru" || name === "Retenção"){
                                                    if(dados[name] === 1){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <div className={classes.linha2}>
                                                                        <IconContext.Provider value={{ size: "1.1em"}}>
                                                                            <FaCheck/>
                                                                        </IconContext.Provider>
                                                                    </div>
                                                                </Table.Col>); 
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                        </Table.Col>); 
                                                    }
                                                }else{
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>); 
                                                }
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                )}
            </>
        )}
        </DialogContent>
</Dialog>);
}

export default ModalLista;