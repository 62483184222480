import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Logo2 } from '../Treinamentos/styles';
import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';

import CircularProgress from '@material-ui/core/CircularProgress';
import logo_icon from '../../img/icon.png';
import Body from './components/Body';

import api from '../../services/api'

import {getEmpresas, getContratos, getFilials} from "../../services/auth";

const drawerWidth = 63;
const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        marginLeft: drawerWidth,
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function PreContra({ history }) {
    const classes = useStyles();
    const [loadding, setLoadding] = useState({ show: true });
    const [empresas, setEmpresas] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [filiais, setFiliais] = useState([]);
    const [mesdados, setMesdados] = useState([]);
    useEffect (() => {
        setEmpresas(JSON.parse(getEmpresas()));
        setContratos(JSON.parse(getContratos()));
        setFiliais(JSON.parse(getFilials()));
        setLoadding( {show: false })
        async function loadMes(){
            await api.get(`/precontra/mes`,{
                headers:{
                }
            }).then(function (response) {
                setMesdados(response.data);
            });
        }
        loadMes();
    }, []);
  return (
        <>
        {loadding.show ? (
            <Logo2>
                <img src={logo_icon} alt="" />
                <CircularProgress />
            </Logo2>
        ) : (
            <>
                <Hearder history={history} />
                <Menu history={history} />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Body
                    empresas={empresas}
                    contratos={contratos}    
                    filiais={filiais}
                    mesdados={mesdados}
                    />
                </main>
            </>
        )}
    </>
  );
}

export default PreContra;