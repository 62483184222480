import React, {useState} from 'react';


import Button from '@material-ui/core/Button';
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ModalViewQuiz from '../ModalViewQuiz';

import api from '../../../../../../../../services/api'

// import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    botao: {
        padding: 0,
    }
}));


function TableRowQuiz({columns, dados, altera, setAltera}) {
    const classes = useStyles();
    const [open4, setOpen4] = useState(false);
   
    const [id_temp, setId_temp] = useState(0);
    const handleClickOpen4 = (id) => {
        setOpen4(true);
        setId_temp(id);
    };
    const handleClose4 = () => {
        setOpen4(false);
        setId_temp(0);
    };

    const [modalViewQuiz, setModalViewQuiz] = useState({
        status: false,
        id: 0,
    });

    function carrega_quiz(id){
        setModalViewQuiz({
            status: true,
            id: id,
        });
    }
    function del_quiz(){
        api.delete('http://www.cilog.com.br/backend/public/perguntaTreinamaento/' + id_temp)
        .then(function(response){
            var cont = altera;
            cont = cont + 1;
            setAltera(cont);
        });
    }

  return (
        <Table.Row>
            {columns.map((column, index) => {
                var name = column.text;
                if(name !== "id"){
                    if(name === "Excluir"){
                        // eslint-disable-next-line eqeqeq
                        if(dados[name] != 0){
                            return (<Table.Col className={classes.linha} key={dados.id + name}>
                                        <div className={classes.linha2}>
                                            <Tooltip title="Excluir">
                                                <IconButton  
                                                size="small"
                                                color="secondary"
                                                key={dados.id} 
                                                onClick={() => handleClickOpen4(dados.id)}
                                                aria-label="Excluir">
                                                    <DeleteForeverIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                </Table.Col>);
                        }
                    }else{
                        if(name === "Detalhes"){
                            // eslint-disable-next-line eqeqeq
                            if(dados[name] != 0){
                                return (<Table.Col className={classes.linha} key={dados.id + name}>
                                            <div className={classes.linha2}>
                                                <Tooltip title="Detalhes">
                                                    <IconButton  
                                                    size="small"
                                                    color="primary" 
                                                    key={dados.id} 
                                                    onClick={() => carrega_quiz(dados.id)}
                                                    aria-label="Detalhes">
                                                        <FindInPageIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                    </Table.Col>);
                            }
                        }else{
                            return (<Table.Col className={classes.linha} key={dados.id + name}>
                                    <div className={classes.linha2}>
                                        {dados[name]}
                                    </div>
                                </Table.Col>); 
                        }
                    }
                }
                return false;
            })}
            <ModalViewQuiz
            open={modalViewQuiz.status}
            onClose={() => setModalViewQuiz({
                'status': false,
                'id': 0
            })}
            maxWidth="sm"
            fullWidth={true}
            id={modalViewQuiz.id}
            />
            <Dialog
                open={open4}
                onClose={handleClose4}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Essa operação não pode ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose4} color="default">
                        Cancelar
                    </Button>
                    <Button onClick={() => del_quiz()} variant="contained" color="secondary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </Table.Row>
  );
}

export default TableRowQuiz;