import styled from 'styled-components';

export const Logo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;
    background: #ecf1f8;

    form {
        width: 100%;
        max-width: 400px;
        padding: 20px;
        height: 400px;;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
        border-top: 20px solid rgba(230, 236, 245, 0.4);
        justify-Content: center;
        align-Items: center;
        img {
            height: 80px;
            width: 80px;
            margin-bottom: 30px;
        }
        div {
            
            font-size: 20px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
        }
    }
`;

export const Priva = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
    font: 18px 'Roboto', sans-serif;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;
    background: #ecf1f8;
    img {
        height: 80px;
        width: 80px;
        justify-Content: center;
        align-Items: center;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;
    font-weight: bold;
    margin: 20px;
    font: 28px 'Roboto', sans-serif;
`;
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-Content: left;
`;
export const Text2 = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-Content: left;
    margin-top: 40px;
`;
export const Container = styled.div`
    background: #FFF;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
    border-top: 20px solid rgba(230, 236, 245, 0.4);
`;

export const Logo2 = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;
    margin-bottom: 10px;
    img {
        height: 80px;
        width: 80px;
    }
    
`;