import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput from "../../../../functions/CurrencyInput"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../components/Styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'

import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getMat, getUser} from "../../../../services/auth";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Select } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput
        {...other}
        
      />
    );
}

function ModalAddMovi(props) {
    const classes = Styles();
    var {empresa, contrato, filial, onClose, consulta, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [save, setSave] = useState(false);
    const [formMEntrada, setMEntrada] = useState({
        error: false,
        helperText: '',
    })
    const [formMsaida, setMSaida] = useState({
        error: false,
        helperText: '',
    })
    const [ placas, setPlacas] = useState([]);
    const [ selectPlacas, setSelectPlacas] = useState(null);
    const [ posicoes, setPosicoes] = useState([]);
    const [ posicoesGlobal, setPosicoesGlobal] = useState([]);

    useEffect (() => {
        async function carrega(){
            setOpen(true);
            api.get('/milimetragem',
            { 
                headers: { 
                    user: getUser(),
                    empresa,
                    contrato,
                    filial,
                } 
            })
            .then(function(response){
                setOpen(false);
                console.log(response.data)
                let {placas} = response.data;
                let placasArray = [];
                let posicoesArray = [];
                if(placas != null){
                    if(placas.length > 0){
                        placas.forEach(element => {
                            placasArray.push({
                                id: element.id,
                                placa: element.descr
                            })
                            let posicoes = element.dados.posicoes;
                            if(posicoes != null){
                                if(posicoes.length > 0){
                                    posicoes.forEach(posicao => {
                                        posicoesArray.push({
                                            placaId:  element.id,
                                            cod: posicao.cod,
                                        })
                                    })
                                }
                            }
                        });
                    }
                }
                placasArray.sort((a,b) => (a.placa > b.placa) ? 1 : ((b.placa > a.placa) ? -1 : 0))
                setPlacas(placasArray)
                setPosicoesGlobal(posicoesArray)
            }).catch(function(error){
                setSave(false);
                setOpen3({
                    show: true,
                    text:  "Ocorreu um erro!",
                    type: "error"
                });
            })
        }
        carrega();
      }, [empresa, contrato, filial]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    
    function salvar(){
        let posicao_add = document.getElementById('posicao_add').value;
        let m1_entrada_add = document.getElementById('m1_entrada_add').value;
        let m2_entrada_add = document.getElementById('m2_entrada_add').value;
        let m3_entrada_add = document.getElementById('m3_entrada_add').value;
        let m4_entrada_add = document.getElementById('m4_entrada_add').value;
        let m1_saida_add = document.getElementById('m1_saida_add').value;
        let m2_saida_add = document.getElementById('m2_saida_add').value;
        let m3_saida_add = document.getElementById('m3_saida_add').value;
        let m4_saida_add = document.getElementById('m4_saida_add').value;
        let motivo = document.getElementById('motivo_add').value;
        let fogosaida = document.getElementById('fogo_saida_add').value;
        let fogoentrada = document.getElementById('fogo_entrada_add').value;
        let data = document.getElementById('data_add').value;
        setMSaida({
            error: false,
            helperText: '',
        })
        setMEntrada({
            error: false,
            helperText: '',
        })
        if(selectPlacas == null || selectPlacas == ''){
            setOpen3({
                show: true,
                text: 'Selecione a placa!',
                type: "error"
            });
            return false;
        }
        if(data == null || data == ''){
            setOpen3({
                show: true,
                text: 'Preencha a data!',
                type: "error"
            });
            return false;
        }
        if(posicao_add == null || posicao_add == ''){
            setOpen3({
                show: true,
                text: 'Selecione a posicao!',
                type: "error"
            });
            return false;
        }
        if(motivo == null || motivo == ''){
            setOpen3({
                show: true,
                text: 'Preencha o motivo!',
                type: "error"
            });
            return false;
        }
        if(fogosaida == null || fogosaida == ''){
            setOpen3({
                show: true,
                text: 'Preencha o fogo de saida!',
                type: "error"
            });
            return false;
        }
        if(m1_saida_add == null || m1_saida_add == ''){
            setMSaida({
                helperText: 'Preencha as milimetragens de Saida.',
                error: true
            })
            return false;
        }
        if(m2_saida_add == null || m2_saida_add == ''){
            setMSaida({
                helperText: 'Preencha as milimetragens de Saida.',
                error: true
            })
            return false;
        }
        if(fogoentrada == null || fogoentrada == ''){
            setOpen3({
                show: true,
                text: 'Preencha o fogo de entrada!',
                type: "error"
            });
            return false;
        }
        if(m1_entrada_add == null || m1_entrada_add == ''){
            setMEntrada({
                helperText: 'Preencha as milimetragens de entrada.',
                error: true
            })
            return false;
        }
        if(m2_entrada_add == null || m2_entrada_add == ''){
            setMEntrada({
                helperText: 'Preencha as milimetragens de entrada.',
                error: true
            })
            return false;
        }
        setSave(true)
        api.post('/pneu/movimentacao',
        { 
            idfunc: getMat(),
            empresa,
            contrato,
            filial,
            placa: selectPlacas.id,
            posicao: posicao_add,
            fogosai: fogosaida,
            fogoentra: fogoentrada,
            mil1: m1_entrada_add,
            mil2: m2_entrada_add,
            mil3: m3_entrada_add,
            mil4: m4_entrada_add,
            mil1_sai: m1_saida_add,
            mil2_sai: m2_saida_add,
            mil3_sai: m3_saida_add,
            mil4_sai: m4_saida_add,
            data,
            motivo,
        })
        .then(function(response){
            setSave(false)
            var {status, message} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Movimentação salva com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
        }).catch(function(error){
            setSave(false);
            setOpen3({
                show: true,
                text:  "Ocorreu um erro!",
                type: "error"
            });
        })
    }
    const handleChangePlaca = (event, newValue) => {
        if(newValue != null){
            setSelectPlacas(newValue)
            let posicoesTemp = posicoesGlobal.filter((posicaoGlobal) => posicaoGlobal.placaId == newValue.id );
            setPosicoes(posicoesTemp)
        }else{
            setPosicoes([])
            setSelectPlacas(null)
        }
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Incluir Movimentação</DialogTitle>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <DialogContent className={classes.root}>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="placa_add">Placa</InputLabel>
                                    <Autocomplete
                                        className={classes.textField}
                                        options={placas}
                                        getOptionLabel={(option) => option.placa}
                                        id="placa_add"
                                        onChange={handleChangePlaca}
                                        value={selectPlacas}
                                        openOnFocus
                                        renderInput={(params) => <TextField placeholder='Selecione...' {...params} />}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="placa_add">Data</InputLabel>
                                    <TextField
                                        size="small"
                                        id="data_add"
                                        type="date"
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="posicao_add">Posição</InputLabel>
                                    <Select
                                    native
                                    className={classes.textField}
                                    inputProps={{
                                        name: 'posicao_add',
                                        id: 'posicao_add',
                                    }}
                                    >
                                        <option value="">Selecione...</option>
                                        {posicoes.map((dados, index) => (
                                            <option key={dados.cod} value={dados.cod}>{dados.cod}</option>
                                        ))}
                                    </Select>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="motivo_add">Motivo</InputLabel>
                                    <TextField
                                        size="small"
                                        id="motivo_add"
                                        type="text"
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={4}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="fogo_saida_add">Fogo Saida</InputLabel>
                                    <TextField
                                        size="small"
                                        id="fogo_saida_add"
                                        type="text"
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m1_saida_add">M1 Saida</InputLabel>
                                    <Input
                                    size="small"
                                    id="m1_saida_add"
                                    error={formMsaida.error}
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                    <FormHelperText  error={formMsaida.error}>{formMsaida.helperText}</FormHelperText>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m2_saida_add">M2 Saida</InputLabel>
                                    <Input
                                    size="small"
                                    id="m2_saida_add"
                                    error={formMsaida.error}
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                    <FormHelperText  error={formMsaida.error}>{formMsaida.helperText}</FormHelperText>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m3_saida_add">M3 Saida</InputLabel>
                                    <Input
                                    size="small"
                                    id="m3_saida_add"
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m4_saida_add">M4 Saida</InputLabel>
                                    <Input
                                    size="small"
                                    id="m4_saida_add"
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={4}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="fogo_saida_add">Fogo Entrada</InputLabel>
                                    <TextField
                                        size="small"
                                        id="fogo_entrada_add"
                                        type="text"
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m1_entrada_add">M1 Entrada</InputLabel>
                                    <Input
                                    size="small"
                                    id="m1_entrada_add"
                                    error={formMEntrada.error}
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                    <FormHelperText  error={formMEntrada.error}>{formMEntrada.helperText}</FormHelperText>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m2_entrada_add">M2 Entrada</InputLabel>
                                    <Input
                                    size="small"
                                    id="m2_entrada_add"
                                    error={formMEntrada.error}
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                    <FormHelperText  error={formMEntrada.error}>{formMEntrada.helperText}</FormHelperText>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m3_entrada_add">M3 Entrada</InputLabel>
                                    <Input
                                    size="small"
                                    id="m3_entrada_add"
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="m4_entrada_add">M4 Entrada</InputLabel>
                                    <Input
                                    size="small"
                                    id="m4_entrada_add"
                                    type="text"
                                    className={classes.textField}
                                    inputComponent={TextMaskCustom}
                                    />
                                </Form.Group>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" disabled={save} onClick={onClose}>Cancelar</Button>
                        <div className={classes.wrapper}>
                            <Button color="primary" disabled={save} onClick={() => {salvar()}} variant="contained">Salvar</Button>
                            {save && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </DialogActions>
                </>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalAddMovi;