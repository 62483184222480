import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        fontSize: "30px",
        color: "#FFF",
        background: "#dc3545"
    }, 
    border: {
        borderColor: "##f5c6cb",
    }, 
    title: {
        fontSize: "35px !important",
        color: "#FFF",
        background: "#dc3545"
    }, 
}));

function Modal(props) {
    var {data, ...rest} = props;
    const classes = useStyles();

  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        className={classes.border}
        >
            <DialogContent className={classes.title} >
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item xs={2}>
                        <ReportProblemIcon fontSize="large"/>
                    </Grid>
                    <Grid item xs={10}>
                            Solicitado Socorro em Rota
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogContent className={classes.body}>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    {data.map((dados, index) => (
                        <Grid container key={dados.id}>
                            <Grid item xs={4}>
                                Placa: {dados.placa}
                            </Grid>
                            <Grid item xs={4}>
                                Problema: {dados.problemas}
                            </Grid>
                            <Grid item xs={4}>
                                Tempo: {dados.hora}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
    </Dialog>
  );
}

export default Modal;