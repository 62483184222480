import React from 'react';

import { Logo } from '../styles';
import logo_icon from '../../img/icon.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { logout, setModulos } from '../../services/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '80%',
  },
  bt: {
    marginTop: 40,
  },
  title: {
    fontFamily: 'roboto'
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
    width: "80%"
  },
}))(Button);


function SemAcesso({ history }) {
  const classes = useStyles();
  function sair() {
    logout();
    setModulos([]);
    history.push('/');
}
  return (
    <Logo>
      <form>
        <img src={logo_icon} alt="CILog" />
        <h2 className={classes.title}>CILog</h2>
        <div style={{textAlign: 'center', fontSize: 30, marginTop: 20}}>
            Usuário sem acesso
        </div>
        <div style={{textAlign: 'center', fontSize: 14, marginTop: 20}}>
            Entre em contato com o administrador do sistema!
        </div>
        <ColorButton className={classes.bt} variant="contained" color="primary" type="button" onClick={() => sair()}>
          Sair
        </ColorButton>
      </form>
    </Logo>
  );
}

export default SemAcesso;