import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Form } from 'react-bootstrap';


//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'

import DialogTitle from '../../../../../components/BoardCheck/Components/DialogTitle'
import api from '../../../../../services/api'
import {cpfMask} from  '../../../../../services/mask'

import { getUser} from "../../../../../services/auth";

import Style from '../../../../../components/Styles';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalAdd(props) {
    const classes = Style();
    const [documentId, setDocumentId] = useState('');
    const [open2, setOpen2] = useState(false);
    var {empresas, contratos, filiais, onClose, funcaoAnt, ...rest} = props;

    const [formMat, setformMat] = useState({
        error: false,
        helperText: '',
    })
    const [formNome, setformNome] = useState({
        error: false,
        helperText: '',
    })
    const [formcpf, setformcpf] = useState({
        error: false,
        helperText: '',
    })
    const [formNasc, setformNasc] = useState({
        error: false,
        helperText: '',
    })
    const [formFuncao, setformFuncao] = useState({
        error: false,
        helperText: '',
    })
    
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const handlechange = (e) => {
        setDocumentId(cpfMask(e.target.value))
    }
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    function TestaCPF(strCPF) {
        var Soma;
        var Resto;
        Soma = 0;
        // eslint-disable-next-line eqeqeq
        if (strCPF == "00000000000") return false;
        let i;
        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
            // eslint-disable-next-line eqeqeq
            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            // eslint-disable-next-line eqeqeq
            if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
        
        Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;
        
            // eslint-disable-next-line eqeqeq
            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            // eslint-disable-next-line eqeqeq
            if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
            return true;
    }
    function salva_Funcionario(){
        var mat = document.getElementById('mat_incluir').value;
        var nome = document.getElementById('nome_incluir').value;
        var cpf = document.getElementById('cpf_incluir').value;
        var nasc = document.getElementById('nasc_incluir').value;
        var funcao = document.getElementById('funcao_incluir').value;

        setformMat({
            error: false,
            helperText: '',
        })
        setformNome({
            error: false,
            helperText: '',
        })
        setformcpf({
            error: false,
            helperText: '',
        })
        setformNasc({
            error: false,
            helperText: '',
        })
        setformFuncao({
            error: false,
            helperText: '',
        })
        if(mat === ""){
            setformMat({
                helperText: 'Preencha a matricula.',
                error: true
            })
            return false;
        }
        if(nome === ""){
            setformNome({
                helperText: 'Preencha o nome.',
                error: true
            })
            return false;
        }
        if(cpf === ""){
            setformcpf({
                helperText: 'Preencha o CPF.',
                error: true
            })
            return false;
        }
        if(cpf.length !== 14){
            setformcpf({
                helperText: 'CPF deve ter 11 digitos.',
                error: true
            })
            return false;
        }
        cpf = cpf.split('.').join('');
        cpf = cpf.split('-').join('');
        if(!TestaCPF(cpf)){
            setformcpf({
                helperText: 'CPF não é valido.',
                error: true
            })
            return false;
        }
        if(nasc === ""){
            setformNasc({
                helperText: 'Preencha a data de nascimento.',
                error: true
            })
            return false;
        }
        if(funcao === ""){
            setformFuncao({
                helperText: 'Selecione a função.',
                error: true
            })
            return false;
        }
        setOpen2(true);
        var {empresa, contrato, filial} = state;
        api.post('/funcionarios',
        { 
            user: getUser(),
            empresa,
            contrato,
            filial,
            mat,
            nome,
            cpf,
            nasc,
            funcao
        })
        .then(function(response){
            var {status} = response.data;
            if(status){
                setOpen3({
                    show: true,
                    text: 'Usuário cadastrado com sucesso!',
                    type: "success"
                });
                onClose();
                funcaoAnt();
            }else{
                var { message } = response.data;
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
            }
            setOpen2(false);
        })
    }
    function cpfIsValid(){
        var cpf = document.getElementById('cpf_incluir').value;
        if(cpf.length !== 14){
            setformcpf({
                helperText: 'CPF deve ter 11 digitos.',
                error: true
            })
            return false;
        }
        cpf = cpf.split('.').join('');
        cpf = cpf.split('-').join('');
        if(!TestaCPF(cpf)){
            setformcpf({
                helperText: 'CPF não é valido.',
                error: true
            })
            return false;
        }
        setformcpf({
            error: false,
            helperText: '',
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false}>Incluir Funcionario</DialogTitle>
        <DialogContent className={classes.root}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                    <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                    <Select
                    native
                    value={state.empresa}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'empresa',
                        id: 'abast_empresa',
                    }}
                    >
                        {empresas.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                        
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                    <Select
                    native
                    value={state.contrato}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'contrato',
                        id: 'abast_contrato',
                    }}
                    >
                        {contratos.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                    <Select
                    native
                    value={state.filial}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'filial',
                        id: 'abast_filial',
                    }}
                    >
                        {filiais.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="mat_incluir">Matricula</InputLabel>
                       <TextField
                            size="small"
                            id="mat_incluir"
                            type="number"
                            error={formMat.error}
                            className={classes.textField}
                        />
                        <FormHelperText  error={formMat.error}>{formMat.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={8}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="nome_incluir">Nome</InputLabel>
                       <TextField
                            size="small"
                            id="nome_incluir"
                            type="text"
                            error={formNome.error}
                            className={classes.textField}
                        />
                        <FormHelperText  error={formNome.error}>{formNome.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="cpf_incluir">CPF</InputLabel>
                       <TextField
                            size="small"
                            id="cpf_incluir"
                            type="text"
                            error={formcpf.error}
                            value={documentId}
                            onChange={handlechange}
                            onBlur={cpfIsValid}
                            className={classes.textField}
                        />
                        <FormHelperText  error={formcpf.error}>{formcpf.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="nasc_incluir">Data Nascimento</InputLabel>
                        <Form.Control 
                            type="date" 
                            name='date_of_birth'
                            id="nasc_incluir"
                            className={classes.textFieldDate}
                         />
                        <FormHelperText  error={formNasc.error}>{formNasc.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={5}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="funcao_incluir">Função</InputLabel>
                        <Select
                        native
                        error={formFuncao.error}
                        size="small"
                        id="funcao_incluir"
                        className={classes.textField}
                        >
                            <option value={''}>Selecione...</option>
                            <option value={'MOTORISTA CAMINHAO TOCO/TRUCK'}>MOTORISTA</option>
                            <option value={'MOTORISTA CARRETA'}>MOTORISTA CARRETA</option>
                            <option value={'AJUDANTE MOTORISTA'}>AJUDANTE</option>
                            <option value={'ADMINISTRATIVO'}>ADMINISTRATIVO</option>
                        </Select>
                        <FormHelperText  error={formFuncao.error}>{formFuncao.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={open2} onClick={onClose}>Cancelar</Button>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={open2}
                    onClick={salva_Funcionario}
                    >
                    Salvar
                </Button>
                {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </DialogActions>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        </Dialog>
  );
}

export default ModalAdd;