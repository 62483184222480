export const TOKEN_KEY = null;
export const MAT = "@Cilog-mat";
export const FILIAL = "@Cilog-filial";
export const FILIALS = "@Cilog-filials";
export const REUNIAO ="@Cilog-reuniao";
export const CONTRATO ="@Cilog-contrato";
export const CONTRATOS ="@Cilog-contratos";
export const EMPRESA = "@Cilog-empresa";
export const EMPRESAS = "@Cilog-empresas";
export const SETOR = "@Cilog-setor";
export const INDICADOR ="@Cilog-idndicador";
export const TODOS ="@Cilog-todos";
export const TYPE = "@Cilog-type";
export const NIVEL = "@Cilog-nivel";
export const USER = "@Cilog-user";
export const MODULOS = "@Cilog-modulos";
export const OPES = "@Cilog-opes";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getMat = () => localStorage.getItem(MAT);
export const getFilial = () => localStorage.getItem(FILIAL);
export const getFilials = () => localStorage.getItem(FILIALS);
export const getReuniao = () => localStorage.getItem(REUNIAO);
export const getContrato = () => localStorage.getItem(CONTRATO);
export const getContratos = () => localStorage.getItem(CONTRATOS);
export const getSetor = () => localStorage.getItem(SETOR);
export const getEmpresa = () => localStorage.getItem(EMPRESA);
export const getEmpresas = () => localStorage.getItem(EMPRESAS);
export const getIndicador = () => localStorage.getItem(INDICADOR);
export const getTodos = () => localStorage.getItem(TODOS);
export const getusertype = () => localStorage.getItem(TYPE);
export const getusernivel = () => localStorage.getItem(NIVEL);
export const getUser = () => localStorage.getItem(USER);
export const getModulos = () => localStorage.getItem(MODULOS);
export const getOpes = () => localStorage.getItem(OPES);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  
};
export const setMat = mat => {
  localStorage.setItem(MAT, mat);
};
export const setFilial2 = filial => {
  localStorage.setItem(FILIAL, filial);
};
export const setFilials = filials => {
  localStorage.setItem(FILIALS, filials);
};
export const setReuniao2 = reuniao => {
  localStorage.setItem(REUNIAO, reuniao);
};
export const setContrato2 = contrato => {
  localStorage.setItem(CONTRATO, contrato);
};
export const setContratos2 = contratos => {
  localStorage.setItem(CONTRATOS, contratos);
};
export const setEmpresa = empresa => {
  localStorage.setItem(EMPRESA, empresa);
};
export const setEmpresas2 = empresas => {
  localStorage.setItem(EMPRESAS, empresas);
};
export const setTodos = todos => {
  localStorage.setItem(TODOS, todos);
};
export const setSetor2 = setor => {
  localStorage.setItem(SETOR, setor);
};
export const setIndicador2 = indicador => {
  localStorage.setItem(INDICADOR, indicador);
};
export const setusertype = type => {
  localStorage.setItem(TYPE, type);
};
export const setusernivel = nivel => {
  localStorage.setItem(NIVEL, nivel);
};
export const setUser = user => {
  localStorage.setItem(USER, user);
};
export const setModulos = modulos => {
  localStorage.setItem(MODULOS, modulos);
}
export const setOpes = opes => {
  localStorage.setItem(OPES, opes);
}