import styled from 'styled-components';

export const Container = styled.div`
    
`;

export const Header = styled.div`
    padding: 15px;
    float: left;
    width: 100%;
    height: 60px;
`;
export const Body = styled.div`
`;
export const Progress = styled.div`
   justify-content: center;
   display: flex;
   margin-top: 10px
`;
export const Titulo = styled.div`
    margin-top: 25px;
    margin-left: 10px;
    max-height: 20px;
`;
export const Corpo = styled.div`
    float: left;
`;
