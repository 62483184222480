import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import "tabler-react/dist/Tabler.css";
import { Badge } from "tabler-react";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ModalImage from './ModalImage';
import ModalObs from './ModalObs';
import Styles from '../../Styles';

import DialogTitle from '../Components/DialogTitle'

import ImagePrev from "react-modal-image";

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function ModalEdit3(props) {
    const classes = Styles();
    var {id, empresa, contrato, filial, consulta, ope, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(false);
    const [open4, setOpen4] = useState({
        open: false,
        id: 0,
    });
    const [image, setImage] = useState('http://www.cilog.com.br/backend/public/images/itens/item_prev.png');

    const [contagem, setContagem] = useState(true)
    const [combustivel, setCombustivel] = useState(true)
    const [opcao1, setOpcao1] = useState('');
    const [opcao2, setOpcao2] = useState('');
    const [opcao3, setOpcao3] = useState('');
    async function carrega_obs_item_check(){
        setOpen(true);
        await api.get('/itensobs/' + id,
            { 
                headers: { 
                        empresa, 
                        contrato, 
                        filial, 
                        ope,
                     } 
                }
            )
        .then(function (response) {
            setOpen(false);
            var json = response.data;
            // eslint-disable-next-line array-callback-return
            json.map((column)  => {
                // eslint-disable-next-line eqeqeq
                if(column.cont == 1){
                    setOpcao1(column.descr)
                }
                // eslint-disable-next-line eqeqeq
                if(column.cont == 2){
                    setOpcao2(column.descr)
                }
                // eslint-disable-next-line eqeqeq
                if(column.cont == 3){
                    setOpcao3(column.descr)
                }
            })
        });
    }
    useEffect (() => {
        async function carrega_dados(){
            setOpen(true);
            await api.get('/itenschecklist/' + id,
                { 
                    headers: {
                            user: getUser(),
                            empresa, 
                            contrato, 
                            filial,
                            ope,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let json = response.data
                let { nome, id_tipos, quant, mostrar, prazo, opeDescr, imagem, imageName, veiculo} = json[0];
                document.getElementById('item_nome').value = nome;
                document.getElementById('item_tipo').value = id_tipos;
                document.getElementById('item_prazo').value = prazo;
                document.getElementById('item_quantidade').value = quant;
                document.getElementById('item_mostrar').value = mostrar;
                // eslint-disable-next-line eqeqeq
                if(quant == 1){
                    setContagem(false);
                }
                if(opeDescr === "TR" || opeDescr === "IN"){
                    setOpen2(true);
                    document.getElementById('check_veiculo').value = veiculo;
                }
                // eslint-disable-next-line eqeqeq
                if(imagem == 1){
                    setImage('http://www.cilog.com.br/backend/public/images/itens/' + imageName);
                }
            });
        }
        carrega_dados();
        carrega_obs_item_check();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    const mudaQuant = (event, reason) => {
        // eslint-disable-next-line eqeqeq
        if(event.target.value == 1){
            setContagem(false);
        }else{
            setContagem(true);
        }
    }

    const mudaCombustivel = (event, reason) => {
        // eslint-disable-next-line eqeqeq
        if(event.target.value == 1){
            setCombustivel(false);
        }else{
            setCombustivel(true);
        }
    }
    function salva_alt_check(){
        var combustivel = document.getElementById('item_combustivel').value;
        var quant = 0;
        if(combustivel === '1'){
            quant = 1;
        }else{
            quant = document.getElementById('item_quantidade').value;
        }
        var mostrar = document.getElementById('item_mostrar').value;
        var tipo_item = document.getElementById('item_tipo').value;
       
        setOpen(true);
        api.put('/itenschecklist/'+id,
        { 
            quant,
			mostrar,
			tipo_item,
			ope,
			combustivel,
			user: getUser(),
        })
        .then(function(response){
            setOpen(false);
            onClose();
            consulta();
        })
    }

  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Detalhes Item de check list</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={6}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="item_nome">Nome</InputLabel>
                               <TextField
                                    size="small"
                                    id="item_nome"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="item_tipo">Tipo</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                id="item_tipo"
                                size="small"
                                >
                                    <option value={1}>ITENS DE SEGURANÇA: REGULARIZAÇÃO IMEDIATA</option>
                                    <option value={2}>ITENS MECÂNICOS: REGULARIZAÇÃO 48H</option>
                                    <option value={3}>ITENS TREM DE FORÇA E CARROCERIA: REGULARIZAÇÃO 30</option>
                                </Select>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="item_prazo">Prazo</InputLabel>
                               <TextField
                                size="small"
                                    id="item_prazo"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        {open2 ? (
                            <Grid item xs={4}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="check_veiculo">Veiculo</InputLabel>
                                    <Select
                                    native
                                    className={classes.textField}
                                    id="check_veiculo"
                                    size="small"
                                    >
                                        <option value={1}>Cavalo</option>
                                        <option value={2}>Carreta</option>
                                    </Select>
                                </Form.Group>
                            </Grid>
                        ): (<Grid item xs={3}></Grid>)}
                        <Grid item xs={3}>
                            <Form.Group > 
                                <InputLabel className={classes.linha} htmlFor="item_combustivel">Combustivel</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                id="item_combustivel"
                                onChange={mudaCombustivel}
                                size="small"
                                >
                                    <option value={0}>Não</option>
                                    <option value={1}>Sim</option>
                                </Select>
                            </Form.Group>
                        </Grid>
                        {combustivel ? (
                            <Grid item xs={3}>
                                <Form.Group > 
                                    <InputLabel className={classes.linha} htmlFor="item_quantidade">Quantidade</InputLabel>
                                    <Select
                                    native
                                    className={classes.textField}
                                    id="item_quantidade"
                                    onChange={mudaQuant}
                                    size="small"
                                    >
                                        <option value={0}>Não</option>
                                        <option value={1}>Sim</option>
                                    </Select>
                                </Form.Group>
                            </Grid>
                        ):(<Grid item xs={3}></Grid>)}
                        <Grid item xs={3}>
                            <Form.Group > 
                                <InputLabel className={classes.linha} htmlFor="item_mostrar">Mostrar</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                id="item_mostrar"
                                size="small"
                                >
                                    <option value={0}>Não</option>
                                    <option value={1}>Sim</option>
                                </Select>
                            </Form.Group>
                        </Grid>
                        {contagem ? (
                            <Grid item xs={8} className={classes.root2}>
                                    <InputLabel htmlFor="list_opcoes">Observações Rapidas</InputLabel>
                                    <List component="nav" id='list_opcoes' aria-label="mailbox folders" className={classes.enable}>
                                        <ListItem button onClick={() => setOpen4({open: true, id: 1, funcao: setOpcao1})}>
                                            <ListItemAvatar>
                                                <Badge color="info" className="mr-1">
                                                    1
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText primary={opcao1} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem button divider onClick={() => setOpen4({open: true, id: 2, funcao: setOpcao2})}>
                                            <ListItemAvatar>
                                                <Badge color="info" className="mr-1">
                                                    2
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText primary={opcao2} />
                                        </ListItem>
                                        <ListItem button onClick={() => setOpen4({open: true, id: 3, funcao: setOpcao3})}>
                                            <ListItemAvatar>
                                                <Badge color="info" className="mr-1">
                                                    3
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText primary={opcao3} />
                                        </ListItem>
                                    </List>
                            </Grid>
                        ) :(
                            <Grid item xs={8} >
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <Form.Group className={classes.image2}> 
                                <InputLabel className={classes.linha} htmlFor="imagePrev">Imagem</InputLabel>
                                <ImagePrev
                                    id="imagePrev"
                                    className={classes.image}
                                    small={image}
                                    large={image}
                                    alt="Item"
                                    />
                                <Grid item xs={12} className={classes.image_alt_item}>
                                    <Button
                                    size="small"
                                    color="default"
                                    className={classes.button}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => {setOpen2(true)}}
                                    >
                                        Upload
                                    </Button>
                                </Grid>
                            </Form.Group > 
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary" onClick={onClose} >Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => {salva_alt_check()}}>Salvar</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        {open2 ? (
            <ModalImage
            open={open2}
            onClose={() => setOpen2(false)}
            maxWidth="sm"
            fullWidth={true}
            id={id}
            empresa={empresa}
            contrato={contrato}
            filial={filial}
            setImage={setImage}
            />
        ) : null}
        {open4.open ? (
            <ModalObs
            open={open4.open}
            onClose={() => setOpen4({open: false, id: 0})}
            maxWidth="sm"
            fullWidth={true}
            id={id}
            empresa={empresa}
            contrato={contrato}
            filial={filial}
            opcao={open4}
            funcao={open4.funcao}
            />
        ) : null}
    </Dialog>
  );
}

export default ModalEdit3;