import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import Select from '@material-ui/core/Select';
import Styles from '../../Styles';

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function ModalEdit4(props) {
    const classes = Styles();
    var {id, empresa, contrato, filial, carregar, ope, onClose, opeDescr, ...rest} = props;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(true);

    const [formName, setName] = useState({
        error: false,
        helperText: '',
    })
    const [formVeiculo, setVeiculo] = useState({
        error: false,
        helperText: '',
    })
    const [formTipo, setTipo] = useState({
        error: false,
        helperText: '',
    })
   
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    useEffect (() => {
        if(opeDescr === "TR" || opeDescr === "IN"){
            setOpen2(true);
        }else{
            setOpen2(false);
        }
    }, [opeDescr]);

    const [state, setState] = useState({
        nome: '',
        veiculo: '',
        tipo: ''
    });

    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
        if(name === 'tipo'){
            var id_ver = event.target.value;
            if(id_ver !== ""){
                setOpen(true)
                api.get('/tipoitenschecklist/' + id_ver,
                    { 
                        headers: { 
                                empresa, 
                                contrato, 
                                filial, 
                                ope,
                            } 
                        }
                    )
                .then(function (response) {
                    setOpen(false)
                    let { prazo } = response.data;
                    document.getElementById('check_prazo').value = prazo;
                    
                });
            }else{
                document.getElementById('check_prazo').value = "";
            }
        }
    }

    function salva_alt_check(){
        var item = document.getElementById('item_check_nome').value;
        var tipo = document.getElementById('check_tipo').value;
        setName({
            error: false,
            helperText: '',
        })
        setVeiculo({
            error: false,
            helperText: '',
        })
        setTipo({
            error: false,
            helperText: '',
        })
       
        if(item === ""){
            setName({
                helperText: 'Digite o nome do item.',
                error: true
            })
            return false;
        }
        if(open2){
            var tipo_veiculo = document.getElementById('check_veiculo').value;
            if(tipo_veiculo === ""){
                setVeiculo({
                    helperText: 'Selecione o tipo do veiculo.',
                    error: true
                })
                return false;
            }
        }
        if(tipo === ""){
            setTipo({
                helperText: 'Selecione o tipo do item.',
                error: true
            })
            return false;
        }
        setOpen(true);
        api.post('/itenschecklist',
		{ 
			empresa,
			contrato,
			filial,
			item,
			tipo_veiculo,
			tipo,
			ope,
			user: getUser(),
		})
        .then(function(response){
            onClose();
            carregar(response.data);
            setOpen(false);
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Cadastrar Item no Check List</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="item_check_nome">Nome</InputLabel>
                               <TextField
                                size="small"
                                value={state.nome}
                                onChange={handleChange2}
                                inputProps={{
                                    name: 'nome',
                                    id: 'item_check_nome',
                                }}
                                error={formName.error}
                                helperText={formName.helperText}
                                    id="item_check_nome"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        {open2 ? (
                            <Grid item xs={4}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor="check_veiculo">Veiculo</InputLabel>
                                    <Select
                                    native
                                    value={state.veiculo}
                                    error={formVeiculo.error}
                                    className={classes.textField}
                                    id="check_veiculo"
                                    size="small"
                                    onChange={handleChange2}
                                    inputProps={{
                                        name: 'veiculo',
                                        id: 'check_veiculo',
                                    }}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value={1}>Cavalo</option>
                                        <option value={2}>Carreta</option>
                                    </Select>
                                    <FormHelperText  error={formVeiculo.error}>{formVeiculo.helperText}</FormHelperText>
                                </Form.Group>
                            </Grid>
                        ): null}
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="check_tipo">Tipo</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                value={state.tipo}
                                onChange={handleChange2}
                                error={formTipo.errorPlaca}
                                inputProps={{
                                    name: 'tipo',
                                    id: 'check_tipo',
                                }}
                                size="small"
                                >
                                    <option value="">Selecione...</option>
                                    <option value={1}>ITENS DE SEGURANÇA: REGULARIZAÇÃO IMEDIATA</option>
                                    <option value={2}>ITENS MECÂNICOS: REGULARIZAÇÃO 48H</option>
                                    <option value={3}>ITENS TREM DE FORÇA E CARROCERIA: REGULARIZAÇÃO 30</option>
                                </Select>
                                <FormHelperText  error={formTipo.error}>{formTipo.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="check_prazo">Prazo</InputLabel>
                               <TextField
                                    size="small"
                                    id="check_prazo"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose} >Cancelar</Button>
                <Button color="primary" variant="contained" onClick={() => {salva_alt_check()}}>Salvar</Button>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit4;