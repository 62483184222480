import React from 'react';

import { Text, View, StyleSheet, Image as ImagePdf } from '@react-pdf/renderer';

// import { Container } from './styles';

const styles2 = StyleSheet.create({
    tab: {
        width: "94%",
        flexDirection: "row",
        borderBottom: 1,
        borderColor: "#000",
        padding: 5,
        justifyContent: "space-between"
    },
    
    image: {
        width: 100,
        height: 30
    },
    text: {
        fontSize: 14,
    }
    
});


function Header({dados, base64}) {
  return (
      <View>
        <View style={styles2.tab}>
            <View  >
                {base64 &&
                    <ImagePdf 
                    allowDangerousPaths
                    style={styles2.image}  
                    src={base64}
                    alt="Logo" />
                }
                
            </View>
            <View>
                <Text>Treinamento: {dados.titulo}</Text>
            </View>
            <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Objetivo: {dados.objetivo}</Text>
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Data: {dados.data}</Text>
            </View>
            <View >
                <Text style={styles2.text}>Carga horária: {dados.carga} min</Text>
            </View>
            <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Instrutor: {dados.instrutor}</Text>
            </View>

            <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Local de realização: {dados.local}</Text>
            </View>

            <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Cliente: {dados.cliente}</Text>
            </View>
            <View >
            </View>
        </View>
    </View>
  );
}

export default Header;