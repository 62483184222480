import React, {useState} from 'react';
import { Container2, Logo2, Header } from './styles';
import ModalAdd from './ModalAdd';
import ModalEdit from './ModalEdit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../services/api'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFileExport } from '@fortawesome/free-solid-svg-icons'
import AssignmentIcon from '@material-ui/icons/Assignment';

import { getUser, getusertype} from "../../services/auth";

import { Form } from 'react-bootstrap';

import logo_icon from '../../img/icon.png';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Liberacao from './Components/Liberacao';

import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react";   
import { CSVLink } from "react-csv";

import Styles from '../Styles';
import { TextField } from '@material-ui/core';




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function BoardGas({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [id_temp, setId_temp] = useState(0);
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState({
        show: false,
        id: 0,
    });
    const [liberacao, setLiberacao] = useState({
        show: false,
        id: 0,
    });

    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");

    const handleChange5 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange6 = (event) => {
        const value = event.target.value;
        setDatefin(value);
    };
   
    var type = getusertype();
    function consultar_abastecimento(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/abastecimento/all',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            var json = response.data;
            var data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }
    function del_abast(){
        if(id_temp !== 0){
            api.delete('/abastecimento/' + id_temp)
            .then(function(response){
                let {id} = response.data;
                if(id > 0){
                    setOpen3({
                        show: true,
                        text: 'Excluido com sucesso!',
                        type: 'success'
                    });
                    consultar_abastecimento();
                }
                
            });
            setOpen2(false);
        }
    }
    function edit_abast(id){
        setModalShow2({
            show: true,
            id
        })
    }


    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [open2, setOpen2] = useState(false);

    const handleClickOpen2 = (id) => {
        setOpen2(true);
        setId_temp(id);
    };
    const handleClose2 = () => {
        setOpen2(false);
        setId_temp(0);
    };
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };

    function export_erp(){
        if(products !== null){
            var id = [];
            // eslint-disable-next-line array-callback-return
            products.map((column) => {
                let o2 = { 
                    id: column.id,
                };
                id.push(o2);
            })
            var {empresa, filial} = state;
            id = JSON.stringify(id);
            handleToggle();
            api.get('/abastecimento/erp',
            { 
                headers: { 
                        empresa, 
                        filial,
                        user: getUser(),
                        data: id
                    } 
                }
            )
            .then(function (response) {
                var {status} = response.data;
                if(status === 1){
                    var {data} = response.data;
                    const element = document.createElement("a");
                    const file = new Blob([data],    
                                {type: 'text/plain;charset=utf-8'});
                    element.href = URL.createObjectURL(file);
                    element.download = "myFile.txt";
                    document.body.appendChild(element);
                    element.click();
                }else{
                    var {erro} = response.data;
                    setOpen3({
                        show: true,
                        text: erro,
                        type: 'warning'
                    });
                }
                handleClose();
            });

        }
    }
        
    return (
    <Container2>
        <Header>
            Abastecimento
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Relatorio"  {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <Form>
            <Grid container spacing={3}>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                    <Select
                    native
                    value={state.empresa}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'empresa',
                        id: 'abast_empresa',
                    }}
                    >
                        {empresas.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                        
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                    <Select
                    native
                    value={state.contrato}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'contrato',
                        id: 'abast_contrato',
                    }}
                    >
                        {contratos.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                    <Select
                    native
                    value={state.filial}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'filial',
                        id: 'abast_filial',
                    }}
                    >
                        {filiais.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                   <TextField
                        type="date"
                        className={classes.textField}
                        locale='pt-PT' 
                        id="dateini" 
                        value={dateini}
                        onChange={handleChange5}
                        inputProps={{
                            name: 'dataini',
                            id: 'abast_dataini',
                        }}
                        placeholder='dd/mm/aaaa'
                    />
                    
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                   <TextField
                        type="date"
                        className={classes.textField}
                        locale='pt-PT' 
                        id="datefim" 
                        value={datefin}
                        onChange={handleChange6}
                        inputProps={{
                            name: 'datafim',
                            id: 'abast_datafim',
                        }}
                        placeholder='dd/mm/aaaa'
                    />
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title="Consultar">
                        <IconButton aria-label="Consultar" onClick={() => consultar_abastecimento()} color="primary" variant="contained">
                            <SearchIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Incluir abastecimento">
                                <IconButton  aria-label="Incluir abastecimento" onClick={() => setModalShow(true)} variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                <Grid item xs="auto">
                    <CSVLink data={exportCsv} filename={"abastecimento.csv"}>     
                        <Tooltip title="Exportar csv">
                            <IconButton aria-label="Exportar csv" variant="contained">
                                <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                            </IconButton>
                        </Tooltip>
                    </CSVLink>
                </Grid> 
                <Grid item xs="auto">
                    <Tooltip title="Exportar ERP">
                        <IconButton aria-label="Exportar ERP" onClick={() => export_erp()} variant="contained" color="secondary">
                            <FontAwesomeIcon icon={faFileExport} size="lg"/>
                        </IconButton>
                    </Tooltip>
                </Grid>  
            </Grid>
        </Form>
        <Box component="span" m={1}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    {lista && (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                            }
                                            return false;
                                        })}
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name === "Tipo"){
                                                if(dados[name] === "E"){
                                                    return (<Table.Col 
                                                            className={classes.linha} 
                                                            key={dados.id + column.text}>
                                                                <Tooltip title="Liberação">
                                                                    <IconButton  
                                                                        size="small" 
                                                                        onClick={() => setLiberacao({
                                                                            show: true,
                                                                            id: dados.id
                                                                        })}
                                                                        aria-label="Edit" 
                                                                        variant="contained">
                                                                        <AssignmentIcon  fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Table.Col>);
                                                }else{
                                                    return (<Table.Col 
                                                        className={classes.linha} 
                                                        key={dados.id + column.text}>
                                                        </Table.Col>);
                                                }
                                            }
                                            return false;
                                        })}

                                        {type === "a" && (
                                            <Table.Col>
                                                <Tooltip title="Alterar">
                                                    <IconButton  size="small" key={dados.id} onClick={() => edit_abast(dados.id)} aria-label="Edit" variant="contained">
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Table.Col>
                                        )}
                                        {type === "a" && (
                                            <Table.Col>
                                                <Tooltip title="Excluir">
                                                    <IconButton  size="small" key={dados.id} onClick={() => handleClickOpen2(dados.id)} aria-label="delete" variant="contained" color="secondary">
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Table.Col>
                                        )}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </>
            )}
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Essa operação não pode ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2} color="default">
                        Cancelar
                    </Button>
                    <Button onClick={() => del_abast()} variant="contained" color="secondary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
             </Dialog>
            </Box>
            {modalShow ? (
                <ModalAdd
                    open={modalShow}
                    onClose={() => setModalShow(false)}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    liberacao={setLiberacao}
                />
            ):null}
            {modalShow2.show ? (
                <ModalEdit
                    open={modalShow2.show}
                    onClose={() => setModalShow2({
                        show: false,
                        id: 0
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consulta={consultar_abastecimento}
                    id={modalShow2.id}
                />
            ):null}
        </TabPanel>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        {liberacao.show ? (
            <Liberacao
            open={liberacao.show}
            onClose={() => setLiberacao({
                show: false,
                id: 0
            })}
            maxWidth="lg"
            fullWidth={true}
            id={liberacao.id}
        />
        ):null}
    
    </Container2>
    );
}

export default BoardGas;