import React from 'react';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

function DialogTitle(props) {
    const classes = useStyles();
    const { children, onClose, open, open4, ...other } = props;
    return (
      <>
        <Backdrop className={classes.backdrop} open={open4} >
        </Backdrop>
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
              open ? (null): (
                  <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                      <CloseIcon />
                  </IconButton>
              )
          ) : null}
        </MuiDialogTitle>
      </>
    );
}

export default DialogTitle;