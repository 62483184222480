import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import {MdSend} from 'react-icons/md'

import Coment from '../Coment';

import { Logo2 } from './styles';
import logo_icon from '../../../../img/icon.png';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { makeStyles } from '@material-ui/core/styles';
import api from '../../../../services/api'


import DialogTitle from './DialogTitle'

import {getMat} from "../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    
    body: {
        minHeight: 300,
        marginTop: 30
    },
    footer: {
        backgroundColor: '#FFF',
        height: 50,
    },
   
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        backgroundColor: '#FFF',
        border: '1px solid #d3d3d3',
        borderRadius: 15,
    },
    root: {
        flexGrow: 1,
        overflowY: "auto",
        background: "#F3F3F3",
    },
  }));

function ModalChat(props) {
    var {onClose,item, nome, id_coment, status, mes, ...rest} = props;
    const [open4, setOpen4] = useState(false);
    const classes2 = useStyles();
    const [open5, setOpen5] = useState(false);
    const [input, setInput] = useState('');
    const [lists, setLists] = useState([]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const descriptionElementRef = React.useRef(null);
    useEffect (() => {
        setOpen4(true);
        if(status === "Em andamento"){
            setOpen5(true);
        }else{
            setOpen5(false);
        }
        async function loadcoment(){
            api.get('/precontra/msg/' + id_coment,
                { 
                    headers: { 
                            idfunc: getMat(),
                        } 
                    }
                )
            .then(function (response) {
                setLists(response.data);
                setOpen4(false);
                var objDiv = document.getElementById("pa_coment_chat");
                objDiv.scrollTop = objDiv.scrollHeight;
            })
        }
        loadcoment(id_coment)
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        
    }, [id_coment, status]);


    function _handleKeyDown (e) {
        if (e.key === 'Enter') {
            enviarComent()
        }
    }

    async function loadcoment(){
        api.get('/precontra/msg/' + id_coment,
            { 
                headers: { 
                        idfunc: getMat(),
                    } 
                }
            )
        .then(function (response) {
            setLists(response.data);
            setOpen4(false);
            var objDiv = document.getElementById("pa_coment_chat");
            objDiv.scrollTop = objDiv.scrollHeight;
        })
    }
    


    async function enviarComent(){
        if(input !== ""){
            var response = await api.post('/precontra/msg',
            { 
                idfunc: getMat(),
                id: id_coment,
                comentario: input,
            });
            if(response.data.status === 1){
                setInput('');
                await loadcoment(id_coment);
               
            }else{
                setOpen3({
                    show: true,
                    text: 'Não foi possivel enviar sua mensagem!',
                    type: 'error'
                });
            }
        }
    }
    
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        scroll="paper"
        >
        <DialogTitle 
            onClose={onClose} 
            open4={false} 
            open={false} 
            descr={item} 
            open5={open5}
            id_coment={id_coment}>
                {nome} - {mes}
        </DialogTitle>
        <DialogContent id="pa_coment_chat" className={classes2.root} dividers ref={descriptionElementRef}>
           
            {open4 ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ):(
                    <>
                        <div className={classes2.body}>
                            {lists ? (
                                    lists.map((list, index) => <Coment key={list.id} index={index} data={list}  /> )
                                ) : (
                                    null
                                )
                            }
                        </div>
                        
                    </>
                )}
        </DialogContent>
        {open4 ? (
            null
        ):(
            <DialogActions className={classes2.footer}>
               
                <InputBase autoFocus value={input} onInput={e => setInput(e.target.value)}
                placeholder="Digite..."
                classes={{
                    root: classes2.inputRoot,
                    input: classes2.inputInput,
                }}
                inputProps={{ 'aria-label': 'comentario' }}
                onKeyDown={_handleKeyDown}
                />
            
                <IconButton variant="contained" color="primary"  aria-label="Mensagem" size="small" onClick={() => enviarComent()}>
                    <MdSend />
                </IconButton>
               
            </DialogActions>
            )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        </Dialog>
  );
}

export default ModalChat;