import { InputLabel, makeStyles, Select, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Alert, Form } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Logo2 } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo_icon from "../../../../../../img/icon.png";
import { Table } from "tabler-react";
import api from '../../../../../../services/api';
import { getUser } from '../../../../../../services/auth';

const useStyles = makeStyles((theme) => ({
    linha: {
      fontSize: 12,
      padding: "5px !important",
    },
    linha2: {
      fontSize: 12,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      fontSize: 12,
      minHeight: "0px !important",
      padding: 0,
    },
    botao: {
      padding: 0,
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      position: "absolute",
      width: "100%",
      height: "100%",
      marginRight: "auto",
      marginBottom: 0,
      marginLeft: "auto",
      userSelect: "none",
      top: 0,
      marginTop: 0,
      paddingTop: "40%",
      fontSize: 22,
    },
    indicator2: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      position: "absolute",
      width: "100%",
      height: "100%",
      marginRight: "auto",
      marginBottom: 0,
      marginLeft: "auto",
      userSelect: "none",
      top: 0,
      marginTop: "-20px",
      fontSize: 16,
    },
    progresso: {
      marginTop: "-50px",
    },
  }));

function Quiz({empresas, contratos, filiais, tipos, anos}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        tipo: 0,
        ano: anos[0].descr,
        mes: 0,
    });
    const [open3, setOpen3] = useState({
        show: false,
        text: "",
        type: "warning",
    });
    const handleClose3 = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpen3({
          show: false,
          text: "",
          type: "warning",
        });
      };
    function consultar() {
        for (var dados in state) {
          if (state[dados] === "") {
            setOpen3({
              show: true,
              text: "Preencha todos os campos para consultar!",
              type: "warning",
            });
            return false;
          }
        }
        setOpen(true);
        let { empresa, contrato, filial, tipo, ano, mes } = state;
        api
          .get('/treinamento/acompquiz', {
            params: {
              empresa,
              contrato,
              filial,
              tipo,
              mes,
              ano,
              user: getUser(),
            },
          })
          .then(function (response) {
            let json = null;
            var { dados } = response.data;
            var data = dados;
            json = data;
    
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if (json !== null && json !== "" && json !== []) {
              let where2 = [];
              var dados = json[0];
              for (var colunas in dados) {
                let o2 = {
                  dataField: colunas,
                  text: colunas,
                };
                where2.push(o2);
              }
              setColumns(where2);
              setProducts(json);
              let export_temp = null;
                export_temp = data.map((column) => {
                    delete column.id;
                    return column;
                });
              setExportCsv(export_temp);
              setLista(true);
            } else {
              setLista(false);
              setProducts(null);
            }
            setOpen(false);
          });
    }
    return (
        <>
            <Form>
                <Grid container spacing={3}>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_empresa">
                        Empresa
                    </InputLabel>
                    <Select
                    native
                    value={state.empresa}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "empresa",
                        id: "abast_empresa",
                    }}
                    >
                    {empresas.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>
                        {dados.descr}
                        </option>
                    ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_contrato">
                    Contrato
                    </InputLabel>
                    <Select
                    native
                    value={state.contrato}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "contrato",
                        id: "abast_contrato",
                    }}
                    >
                    {contratos.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>
                        {dados.descr}
                        </option>
                    ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_filial">
                    Filial
                    </InputLabel>
                    <Select
                    native
                    value={state.filial}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "filial",
                        id: "abast_filial",
                    }}
                    >
                    {filiais.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>
                        {dados.descr}
                        </option>
                    ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="tipo_treina">
                    Negócio
                    </InputLabel>
                    <Select
                    native
                    value={state.tipo}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "tipo",
                        id: "tipo_treina",
                    }}
                    >
                    <option value={0}>Todos</option>
                    {tipos.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>
                        {dados.descr}
                        </option>
                    ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="mes_treina">
                    Mês
                    </InputLabel>
                    <Select
                    native
                    value={state.mes}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "mes",
                        id: "mes_treina",
                    }}
                    >
                    <option value={0}>Todos</option>
                    <option value={1}>Janeiro</option>
                    <option value={2}>Feveiro</option>
                    <option value={3}>Março</option>
                    <option value={4}>Abril</option>
                    <option value={5}>Maio</option>
                    <option value={6}>Junho</option>
                    <option value={7}>Julho</option>
                    <option value={8}>Agosto</option>
                    <option value={9}>Setembro</option>
                    <option value={10}>Outubro</option>
                    <option value={11}>Novembro</option>
                    <option value={12}>Dezembro</option>
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="ano_treina">
                    Ano
                    </InputLabel>
                    <Select
                    native
                    value={state.ano}
                    onChange={(event) => {
                        const name = event.target.name;
                        setState({
                        ...state,
                        [name]: event.target.value,
                        });
                    }}
                    className={classes.textField}
                    inputProps={{
                        name: "ano",
                        id: "ano_treina",
                    }}
                    >
                    {anos.map((dados, index) => (
                        <option key={dados.id} value={dados.descr}>
                        {dados.descr}
                        </option>
                    ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title="Consultar">
                    <IconButton
                        aria-label="Consultar"
                        onClick={() => consultar()}
                        color="primary"
                        variant="contained"
                    >
                        <SearchIcon fontSize="large" />
                    </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs="auto">
                    <CSVLink data={exportCsv} filename={"treinamentos.csv"}>
                    <Tooltip title="Exportar csv">
                        <IconButton aria-label="Exportar csv" variant="contained">
                        <FontAwesomeIcon icon={faFileCsv} size="lg" />
                        </IconButton>
                    </Tooltip>
                    </CSVLink>
                </Grid>
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
                ) : (
                <>
                    {lista && (
                    <>
                        <Table>
                        <Table.Header>
                            <Table.Row>
                            {columns.map((dados, index) => {
                                if (dados.text !== "id") {
                                    return (
                                        <Table.ColHeader key={dados.text}>
                                        {dados.text}
                                        </Table.ColHeader>
                                    );
                                }
                                return false;
                            })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {products.map((dados, index) => (
                            <Table.Row key={dados.id}>
                                {columns.map((column, index) => (
                                    column.text !== "id" && (
                                        <Table.Col
                                            className={classes.linha}
                                            key={dados.id + column.text}
                                            >
                                            {dados[column.text]}
                                        </Table.Col>
                                    )
                                ))}
                                
                            </Table.Row>
                            ))}
                        </Table.Body>
                        </Table>
                    </>
                    )}
                </>
                )}
            </Box>
            <Snackbar
                open={open3.show}
                autoHideDuration={6000}
                onClose={handleClose3}
            >
                <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Quiz;