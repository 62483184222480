import React, {useState, useEffect} from 'react';
import { Container, Logo2, Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import api from '../../services/api'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getUser, getusertype} from "../../services/auth";
import { CSVLink } from "react-csv";
import {Lightbox} from "react-modal-image";

import ModalEdit from './ModalEdit';
import ModalEdit2 from './ModalEdit2';
import ModalEdit3 from './ModalEdit3';
import ModalEdit4 from './ModalEdit4';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


import logo_icon from '../../img/icon.png';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Form, Dropdown, DropdownButton} from 'react-bootstrap';

/*import {
    DragDropContext,
    Droppable,
    Draggable,
  } from "react-beautiful-dnd";*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFileImage, faTools, faImage } from '@fortawesome/free-solid-svg-icons'

import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react";
import Styles from '../Styles';
import { TextField } from '@material-ui/core';
import Tab2 from './Components/Tab2';
import ModalEditDocument from './ModalEditDocument';
import ModalAddDocument from './ModalAddDocument';
import ModalAddDocumentTipo from './ModalAddDocumentTipo';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BoardCheck({empresas, contratos, filiais, opes}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState([]);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        ope: opes[0].id,
    });
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [operacoes, setOperacoes] = useState(opes);
    const [url, setUrl] = useState(null);
    const [image, setImage] = useState({
        open: false,
        alt: "",
    });
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const [modalShow2, setModalShow2] = useState({
        show: false,
        id: 0,
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const handleChange3 = (event) => {
        const name = event.target.name;
        setState3({
          ...state3,
          [name]: event.target.value,
        });
    };

    const handleChange5 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange6 = (event) => {
        const value = event.target.value;
        setDatefin(value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    function consultar_anomalias(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial, ope} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/checkrelatorio',
            { 
                headers: { 
                        empresa,
                        dataini, 
                        datafim,
                        contrato,
                        ope,
                        filial,
                        user: getUser(),
                        tipo: 18
                    } 
                }
            )
        .then(function (response) {
            var json = response.data;
            var data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== 'null' && json !== ''){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    delete column['Img Saida'];
                    delete column['Img Retorno'];
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function mostrar_img(nome, alt){
        setUrl("backend/public/images/" + nome);
        setImage({
            open: true,
            alt,
        })
    }
    function closeLightbox(){
        setImage({
            open: false,
            alt: "",
        })
        setUrl(null);
    }
    function alt_os(id){
        setModalShow2({
            show: true,
            id
        })
    }

    const [columns2, setColumns2] = useState(null);
    const [products2, setProducts2] = useState([]);
    const [exportCsv2, setExportCsv2] = useState([]);
    const [lista2, setLista2] = useState(false);
    const [state3, setState3] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        ope: opes[0].id,
        tipo: 7,
    });
    const [dateini2, setDateini2] = useState("");
    const [datefin2, setDatefin2] = useState("");
    const handleChange7 = (event) => {
        const value = event.target.value;
        setDateini2(value);
        
    };
    const handleChange8 = (event) => {
        const value = event.target.value;
        setDatefin2(value);
    };
    var type = getusertype();
    const [edit, setEdit] = useState(false);
    const [id_temp, setId_temp] = useState(0);
    function consultar_relatorio(){
        for(var dados in state3) {
            if(state3[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini2 === "" || datefin2 === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial, tipo, ope} = state3;
        var dataini = dateini2;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin2;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/checkrelatorio',
            { 
                headers: { 
                        empresa,
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        ope,
                        user: getUser(),
                        tipo
                    } 
                }
            )
        .then(function (response) {
            var json = response.data;
            var data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== 'null' && json !== ''){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns2(where2);
                setProducts2(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    return column;
                })
                setExportCsv2(export_temp);
                setLista2(true);
                
            }else{
                setLista2(false);
                setProducts2(null);
            }
            handleClose();
            if(state3.tipo === "11"){
                setEdit(true);
            }else{
                setEdit(false);
            }
        });
    }
    const [open4, setOpen4] = useState(false);
    const handleClickOpen4 = (id) => {
        setOpen4(true);
        setId_temp(id);
    };
    const handleClose4 = () => {
        setOpen4(false);
        setId_temp(0);
    };
    function del_check(){
        if(id_temp !== 0){
            api.delete('/checkrelatorio/' + id_temp,
            {
                headers: {  
                    user: getUser(),
                    tipo: 3,
                } 
            })
            .then(function(response){
                console.log(response.data)
                let {id} = response.data;
                if(id > 0){
                    setOpen3({
                        show: true,
                        text: 'Excluido com sucesso!',
                        type: 'success'
                    });
                    consultar_relatorio();
                }
                
            });
            setOpen4(false);
        }
    }
    const [modalShow3, setModalShow3] = useState({
        show: false,
        id: 0,
    });
    function edit_check(id){
        setModalShow3({
            show: true,
            id
        })
    }
    const [opeDescr, setOpeDescr] = useState(null);
   
    const handleChange4 = (event) => {
        const name = event.target.name;
        setState4({
            ...state4,
            [name]: event.target.value,
          });

        var empresa = document.getElementById('itens_check_empresa').value;
        var contrato = document.getElementById('itens_check_contrato').value;
        var filial = document.getElementById('itens_check_filial').value;
        
        if(name === "ope"){
            var sel = event.target;
            setOpeDescr(sel.options[sel.selectedIndex].text);
        }
        if(name === "empresa"){
            filter2(setOperacoes, event.target.value, contrato, filial);
        }
        if(name === "contrato"){
            filter2(setOperacoes, empresa, event.target.value, filial);
        }
        if(name === "filial"){
            filter2(setOperacoes, empresa, contrato, event.target.value);
        }
        setLista3(false);
    };
    function filter2(local, val, val2, val3){
        let data = opes;
        // eslint-disable-next-line eqeqeq
        let data_filter = data.filter( element => element['empresa'] == val)
         // eslint-disable-next-line eqeqeq
        data_filter = data_filter.filter( element => element['contrato'] == val2)
         // eslint-disable-next-line eqeqeq
        data_filter = data_filter.filter( element => element['filial'] == val3)
        local(data_filter);
    }

    useEffect (() => {
        filter2(setOperacoes, empresas[0].id, contratos[0].id, filiais[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contratos, empresas, filiais]);
        
    const [columns3, setColumns3] = useState(null);
    const [products3, setProducts3] = useState([]);
    const [lista3, setLista3] = useState(false);
    const [exportCsv3, setExportCsv3] = useState([]);

    function consultar_itens_check(){
        var empresa = document.getElementById('itens_check_empresa').value;
        var contrato = document.getElementById('itens_check_contrato').value;
        var filial = document.getElementById('itens_check_filial').value;
        var ope = document.getElementById('itens_check_ope').value;
        if(empresa === "" || contrato === "" || filial === "" || ope === ""){
            setOpen3({
                show: true,
                text: 'Selecione todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        api.get('/itenschecklist/all',
            { 
                headers: { 
                        empresa,
                        contrato,
                        filial,
                        user: getUser(),
                        ope,
                    } 
                }
            )
        .then(function (response) {
            var json = response.data.linhas;
            var data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== '' && json !== []){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns3(where2);
                setProducts3(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    delete column.base;
                    return column;
                })
                setExportCsv3(export_temp);
                setLista3(true);
                
            }else{
                setLista3(false);
                setProducts3(null);
            }
            handleClose();
        });
    }
    const [modalShow4, setModalShow4] = useState({
        show: false,
        id: 0,
    });
    function edit_itens_check(id){
        setModalShow4({
            show: true,
            id
        })
    }
    const [open5, setOpen5] = useState(false);
    const [state4, setState4] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        ope: opes[0].id,
    });
    function abre_modal_incluir(){
        var empresa = document.getElementById('itens_check_empresa').value;
        var contrato = document.getElementById('itens_check_contrato').value;
        var filial = document.getElementById('itens_check_filial').value;
        var ope = document.getElementById('itens_check_ope').value;
        if(empresa === "" || contrato === "" || filial === "" || ope === ""){
            setOpen3({
                show: true,
                text: 'Selecione a empresa, contrato, filial e a operação pra poder cadastrar um novo item!',
                type: 'warning'
            });
            return false
        }
        var sel = document.getElementById('itens_check_ope');
        setOpeDescr(sel.options[sel.selectedIndex].text);
        setState4({
            empresa,
            contrato,
            filial,
            ope,
        })
        setOpen5(true);
    }

    function handleClose5(id){
        setOpen5(false);
        setModalShow4({
            show: true,
            id
        })
    };

    const [state5, setState5] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        ope: opes[0].id,
    });
    const handleChange10 = (event) => {
        const name = event.target.name;
        setState5({
          ...state,
          [name]: event.target.value,
        });
    };
    const [columns4, setColumns4] = useState(null);
    const [products4, setProducts4] = useState([]);
    const [lista4, setLista4] = useState(false);
    const [exportCsv4, setExportCsv4] = useState([]);
    const consultar_documentos_check = () => {
        var empresa = document.getElementById('documento_check_empresa').value;
        var contrato = document.getElementById('documento_check_contrato').value;
        var filial = document.getElementById('documento_check_filial').value;
        var ope = document.getElementById('documento_check_ope').value;
        if(empresa === "" || contrato === "" || filial === "" || ope === ""){
            setOpen3({
                show: true,
                text: 'Selecione todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        api.get('/checklist/documento',
            { 
                headers: { 
                        empresa,
                        contrato,
                        filial,
                        user: getUser(),
                        ope,
                    } 
                }
            )
        .then(function (response) {
            let {status, data} = response.data;
            if(status === true){
                let where2 = [];
                let dados = data[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns4(where2);
                setProducts4(data);
                setExportCsv4(data);
                setLista4(true);
            }else{
                setLista4(false);
                setProducts4(null);
            }
            handleClose();
        });
    }
    const [openEditDocument, setOpenEditDocument] = useState({show: false, id: 0});
    const edit_documento_check = (id) => {
        console.log(id)
        setOpenEditDocument({
            show:true,
            id: id
        })
    }
    const [openModalDelete, setOpenModalDelete] = useState({
        show: false,
        id: 0
    });
    const handleClickOpenModalDelete = (id) => {
        setOpenModalDelete({
            show: true,
            id: id
        });
    };
    const handleCloseModalDelete = () => {
        setOpenModalDelete({
            show: false,
            id: 0
        });
    };
    const delete_documento_check = (id) => {
        handleCloseModalDelete();
        handleToggle();
        api.delete('/checklist/documento/' + id,
            { 
                headers: { 
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let {status, message} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: message,
                    type: 'success'
                });
                consultar_documentos_check();
                
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: 'error'
                });
            }
        });
    }
    const [openAddDocumentTipo, setOpenAddDocumentTipo] = useState(false)
    const abre_modal_tipo_documento = () => {
        setOpenAddDocumentTipo(true);
    }
    const [ openAddDocument, setOpenAddDocument] = useState(false);
    const abre_modal_documento = () => {
        setOpenAddDocument(true)
    }
    const openLink = (link) => {
        let url =  "https://www.cilog.com.br/backend/public/documentos/" + link;
        window.open(url, '_blank', 'noreferrer');
    }
    return (
    <Container>
        <Header>
            Check List
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Anomalias"  {...a11yProps(0)} />
            <Tab label="Relatorios"  {...a11yProps(1)} />
            <Tab label="NPS"  {...a11yProps(2)} />
            <Tab label="Cadastros de Itens"  {...a11yProps(3)} />
            <Tab label="Documentos"  {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                     <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_ope">Operação</InputLabel>
                        <Select
                        native
                        value={state.ope}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'itens_check_ope',
                        }}
                        >
                            {operacoes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini}
                            onChange={handleChange5}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                        
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin}
                            onChange={handleChange6}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_anomalias()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"anomalias.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader className={classes.linha} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                if(name === "Img Retorno" || name === "Img Saida"){
                                                    if(dados[name] !== null){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                            <Tooltip title={name}>
                                                                <IconButton  size="small" onClick={() => mostrar_img(dados[name], name)} aria-label={name} variant="contained">
                                                                    <FontAwesomeIcon icon={faFileImage} size="lg"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Table.Col>);
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}></Table.Col>);
                                                    }
                                                }else{
                                                    if(name === "Status"){
                                                        if(dados[name] === "No Prazo"){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                        <Badge color="success" className="mr-1">
                                                                            {dados[name]}
                                                                        </Badge>
                                                                    </Table.Col>);
                                                        }else{
                                                            if(dados[name] === "Pendente"){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                            <Badge color="warning" className="mr-1">
                                                                                {dados[name]}
                                                                            </Badge>
                                                                        </Table.Col>);
                                                            }else{
                                                                if(dados[name] === "Atrasado"){
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                                <Badge color="danger" className="mr-1">
                                                                                    {dados[name]}
                                                                                </Badge>
                                                                            </Table.Col>);
                                                                }else{
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}></Table.Col>);
                                                                }
                                                            }
                                                        }
                                                    }else{
                                                        if(name === "OS"){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <Tooltip title="Altera OS" >
                                                                    <Button 
                                                                        className={classes.button}
                                                                        size="small" 
                                                                        color="default"
                                                                         onClick={() => alt_os(dados.id)}
                                                                         startIcon={<FontAwesomeIcon icon={faTools} size="sm"/>}>
                                                                        {dados[name]}
                                                                    </Button>
                                                                </Tooltip>
                                                            </Table.Col>);
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                        }
                                                    }
                                                }
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
                {image.open && (
                    <Lightbox
                    small={url}
                    medium={url}
                    alt={image.alt}
                    onClose={closeLightbox}
                    hideDownload={true}
                    hideZoom={true}
                    />
                )}
                {modalShow2.show && (
                    <ModalEdit
                        open={modalShow2.show}
                        onClose={() => setModalShow2({
                            show: false,
                            id: 0
                        })}
                        maxWidth="sm"
                        fullWidth={true}
                        consulta={consultar_anomalias}
                        id={modalShow2.id}
                    />
                )}
            </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="relatorio_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state3.empresa}
                        onChange={handleChange3}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'relatorio_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="relatorio_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state3.contrato}
                        onChange={handleChange3}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'relatorio_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="relatorio_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state3.filial}
                        onChange={handleChange3}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'relatorio_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                     <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_ope">Operação</InputLabel>
                        <Select
                        native
                        value={state3.ope}
                        onChange={handleChange3}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'itens_check_ope',
                        }}
                        >
                            {operacoes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="relatorio_dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="relatorio_dateini" 
                            value={dateini2}
                            onChange={handleChange7}
                            inputProps={{
                                name: 'dataini',
                                id: 'relatorio_dateini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                        
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="relatorio_datafim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="relatorio_datafim" 
                            value={datefin2}
                            onChange={handleChange8}
                            inputProps={{
                                name: 'datafim',
                                id: 'relatorio_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="tipo_filial">Tipo</InputLabel>
                        <Select
                        native
                        value={state3.tipo}
                        onChange={handleChange3}
                        className={classes.textField}
                        inputProps={{
                            name: 'tipo',
                            id: 'tipo_filial',
                        }}
                        >
                            <option value={7}>Quantidade</option>
                            <option value={11}>Geral</option>
                            <option value={19}>Combustível</option>
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_relatorio()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv2} filename={"check_relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista2 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {type === "a" && (
                                            edit && (
                                                <Table.ColHeader className={classes.cabecario}></Table.ColHeader>
                                            )
                                        )}
                                        {columns2.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader className={classes.cabecario} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products2.map((dados, index) => (
                                        <Table.Row key={dados.Data + index}>
                                            {type === "a" && (
                                               edit && (
                                                    <Table.Col>
                                                        <DropdownButton size="sm" variant="warning"
                                                        title={
                                                            <EditIcon fontSize="small" />
                                                        }
                                                        >
                                                            <Dropdown.Item onClick={() => edit_check(dados.id)} 
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                                >
                                                                <EditIcon fontSize="small" style={{marginRight: 20}} />
                                                                Alterar
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleClickOpen4(dados.id)} 
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                            >
                                                                <DeleteIcon fontSize="small" style={{marginRight: 20}}/>
                                                                Excluir
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </Table.Col>
                                                )
                                            )}
                                            {columns2.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);      
                                                }else{
                                                    return false;
                                                }
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            <Dialog
                open={open4}
                onClose={handleClose4}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Essa operação não pode ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose4} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => del_check()} variant="contained" color="secondary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
             </Dialog>
             {modalShow3.show ? (
                <ModalEdit2
                    open={modalShow3.show}
                    onClose={() => setModalShow3({
                        show: false,
                        id: 0
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consulta={consultar_relatorio}
                    id={modalShow3.id}
                />
            ):null}
            </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <Tab2 empresas={empresas} contratos={contratos} filiais={filiais} opes={opes} />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state4.empresa}
                        onChange={handleChange4}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'itens_check_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state4.contrato}
                        onChange={handleChange4}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'itens_check_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state4.filial}
                        onChange={handleChange4}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'itens_check_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="itens_check_ope">Operação</InputLabel>
                        <Select
                        native
                        value={state4.ope}
                        onChange={handleChange4}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'itens_check_ope',
                        }}
                        >
                            {operacoes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_itens_check()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Cadastrar novo item">
                                <IconButton
                                    aria-label="Cadastrar novo item" 
                                    onClick={() => abre_modal_incluir()} 
                                    variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>  
                    )}
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv3} filename={"itens_check.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista3 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns3.map((dados, index) => {
                                            if(dados.text !== "id" && dados.text !== "base"){
                                                return (<Table.ColHeader className={classes.cabecario} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {products3.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns3.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id" && name !== "base"){
                                                if(name === "foto"){
                                                    if(dados[name] === true){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                            <FontAwesomeIcon icon={faImage} size="lg"/>
                                                        </Table.Col>);      
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                        </Table.Col>);   
                                                    }
                                                }else{
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);      
                                                }
                                            }else{
                                                return false;
                                            }
                                        })}
                                        <Table.Col>
                                            {type === "a" && (
                                                <Tooltip title="Alterar">
                                                    <IconButton  size="small" key={dados.id} onClick={() => edit_itens_check("item_" + dados.base)} aria-label="Edit" variant="contained">
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                 </Tooltip>
                                            )}
                                        </Table.Col>
                                    </Table.Row>
                                ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            {modalShow4.show ? (
                <ModalEdit3
                    open={modalShow4.show}
                    onClose={() => setModalShow4({
                        show: false,
                        id: 0
                    })}
                    maxWidth="md"
                    fullWidth={true}
                    empresa={state4.empresa}
                    contrato={state4.contrato}
                    filial={state4.filial}
                    ope={state4.ope}
                    consulta={consultar_itens_check}
                    id={modalShow4.id}
                />
            ):null}
            {open5 ? (
                <ModalEdit4
                    open={open5}
                    onClose={() => setOpen5(false)}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state4.empresa}
                    contrato={state4.contrato}
                    filial={state4.filial}
                    ope={state4.ope}
                    carregar={handleClose5}
                    opeDescr={opeDescr}
                />
            ):null}
        </TabPanel>
        <TabPanel value={value} index={4}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="documento_check_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state5.empresa}
                        onChange={handleChange10}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'documento_check_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="documento_check_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state5.contrato}
                        onChange={handleChange10}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'documento_check_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="documento_check_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state5.filial}
                        onChange={handleChange10}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'documento_check_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="documento_check_ope">Operação</InputLabel>
                        <Select
                        native
                        value={state5.ope}
                        onChange={handleChange10}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'documento_check_ope',
                        }}
                        >
                            {operacoes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_documentos_check()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {type === "a" && (
                        <>
                            <Grid item xs="auto">
                                <Tooltip title="Cadastrar Novo Documento">
                                    <IconButton
                                        aria-label="Cadastrar Novo Documento" 
                                        onClick={() => abre_modal_documento()} 
                                        variant="contained" color="primary">
                                        <AddCircleIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>  
                            <Grid item xs="auto">
                                <Tooltip title="Cadastrar Novo Tipo de Documento">
                                    <IconButton
                                        aria-label="Cadastrar Novo Tipo de Documento" 
                                        onClick={() => abre_modal_tipo_documento()} 
                                        variant="contained" color="primary">
                                        <NoteAddIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>  
                        </>
                    )}
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv4} filename={"documentos_check.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista4 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns4.map((dados, index) => {
                                            if(dados.text !== "id" && dados.text !== "Anexo"){
                                                return (<Table.ColHeader className={classes.cabecario} key={dados.id + dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {products4.map((dados, index) => (
                                    <Table.Row key={dados.id + 'coluna' + index}>
                                        {columns4.map((column, index2) => {
                                            let name = column.text;
                                            if(name !== "id"){
                                                if(name !== "Anexo"){
                                                    let anexos = dados.Anexo;
                                                    return (
                                                        <Table.Col className={classes.linha} key={dados.id + 'celula' + index2}>
                                                            <div  style={{display: 'flex', alignItems: 'center'}}>
                                                                {dados[name]}
                                                                {anexos.map((anexo, idx) => {
                                                                    if(anexo.tipo === name){
                                                                        let dadosAnexo = anexo['data'];
                                                                        return (
                                                                            <div key={anexo.id + 'action' + index} style={{display: 'flex', marginLeft: 10}}>
                                                                                {dadosAnexo.map((dados2, index) => (
                                                                                    <Tooltip  key={dados2.id + "_anexos"} title="anexos">
                                                                                        <IconButton
                                                                                        size="small" 
                                                                                        onClick={() => openLink(dados2.nome)} >
                                                                                            {dados2.tipo === 'pdf' ? (
                                                                                                <PictureAsPdfIcon fontSize="small" />
                                                                                            ) : (
                                                                                                <InsertPhotoIcon fontSize="small" />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                ))}
                                                                                {type === "a" && (
                                                                                    <div >
                                                                                        <Tooltip title="Alterar">
                                                                                            <IconButton  size="small"  onClick={() => edit_documento_check(anexo.id)} aria-label="Edit" variant="contained">
                                                                                                <EditIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Excluir">
                                                                                            <IconButton  size="small" color="secondary" onClick={() => handleClickOpenModalDelete(anexo.id)} aria-label="Delete" variant="contained">
                                                                                                <DeleteIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    }else{
                                                                        return false;
                                                                    }
                                                                })}
                                                            </div>
                                                        </Table.Col>); 
                                                }else{
                                                    return false;
                                                }
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            <Dialog
                open={openModalDelete.show}
                onClose={handleCloseModalDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Deseja realmente excluir?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Essa operação não pode ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalDelete} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => delete_documento_check(openModalDelete.id)} variant="contained" color="secondary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
             </Dialog>
             {openEditDocument.show ? (
                <ModalEditDocument
                    open={openEditDocument.show}
                    onClose={() => setOpenEditDocument({show: false, id: 0})}
                    maxWidth="sm"
                    fullWidth={true}
                    consulta={() => consultar_documentos_check()}
                    id={openEditDocument.id}
                />
            ):null}
            {openAddDocument ? (
                <ModalAddDocument
                    open={openAddDocument}
                    onClose={() => setOpenAddDocument(false)}
                    maxWidth="sm"
                    fullWidth={true}
                    consulta={() => consultar_documentos_check()}
                    empresa={state5.empresa}
                    contrato={state5.contrato}
                    filial={state5.filial}
                    ope={state5.ope}
                />
            ):null}
             {openAddDocumentTipo ? (
                <ModalAddDocumentTipo
                    open={openAddDocumentTipo}
                    onClose={() => setOpenAddDocumentTipo(false)}
                    maxWidth="sm"
                    fullWidth={true}
                    consulta={() => consultar_documentos_check()}
                    empresa={state5.empresa}
                    contrato={state5.contrato}
                    filial={state5.filial}
                    ope={state5.ope}
                />
            ):null}
        </TabPanel>
      
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
     </Container>
    );
}

export default BoardCheck;