import styled from 'styled-components';


export const Logo2 = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-Content: center;
    align-Items: center;
    margin-top: 30px;
    margin-bottom: 10px;
    img {
        height: 80px;
        width: 80px;
    }
    
`;

