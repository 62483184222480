import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import { getUser} from "../../../../services/auth";
import CustomizedProgressBars from './components/CustomizedProgressBars'

import DialogTitle from '../../../Treinamentos/components/Body/components/DialogTitle'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    }
}));


function ModalAdd(props) {
    const classes = useStyles();
    var {empresa, contrato, filial, onClose,...rest} = props;
    const [visivel2, setVisivel2] = useState(true)
    const [progress, setProgress] = useState(0);
    const [formTitulo, setTitulo] = useState({
        error: false,
        helperText: '',
    })
    const [formObj, setObj] = useState({
        error: false,
        helperText: '',
    })
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    function salvar(){
        var titulo = document.getElementById('titulo_incluir').value;
        var objetivo = document.getElementById('objetivo_incluir').value;
        setTitulo({
            error: false,
            helperText: ''
        });
        setObj({
            error: false,
            helperText: '',
        });
       
        if(titulo === 0 || titulo === ""){
            setTitulo({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(objetivo === 0 || objetivo === ""){
            setObj({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        setVisivel2(false);
        var config = {
            onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
				//nanobar.go( percentCompleted );
                setProgress(percentCompleted);
            }
		};
        
        api.post('/avisos',
        { 
            user: getUser(),
            empresa,
            contrato,
            filial,
            titulo,
            msg: objetivo,
            
        }, config)
        .then(function(response){
            var {id} = response.data;
            setVisivel2(true)
            if(id > 0){
                setOpen3({
                    show: true,
                    text: 'Aviso enviado com sucesso!',
                    type: "success"
                });
                onClose();
            }else{
                setOpen3({
                    show: true,
                    text: "Ocorreu algum erro no envio!",
                    type: "error"
                });
            }
        })
       
    }
  return (
    <Dialog
    {...rest}
    aria-labelledby="form-dialog-title"
    >
    <DialogTitle onClose={onClose} open4={false} open={false}>Cadastrar Treinamento</DialogTitle>
    <DialogContent className={classes.root}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={3}>
                <Form.Group> 
                    <InputLabel className={classes.linha} htmlFor="titulo_incluir">Titulo</InputLabel>
                   <TextField
                        size="small"
                        error={formTitulo.error}
                        id="titulo_incluir"
                        type="text"
                        className={classes.textField}
                    />
                    <FormHelperText  error={formTitulo.error}>{formTitulo.helperText}</FormHelperText>
                </Form.Group>
            </Grid>
            <Grid item xs={9}>
                <Form.Group> 
                    <InputLabel className={classes.linha} htmlFor="objetivo_incluir">Mensagem</InputLabel>
                   <TextField
                        size="small"
                        id="objetivo_incluir"
                        type="text"
                        className={classes.textField}
                        error={formObj.error}
                    />
                    <FormHelperText  error={formObj.error}>{formObj.helperText}</FormHelperText>
                </Form.Group>
            </Grid>
           
        </Grid>
    </DialogContent>
    {visivel2 ? (
        <DialogActions>
            <Button color="secondary" onClick={props.onClose}>Cancelar</Button>
            <Button color="primary" variant="contained" onClick={() => salvar()}>Salvar</Button>
        </DialogActions>
    ):(
        <CustomizedProgressBars progress={progress} />
    )}
    <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity={open3.type}>
            {open3.text}
        </Alert>
    </Snackbar>
    </Dialog>
);
}

export default ModalAdd;