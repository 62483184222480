import React, {useState, useEffect} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import { Logo2 } from './styles';
import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";
import api from '../../../../services/api'

import { config } from '../../../../services/constants';
import Header from "./components/Header";
import Body from "./components/Body";
import Botton from "./components/Botton";
import DialogTitle from '../../../BoardCheck/Components/DialogTitle';

const styles2 = StyleSheet.create({
    page: {
        display: "flex",
        backgroundColor: '#FFF',
        margin: 20,
        flexWrap: "wrap",
        height: "95%",
        paddingBottom: 40,
    },
    document:{
        width: "100%",
        height: "100%",
    },
    tab: {
        width: "94%",
        flexDirection: "row",
        borderBottom: 0.5,
        borderColor: "#a3a3a3",
        padding: 5,
        justifyContent: "space-around",
    },
    text: {
        fontSize: 10,
        width: "80%",
    },
    text2: {
        fontSize: 10,
        width: "20%",
    },
    lista: {
        marginTop: 10,
        
    },
    div: {
        height: 150,
    },
  });

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Liberacao(props) {
    var {id, onClose, ...rest} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [base64, setbase64] = useState(false);
    const [dados, setDados] = useState({
        titulo: "",
        objetivo: "",
        data: "",
        requisitante: "",
        operacao: "",
        placa: "",
        assinatura: "",
        numero: 0,
        prefixo: "",
        responsavel: "",
        funcao: "",
    });
    const [body, setBody] = useState([]);
    const [columns, setColumns] = useState(null);

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
    }

    useEffect (() => {
        async function carregar(){
            setOpen(true);
            await api.get('/ticket/' + id ,
                { 
                    headers: { 
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                var dados = response.data;
                
                var {empresa, data, requisitante, operacao, 
                    numero, presencas, placa, assinatura, prefixo, responsavel, funcao, fornecedor} = dados;
                setDados({
                    titulo: "Liberação de abastecimento",
                    objetivo: fornecedor,
                    data,
                    requisitante,
                    operacao,
                    numero,
                    placa,
                    assinatura,
                    prefixo,
                    responsavel,
                    funcao,
                })
                var url2 = config.url.API_URL
                var url3 = url2+ "/images/logo/logo_"+empresa+".png";

                var json =  JSON.stringify(presencas);
                json = JSON.parse(json);
                if(json !== null && json !== ''  && json !== []){
                    let where2 = [];
                    var dados2 = json[0];
                    for(var colunas in dados2) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                    setColumns(where2);
                    setBody(presencas);
                }else{
                    setColumns([]);
                    setBody([]);
                }

                getBase64FromUrl(url3).then(function (response) {
                    setbase64(response);
                })
                setOpen(false);
                
            });
            
        }
        if(id !== 0){
            carregar();
        }
      }, [id]);

    return (
        <Dialog
            fullScreen
            {...rest}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            >
            <DialogTitle onClose={onClose} open={open} open4={false}>Liberação de abastecimento externo</DialogTitle>
            <DialogContent className={classes.root}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        <PDFViewer style={styles2.document}>
                            <Document style={styles2.document}>
                                <Page size="A4" style={styles2.page}>
                                    <View>
                                        <Header dados={dados} base64={base64} />
                                        <View style={styles2.lista}>
                                            <View style={styles2.tab}>
                                                <Text style={styles2.text2}>
                                                    Quantidades
                                                </Text>
                                                <Text style={styles2.text}>
                                                    Descrição do material
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles2.lista}>
                                        {body.map((dados, index) => (
                                            <Body key={dados.id} dados={dados} columns={columns} />
                                        ))}
                                    </View>
                                    <View>
                                        <Botton dados={dados}/>
                                    </View>
                                    <View style={styles2.div}></View>
                                    <View>
                                        <Header dados={dados} base64={base64} />
                                        <View style={styles2.lista}>
                                            <View style={styles2.tab}>
                                                <Text style={styles2.text2}>
                                                    Quantidades
                                                </Text>
                                                <Text style={styles2.text}>
                                                    Descrição do material
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles2.lista}>
                                        {body.map((dados, index) => (
                                            <Body key={dados.id} dados={dados} columns={columns} />
                                        ))}
                                    </View>
                                    <View>
                                        <Botton dados={dados}/>
                                    </View>
                                </Page>
                            </Document>
                        </PDFViewer>  
                    </>
                )}
            </DialogContent>
    
        </Dialog>
      );
}

export default Liberacao;