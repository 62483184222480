import React, {useState, useEffect} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../../../../../../../services/api'


import { Logo2 } from './styles';

import logo_icon from '../../../../../../../../img/icon.png';
import DialogTitle from '../../../DialogTitle';

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    root: {
        flexGrow: 1,
        overflowY: "initial",
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
    },
            'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

function ModalViewQuiz(props) {
    const classes = useStyles();
    const open4 = false;
    const [open, setOpen] = useState(true);
    
    const [selectedValue, setSelectedValue] = useState("");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    var {onClose, id, ...rest} = props;
    
    useEffect (() => {
        async function carrega_quiz(){
            api.get('perguntaTreinamaento/' + id,
            { 
            headers: { 
                } 
                
            })
            .then(function(response){
		        var {descr,  opcoes } = response.data;
                setOpen(false);
                var cont = 0;
                // eslint-disable-next-line array-callback-return
                opcoes.map((opcao) => {
                        cont++;
                        var descr2 = opcao.descr;
                        var correta = opcao.correta;
                        // eslint-disable-next-line eqeqeq
                        if (cont == 1) {
                            document.getElementById('resposta1_incluir').value = descr2;
                            // eslint-disable-next-line eqeqeq
                            if (correta == 1) {
                                setSelectedValue("1");
                            }
                        }
                        // eslint-disable-next-line eqeqeq
                        if (cont == 2) {
                            document.getElementById('resposta2_incluir').value = descr2;
                            // eslint-disable-next-line eqeqeq
                            if (correta == 1) {
                                setSelectedValue("2");
                            }
                        }
                        // eslint-disable-next-line eqeqeq
                        if (cont == 3) {
                            document.getElementById('resposta3_incluir').value = descr2;
                            // eslint-disable-next-line eqeqeq
                            if (correta == 1) {
                                setSelectedValue("3");
                            }
                        }
                    });
                document.getElementById('pergunta_incluir').value = descr;
                
	        });
        }
        if(id !== 0){
            carrega_quiz();
        }
    }, [id]);
    
    return (<Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={open4} open={open}>Ver Pergunta</DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="pergunta_incluir">Pergunta</InputLabel>
                               <TextField
                        type="date"
                                    size="small"
                                    id="pergunta_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            Opções
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            Correta
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta1_incluir">Resposta 1</InputLabel>
                               <TextField
                        type="date"
                                    size="small"
                                    id="resposta1_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "1"}
                                onChange={handleChange}
                                value="1"
                                name="radio-button-correta"
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta2_incluir">Resposta 2</InputLabel>
                               <TextField
                        type="date"
                                    size="small"
                                    id="resposta2_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "2"}
                                onChange={handleChange}
                                value="2"
                                name="radio-button-correta"
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="resposta3_incluir">Resposta 3</InputLabel>
                               <TextField
                        type="date"
                                    size="small"
                                    id="resposta3_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid className={classes.center} item xs={1}>
                            <Radio
                                checked={selectedValue === "3"}
                                onChange={handleChange}
                                value="3"
                                name="radio-button-correta"
                                disabled
                            />
                        </Grid>
                    </Grid>    
                </Grid> 
            )}
            </DialogContent>
        </Dialog>
    );
}

export default ModalViewQuiz;