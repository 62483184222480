import React, {useState} from 'react';
import { Container2, Logo2,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../components/Styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getUser, getusertype} from "../../../services/auth";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Form } from 'react-bootstrap';

import logo_icon from '../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';

import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { CSVLink } from "react-csv";
import ModalEditMovi from './ModalEditMovi';
import ModalEditMilimetragem from './ModalEditMilimetragem';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import ModalAddMovi from './ModalAddMovi';
import ModalEditCalibragem from './ModalEditCalibragem';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Body({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [columns2, setColumns2] = useState(null);
    const [products2, setProducts2] = useState(null);
    const [lista2, setLista2] = useState(false);
    const [open2, setOpen2] = useState({
        show: false,
        id: 0,
        loading: false
    });
    const [columns3, setColumns3] = useState(null);
    const [products3, setProducts3] = useState(null);
    const [lista3, setLista3] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [exportCsv2, setExportCsv2] = useState([]);
    const [exportCsv3, setExportCsv3] = useState([]);
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [dateini2, setDateini2] = useState("");
    const [datefin2, setDatefin2] = useState("");
    const [dateini3, setDateini3] = useState("");
    const [datefin3, setDatefin3] = useState("");
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const type = getusertype();
    function consultar_calibragem(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/calibragem/client',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            handleClose();
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                var uniques = [];
                var itemsFound = {};
                for(var i = 0, l = where2.length; i < l; i++) {
                    var stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns(uniques);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    var name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
        }).catch(function (error) {
            console.log(error)
            setLista(false);
            setProducts(null);
        });
    }
    function consultar_milimetragem(){
        for(var dados in state2) {
            if(state2[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini2 === "" || datefin2 === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial} = state2;
        var dataini = dateini2;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin2;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/milimetragem/client',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            handleClose();
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                var uniques = [];
                var itemsFound = {};
                for(var i = 0, l = where2.length; i < l; i++) {
                    var stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns2(uniques);
                setProducts2(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    var name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv2(export_temp);
                setLista2(true);
            }else{
                setLista2(false);
                setProducts2(null);
            }
        }).catch(function (error) {
            console.log(error)
            setLista2(false);
            setProducts2(null);
        });
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange3 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange4 = (event) => {
        const value = event.target.value;
        setDatefin(value);
    };
    const handleChange6 = (event) => {
        const value = event.target.value;
        setDateini2(value);
        
    };
    const handleChange7 = (event) => {
        const value = event.target.value;
        setDatefin2(value);
    };
    const handleChange9 = (event) => {
        const value = event.target.value;
        setDateini3(value);
    };
    const handleChange10 = (event) => {
        const value = event.target.value;
        setDatefin3(value);
    };
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const [state2, setState2] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const [state3, setState3] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const handleChange5 = (event) => {
        const name = event.target.name;
        setState2({
          ...state2,
          [name]: event.target.value,
        });
    };
    const handleChange8 = (event) => {
        const name = event.target.name;
        setState3({
          ...state3,
          [name]: event.target.value,
        });
    };

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    function consultar_movimentacao(){
        for(var dados in state3) {
            if(state3[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini3 === "" || datefin3 === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial} = state3;
        var dataini = dateini3;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin3;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/pneu/movimentacao/all',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            handleClose();
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                var uniques = [];
                var itemsFound = {};
                for(var i = 0, l = where2.length; i < l; i++) {
                    var stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns3(uniques);
                setProducts3(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    var name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv3(export_temp);
                setLista3(true);
            }else{
                setLista3(false);
                setProducts3(null);
            }
        }).catch(function (error) {
            console.log(error)
            setLista3(false);
            setProducts3(null);
        });
    }
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const [modalShow2, setModalShow2] = useState({
        show: false,
        id: 0,
    });
    function edit(id){
        setModalShow2({
            show: true,
            id
        })
    }
    const [modalShow3, setModalShow3] = useState({
        show: false,
        id: 0,
    });
    function editMilimetragem(id){
        setModalShow3({
            show: true,
            id
        })
    }
    function excluirMovimentacao(id){
        setOpen2({show: true, id: id, loading: false})
    }
    function del_Movi(id){
        setOpen2({show: true, id: id, loading: true})
        api.delete('/pneu/movimentacao/' + id,
        { 
            headers: { 
                    user: getUser(),
                } 
            }
        )
        .then(function (response) {
            let { status, message } = response.data
            setOpen2({show: false, id: 0, loading: false})
            if(status === true){
                consultar_movimentacao()
                setOpen3({
                    show: true,
                    text: message,
                    type: 'success'
                });
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: 'warning'
                });
            }
        }).catch(function (error) {
            console.log(error)
            setLista3(false);
            setProducts3(null);
        });
    }
    const [modalAddMoviShow, setModalAddMoviShow] = useState({
        show: false,
    })
    const [modalShow4, setModalShow4] = useState({
        show: false,
        id: 0,
    });
    function editCalibragem(id){
        setModalShow4({
            show: true,
            id
        })
    }
    return (
    <Container2>
        <Header>
            Pneu
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Calibragem"  {...a11yProps(0)} />
            <Tab label="Milimetragem"  {...a11yProps(1)} />
            <Tab label="Movimentação"  {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini}
                            onChange={handleChange3}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin}
                            onChange={handleChange4}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_calibragem()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"calibragem.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                }
                                                return false;
                                            })}
                                            {type === "a" && (
                                                <Table.Col>
                                                    <Tooltip title="Alterar">
                                                        <IconButton  size="small" key={dados.id} onClick={() => editCalibragem(dados.id)} aria-label="Edit" variant="contained">
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Table.Col>
                                            )}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            {modalShow4.show && (
                <ModalEditCalibragem
                    open={modalShow4.show}
                    onClose={() => setModalShow4({
                        show: false,
                        id: 0
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consulta={consultar_calibragem}
                    id={modalShow4.id}
                />
            )}
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state2.empresa}
                        onChange={handleChange5}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state2.contrato}
                        onChange={handleChange5}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state2.filial}
                        onChange={handleChange5}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini2}
                            onChange={handleChange6}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin2}
                            onChange={handleChange7}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_milimetragem()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv2} filename={"milimetragem.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista2 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns2.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products2.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns2.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                }
                                                return false;
                                            })}
                                            {type === "a" && (
                                                <Table.Col>
                                                    <Tooltip title="Alterar">
                                                        <IconButton  size="small" key={dados.id} onClick={() => editMilimetragem(dados.id)} aria-label="Edit" variant="contained">
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Table.Col>
                                            )}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            {modalShow3.show ? (
                <ModalEditMilimetragem
                    open={modalShow3.show}
                    onClose={() => setModalShow3({
                        show: false,
                        id: 0
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    consulta={consultar_milimetragem}
                    id={modalShow3.id}
                />
            ):null}
        </TabPanel>
        <TabPanel value={value} index={2}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state3.empresa}
                        onChange={handleChange8}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state3.contrato}
                        onChange={handleChange8}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state3.filial}
                        onChange={handleChange8}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini3}
                            onChange={handleChange9}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin3}
                            onChange={handleChange10}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_movimentacao()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv3} filename={"movimentacao.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Incluir Movimentação">
                                <IconButton  aria-label="Incluir abastecimento" onClick={() => setModalAddMoviShow({show: true})} variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista3 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns3.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products3.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns3.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                }
                                                return false;
                                            })}
                                            {type === "a" && (
                                                <Table.Col>
                                                    <Tooltip title="Alterar">
                                                        <IconButton  size="small" key={dados.id} onClick={() => edit(dados.id)} aria-label="Edit" variant="contained">
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Table.Col>
                                            )}
                                             {type === "a" && (
                                                <Table.Col>
                                                    <Tooltip title="Excluir">
                                                        <IconButton  size="small" key={dados.id} onClick={() => excluirMovimentacao(dados.id)} aria-label="delete" variant="contained" color="secondary">
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Table.Col>
                                            )}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
                <Dialog
                    open={open2.show}
                    onClose={() => setOpen2({show: false, id: 0})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Deseja realmente excluir?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Essa operação não pode ser desfeita!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disable={open2.loading} onClick={() => setOpen2({show: false, id: 0})} color="default">
                            Cancelar
                        </Button>
                        {open2.loading ? (
                            <CircularProgress />
                        ) : (
                            <Button disable={open2.loading} onClick={() => del_Movi(open2.id)} variant="contained" color="secondary" autoFocus>
                                Excluir
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </Box>
            {modalAddMoviShow.show && (
                <ModalAddMovi
                    open={modalAddMoviShow.show}
                    onClose={() => setModalAddMoviShow({
                        show: false,
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consulta={consultar_movimentacao}
                />
            )}
            {modalShow2.show && (
                <ModalEditMovi
                    open={modalShow2.show}
                    onClose={() => setModalShow2({
                        show: false,
                        id: 0
                    })}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consulta={consultar_movimentacao}
                    id={modalShow2.id}
                />
            )}
        </TabPanel>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    
    </Container2>
    );
}

export default Body;