import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { Box, CircularProgress, IconButton, InputLabel, Snackbar, Tooltip } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Styles from '../../../../components/Styles';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import api from '../../../../services/api';
import { getUser } from '../../../../services/auth';
import { Logo2 } from '../styles';
import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react"; 
import logo_icon from '../../../../img/icon.png';
import ModalAdd from './ModalAdd';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Plano = ({empresas, contratos, filiais, type}) => {
    const classes = Styles();
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleToggle = () => {
        setLoading(!loading);
    };
    const [exportCsv, setExportCsv] = useState([]);
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const [open, setOpen] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    function consultar(){
        for(let dados in state) {
            if(state[dados] === ""){
                setOpen({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
       
        handleToggle();
        let {empresa, contrato, filial} = state;
        api.get('/skap/treinamento',
            { 
                headers: { 
                        empresa, 
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            setLoading(false)
            handleClose();
            let {data} = response.data;
            let json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== '' && json !== '[]'){
                let where2 = [];
                json.forEach(dados => {
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                let uniques = [];
                let itemsFound = {};
                for(let i = 0, l = where2.length; i < l; i++) {
                    let stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns(uniques);
                setProducts(json);
                
                let export_temp = data.map((column)  => {
                    delete column.id;
                    let name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            
        });
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    return (
        <>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Importar resultado">
                                <IconButton  aria-label="Importar resultado" onClick={() => setModalShow(true)} variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Snackbar open={open.show} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={open.type}>
                        {open.text}
                    </Alert>
                </Snackbar>
            </Form>
            <Box component="span" m={1}>
                {loading ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    if(name === "Pagamento"){
                                                        if(dados[name] === 'Pago'){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <Badge color="success" className="mr-1">
                                                                    {dados[name]}
                                                                </Badge>
                                                            </Table.Col>);
                                                        }else{
                                                            if(dados[name] === 'Lançado'){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <Badge color="warning" className="mr-1">
                                                                        {dados[name]}
                                                                    </Badge>
                                                                </Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name]}
                                                                </Table.Col>);
                                                            }
                                                        }
                                                    }else{
                                                        if(name === "Emitido"){
                                                            if(dados[name] === 'Sim'){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <Badge color="success" className="mr-1">
                                                                        {dados[name]}
                                                                    </Badge>
                                                                </Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name]}
                                                                </Table.Col>);
                                                            }
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                        }
                                                    }
                                                }
                                                return false;
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
                {modalShow && (
                    <ModalAdd
                        open={modalShow}
                        onClose={() => {
                            setModalShow(false);
                            consultar();
                        }}
                        maxWidth="sm"
                        fullWidth={true}
                        empresa={state.empresa}
                        contrato={state.contrato}
                        filial={state.filial}
                    />
                )}
            </Box>
        </>
    );
}

export default Plano;