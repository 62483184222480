import React from 'react';
import LocationCity from '@material-ui/icons/LocationCity'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import ProgressBar from 'react-customizable-progressbar'
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { Container, Progress, Titulo } from './styles';


const useStyles = makeStyles((theme) => ({
    blue: {
        color: "#FFF",
        backgroundColor: "#004e95",
        marginLeft: 20,
        marginTop: 20,
    },
    square: {
        color: "#FFF",
        backgroundColor: "#ffce54",
        width: theme.spacing(7),
        height: theme.spacing(7),
        borderRadius: 0,
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        userSelect: "none",
        top:0,
        marginTop: 0,
        paddingTop: "40%",
        fontSize: 22,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paper2: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: -20,
        marginBottom: 10
    },
    footer: {
        marginBottom: 10
    },
    outros: {
        width: "100%",
        height: 180,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text_saida: {
        display: "flex",
        alignItems: "center",
    }
}));

function CardBoard({ data }) {
    const classes = useStyles();
    var percent = 0;
    percent = data.retorno / data.saida * 100;
    if(data.saida === 0){
        percent = 0;
    }
    if(data.retorno){
        if(data.retorno === 0){
            percent = 0;
        }
    }
   
    percent = percent.toFixed(0);

    var progress = true;
    if(data.ope !== "DU"){
        progress = false;
    }else{
        if(data.saida === 0){
            progress = false;
        }
    }
    var progress2 = true;
    /*if(data.saida === 0){
        progress2 = false;
    }*/

    return (
      <>
        {data ? (
            <>
                {progress2 &&
                    <Container>
                        <Grid container wrap="nowrap" spacing={2}>
                                <Avatar className={classes.blue}>
                                    <LocationCity /> 
                                </Avatar>
                            <Grid item xs>
                                <Titulo>{data.contrato} - {data.filial} -{ data.ope}</Titulo>
                            </Grid>
                        </Grid>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs>
                            {progress ? (
                                <div className={classes.paper}> 
                                    <Progress>
                                        <ProgressBar
                                            radius={70}
                                            progress={percent}
                                            strokeWidth={10}
                                            strokeColor="#ffce54"
                                            strokeLinecap="butt"
                                            trackStrokeWidth={8}
                                            trackStrokeLinecap="butt"
                                            cut={120}
                                            initialAnimation
                                            rotate={-210}
                                        >
                                        <div className={classes.indicator}>
                                            {percent}%
                                        </div>
                                        </ProgressBar>
                                    </Progress>
                                </div>         
                            ) : (
                                <>
                                    {data.saida === 0 ? (
                                        <div className={classes.paper}> 
                                            <Progress>
                                                <div className={classes.outros}> 
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={6}>
                                                            Não foram feito checklist hoje nessa filial
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                    </Grid>
                                                </div>
                                            </Progress>
                                        </div>
                                    ) : (
                                        <div className={classes.paper}> 
                                            <Progress>
                                                <div className={classes.outros}> 
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <Avatar variant="rounded" className={classes.square}>
                                                                <AssignmentIcon />
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.text_saida}>
                                                            Feitos: {data.saida}
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                    </Grid>
                                                </div>
                                            </Progress>
                                        </div>
                                    )}
                                    
                                </>
                            )}
                        </Grid>
                        </Grid>
                        {progress ? (
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className={classes.paper2}> 
                                        Saida: {data.saida}
                                    </div>
                                </Grid>
                                <Grid item xs={6} className={classes.footer}>
                                    <div className={classes.paper2}> 
                                        Retorno: {data.retorno}
                                    </div>   
                                </Grid>      
                            </Grid>
                        ) : (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.paper2}> 
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        
                    </Container>
                }
            </>
        ) :(
            <div/>
        )}
      </>
  );
}

export default CardBoard;