import React, {useState} from 'react';


import Button from '@material-ui/core/Button';
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PeopleIcon from '@material-ui/icons/People';
import FlagIcon from '@material-ui/icons/Flag';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../../../../../../../services/api'

import ModalLista from '../../../ModalLista';
import Lista from '../Lista';
import ModalAddAgenda from '../ModalAddAgenda';
import ModalAddAgendaEdit from '../ModalAddAgendaEdit';

import { getUser} from "../../../../../../../../services/auth";

// import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    botao: {
        padding: 0,
    }
}));


function TableRowAgenda({columns, dados, empresa, contrato, filial, setOnline, id_agenda, setAltera, altera}) {
    const classes = useStyles();
    const [modalShow, setModalShow] = useState({
        show: false,
        id: 0,
    });
    function listafunc(id){
        setModalShow({
            show: true,
            id
        })
    }

    const [modalShow2, setModalShow2] = useState({
        show: false,
        id: 0,
    });
    function abrir_document(id){
        setModalShow2({
            show: true,
            id
        })
    }
    function open_video(nome){
        window.open('http://www.cilog.com.br/backend/public/videos/' + nome);
    }   
    const [modalShowAgenda, setModalShowAgenda] = useState({
        status: false,
        id: 0,
    });
    const [modalShowAgendaEdit, setModalShowAgendaEdit] = useState({
        status: false,
        id: 0,
        data: null
    });
    function func_agenda(id, idAgenda){
        setModalShowAgenda({
            status: true,
            idTreina: id.id,
            id: idAgenda
        });
    }
    function finalizar(id){
        api.post('treinamento/finaliza',
		{ 
			id,
			user: getUser(),
		})
		.then(function(response){
            var { status } = response.data;
            // eslint-disable-next-line eqeqeq
            if(status == 1){
                var cont = altera;
                cont = cont + 1;
                setAltera(cont)
            }
		});
    }
    function editarAgendamento(id, data){
        console.log(data);
        setModalShowAgendaEdit({
            status: true,
            id,
            data
        })
    }
  return (
        <Table.Row>
            {columns.map((column, index) => {
                var name = column.text;
                if(name !== "id"){
                    if(name === "Participantes"){
                        return (<Table.Col className={classes.linha} key={dados.id + name}>
                                <div className={classes.linha2}>
                                    <Button className={classes.botao} 
                                        aria-label="Participantes" 
                                        size="small"
                                        onClick={() => listafunc(dados.id)}>
                                        {dados[name]}
                                        <GroupIcon fontSize="small"/>
                                    </Button>
                                </div>
                        </Table.Col>);
                    }else{
                        if(name === "Ata"){
                            return (<Table.Col className={classes.linha} key={dados.id + name}>
                                        <div className={classes.linha2}>
                                            <Tooltip title="Ata de presença">
                                                <IconButton  
                                                size="small"
                                                color="primary" 
                                                key={dados.id} 
                                                onClick={() => abrir_document(dados.id)}
                                                aria-label="Ata de presença">
                                                    <AssignmentIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                </Table.Col>);
                        }else{
                            if(name === "Excluir"){
                                // eslint-disable-next-line eqeqeq
                                if(dados[name] != 0){
                                    return (<Table.Col className={classes.linha} key={dados.id + name}>
                                                <div className={classes.linha2}>
                                                    <Tooltip title="Excluir">
                                                        <IconButton  
                                                        size="small"
                                                        color="secondary"
                                                        key={dados.id} 
                                                        
                                                        aria-label="Excluir">
                                                            <DeleteForeverIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        </Table.Col>);
                                }
                            }else{
                                if(name === "Video"){
                                    return (<Table.Col className={classes.linha} key={dados.id + name}>
                                                <div className={classes.linha2}>
                                                    <Tooltip title="Video">
                                                        <IconButton  
                                                        size="small"
                                                        color="primary" 
                                                        key={dados.id} 
                                                        onClick={() => open_video(dados[name])}
                                                        aria-label="Video">
                                                            <OndemandVideoIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        </Table.Col>);
                                }else{
                                    if(name === "Func Agendados"){
                                        return (<Table.Col className={classes.linha} key={dados.id + name}>
                                                <div className={classes.linha2}>
                                                    <Button className={classes.botao} 
                                                        aria-label="Participantes" 
                                                        color="primary" 
                                                        size="small"
                                                        onClick={() => func_agenda(id_agenda ,dados.id)}>
                                                        {dados[name]}
                                                        <PeopleIcon fontSize="small"/>
                                                    </Button>
                                                </div>
                                            </Table.Col>);
                                    }else{
                                        // eslint-disable-next-line eqeqeq
                                        if(name === "online" && dados[name] == 1){
                                            setOnline(false);
                                        }else{
                                            // eslint-disable-next-line eqeqeq
                                            if(name === "online" && dados[name] == 0){
                                                return false;
                                            }else{
                                                return (<Table.Col className={classes.linha} key={dados.id + name}>
                                                        <div className={classes.linha2}>
                                                            {dados[name]}
                                                        </div>
                                                    </Table.Col>); 
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return false;
            })}
            {dados.Status === "Não finalizado" && (
                <Table.Col className={classes.linha}>
                    <div className={classes.linha2}>
                        <Tooltip title="Finalizar">
                            <IconButton  
                            size="small"
                            color="secondary"
                            key={dados.id} 
                            onClick={() => finalizar(dados.id)}
                            aria-label="Excluir">
                                <FlagIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Table.Col>
            )}
            {dados.Status !== "Finalizado" && (
                <Table.Col className={classes.linha} key={dados.id + 'editar'}>
                    <div className={classes.linha2}>
                        <Button className={classes.botao} 
                            aria-label="Editar" 
                            size="small"
                            onClick={() => editarAgendamento(dados.id, dados.Data)}>
                            <EditIcon fontSize="small"/>
                        </Button>
                    </div>
                </Table.Col>
            )}
            {modalShow ? (
                <ModalLista
                open={modalShow.show}
                onClose={() => setModalShow({
                    show: false,
                    id: 0
                })}
                url="/treinamento/3/"
                maxWidth="md"
                fullWidth={true}
                empresa={empresa}
                contrato={contrato}
                filial={filial}
                id={modalShow.id}
            />
            ):null}
            {modalShow2 ? (
                <Lista
                open={modalShow2.show}
                onClose={() => setModalShow2({
                    show: false,
                    id: 0
                })}
                maxWidth="lg"
                fullWidth={true}
                empresa={empresa}
                contrato={contrato}
                filial={filial}
                id={modalShow2.id}
            />
            ):null}
            {modalShowAgenda.status ? (
                <ModalAddAgenda
                open={modalShowAgenda.status}
                onClose={() => setModalShowAgenda({
                    'status': false,
                    'id': 0
                })}
                empresa={empresa}
                contrato={contrato}
                filial={filial}
                maxWidth="md"
                fullWidth={true}
                idTreina={modalShowAgenda.idTreina}
                setAltera={setAltera}
                altera={altera}
                id={modalShowAgenda.id}
                />
            ) : null}
            {modalShowAgendaEdit.status && (
                <ModalAddAgendaEdit
                open={modalShowAgendaEdit.status}
                onClose={() => setModalShowAgendaEdit({
                    'status': false,
                    'id': 0
                })}
                empresa={empresa}
                contrato={contrato}
                filial={filial}
                maxWidth="md"
                fullWidth={true}
                idTreina={modalShowAgendaEdit.idTreina}
                setAltera={setAltera}
                altera={altera}
                data={modalShowAgendaEdit.data}
                id={modalShowAgendaEdit.id}
                />
            )}
        </Table.Row>
  );
}

export default TableRowAgenda;