import axios from 'axios';
import { getToken , login} from "./auth";

import { config } from './constants'

var url = config.url.API_URL

const api = axios.create({
  baseURL: url
});
api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});
api.interceptors.response.use((response) => {
  // Do something with response data
  return response;
},(error) => {
  // Do something with response error
  
  // You can even test for a response code 
  // and try a new request before rejecting the promise
  if (error.response === undefined) {  
    return error;
  }
  if (error.response.status === 401) {     
    login(null);
    return window.location.href = '/'
  }
  if (error.response.status === 403) {     
    login(null);
    return window.location.href = '/'
  }
  
  return Promise.reject(error);
});

export default api;