import React from 'react';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import {getMat} from "../../../../../services/auth";

import api from '../../../../../services/api'

import { lightGreen } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    subtitle1: {
      background: "#bf7c2e",
    }

}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
}))(Button);

function DialogTitle(props) {
    const classes = useStyles();
    const { children, onClose, open, open4, descr, open5, id_coment, ...other } = props;

    function aprova_critica(status){
      api.get('/precontra/' + id_coment+"/edit",
          { 
              headers: { 
                      idfunc: getMat(),
                      status,
                  } 
              }
          )
      .then(function (response) {
          onClose();
      })
  }
  


    return (
      <>
        <Backdrop className={classes.backdrop} open={open4} >
        </Backdrop>
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          <Typography  variant="subtitle1">{descr}</Typography>
          {onClose ? (
              open ? (null): (
                  <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                      <CloseIcon />
                  </IconButton>
              )
          ) : null}
          {open5 ? (
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={3}>
                      <Button color="secondary" onClick={() => aprova_critica(2)}>Reprovar</Button>
                  </Grid>
                  <Grid item xs={3}>
                      <ColorButton variant="contained" color="primary" onClick={() => aprova_critica(1)}>Aprovar</ColorButton>
                  </Grid>
              </Grid>
            ): (null)}
        </MuiDialogTitle>
      </>
    );
}

export default DialogTitle;