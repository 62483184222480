import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput from "../../../../functions/CurrencyInput"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../components/Styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'

import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput
        {...other}
        
      />
    );
}

function ModalEditMovi(props) {
    const classes = Styles();
    var {empresa, contrato, filial, id, onClose, consulta, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [save, setSave] = useState(false);
    const [formMEntrada, setMEntrada] = useState({
        error: false,
        helperText: '',
    })
    const [formMsaida, setMSaida] = useState({
        error: false,
        helperText: '',
    })

    useEffect (() => {
        async function carrega(){
            setOpen(true);
            await api.get('/pneu/movimentacao/' + id,
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                            type: 3,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let { status, data } = response.data;
                if(status === true){
                    let { date, posicao, motivo, m1_saida, m2_saida, m3_saida, m4_saida, carro, pneu_saida, m1, m2, m3, m4, pneu_entrada} = data;
                    let { placa } = carro;
                    document.getElementById('data_edit').value = date;
                    document.getElementById('placa_edit').value = placa;
                    document.getElementById('motivo_edit').value = motivo;
                    document.getElementById('posicao_edit').value = posicao;
                    document.getElementById('fogo_saida_edit').value = pneu_saida.fogo;
                    if(m1_saida != null){
                        document.getElementById('m1_saida_edit').value = m1_saida;
                    }
                    if(m2_saida != null){
                        document.getElementById('m2_saida_edit').value = m2_saida;
                    }
                    if(m3_saida != null){
                        document.getElementById('m3_saida_edit').value = m3_saida;
                    }
                    if(m4_saida != null){
                        document.getElementById('m4_saida_edit').value = m4_saida;
                    }
                    document.getElementById('fogo_entrada_edit').value = pneu_entrada.fogo;
                    document.getElementById('m1_entrada_edit').value = m1;
                    document.getElementById('m2_entrada_edit').value = m2;
                    document.getElementById('m3_entrada_edit').value = m3;
                    document.getElementById('m4_entrada_edit').value = m4;
                }else{
                    let { message } = response.data;
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
            });
        }
        carrega();
      }, [empresa, contrato, filial, id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    
    function salvar(){
        let m1_entrada_edit = document.getElementById('m1_entrada_edit').value;
        let m2_entrada_edit = document.getElementById('m2_entrada_edit').value;
        let m3_entrada_edit = document.getElementById('m3_entrada_edit').value;
        let m4_entrada_edit = document.getElementById('m4_entrada_edit').value;
        let m1_saida_edit = document.getElementById('m1_saida_edit').value;
        let m2_saida_edit = document.getElementById('m2_saida_edit').value;
        let m3_saida_edit = document.getElementById('m3_saida_edit').value;
        let m4_saida_edit = document.getElementById('m4_saida_edit').value;
        let motivo = document.getElementById('motivo_edit').value;
        let fogosaida = document.getElementById('fogo_saida_edit').value;
        let fogoentrada = document.getElementById('fogo_entrada_edit').value;
        let data = document.getElementById('data_edit').value;
        setMSaida({
            error: false,
            helperText: '',
        })
        setMEntrada({
            error: false,
            helperText: '',
        })
        if(data == null || data == ''){
            setOpen3({
                show: true,
                text: 'Preencha a data!',
                type: "error"
            });
            return false;
        }
        if(motivo == null || motivo == ''){
            setOpen3({
                show: true,
                text: 'Preencha o motivo!',
                type: "error"
            });
            return false;
        }
        if(fogosaida == null || fogosaida == ''){
            setOpen3({
                show: true,
                text: 'Preencha o fogo de saida!',
                type: "error"
            });
            return false;
        }
        if(m1_saida_edit == null || m1_saida_edit == ''){
            setMSaida({
                helperText: 'Preencha as milimetragens de Saida.',
                error: true
            })
            return false;
        }
        if(m2_saida_edit == null || m2_saida_edit == ''){
            setMSaida({
                helperText: 'Preencha as milimetragens de Saida.',
                error: true
            })
            return false;
        }
        if(fogoentrada == null || fogoentrada == ''){
            setOpen3({
                show: true,
                text: 'Preencha o fogo de entrada!',
                type: "error"
            });
            return false;
        }
        if(m1_entrada_edit == null || m1_entrada_edit == ''){
            setMEntrada({
                helperText: 'Preencha as milimetragens de entrada.',
                error: true
            })
            return false;
        }
        if(m2_entrada_edit == null || m2_entrada_edit == ''){
            setMEntrada({
                helperText: 'Preencha as milimetragens de entrada.',
                error: true
            })
            return false;
        }
        setSave(true)
        api.get('/pneu/movimentacao/'+id+'/edit',
        { 
            headers: { 
                user: getUser(),
                empresa,
                contrato,
                filial,
                m1: m1_entrada_edit,
                m2: m2_entrada_edit,
                m3: m3_entrada_edit,
                m4: m4_entrada_edit,
                m1saida: m1_saida_edit,
                m2saida: m2_saida_edit,
                m3saida: m3_saida_edit,
                m4saida: m4_saida_edit,
                data,
                motivo,
                fogosaida,
                fogoentrada
            } 
        })
        .then(function(response){
            setSave(false)
            var {status, erro} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Alteração salva com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
            }
        }).catch(function(error){
            setSave(false);
            setOpen3({
                show: true,
                text:  "Ocorreu um erro!",
                type: "error"
            });
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Alterar Movimentação</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={3}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="placa_edit">Placa</InputLabel>
                               <TextField
                                    size="small"
                                    id="placa_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="placa_edit">Data</InputLabel>
                               <TextField
                                    size="small"
                                    id="data_edit"
                                    type="date"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="motivo_edit">Motivo</InputLabel>
                               <TextField
                                    size="small"
                                    id="motivo_edit"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="posicao_edit">Posição</InputLabel>
                               <TextField
                                    size="small"
                                    id="posicao_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="fogo_saida_edit">Fogo Saida</InputLabel>
                                <TextField
                                    size="small"
                                    id="fogo_saida_edit"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m1_saida_edit">M1 Saida</InputLabel>
                                <Input
                                size="small"
                                id="m1_saida_edit"
                                error={formMsaida.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formMsaida.error}>{formMsaida.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m2_saida_edit">M2 Saida</InputLabel>
                                <Input
                                size="small"
                                id="m2_saida_edit"
                                error={formMsaida.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formMsaida.error}>{formMsaida.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m3_saida_edit">M3 Saida</InputLabel>
                                <Input
                                size="small"
                                id="m3_saida_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m4_saida_edit">M4 Saida</InputLabel>
                                <Input
                                size="small"
                                id="m4_saida_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="fogo_saida_edit">Fogo Entrada</InputLabel>
                               <TextField
                                    size="small"
                                    id="fogo_entrada_edit"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m1_entrada_edit">M1 Entrada</InputLabel>
                                <Input
                                size="small"
                                id="m1_entrada_edit"
                                error={formMEntrada.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formMEntrada.error}>{formMEntrada.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m2_entrada_edit">M2 Entrada</InputLabel>
                                <Input
                                size="small"
                                id="m2_entrada_edit"
                                error={formMEntrada.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formMEntrada.error}>{formMEntrada.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m3_entrada_edit">M3 Entrada</InputLabel>
                                <Input
                                size="small"
                                id="m3_entrada_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m4_entrada_edit">M4 Entrada</InputLabel>
                                <Input
                                size="small"
                                id="m4_entrada_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={save} onClick={onClose}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button color="primary" disabled={save} onClick={() => {salvar()}} variant="contained">Salvar</Button>
                    {save && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEditMovi;