import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import FormHelperText from '@material-ui/core/FormHelperText';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'

import Styles from '../../Styles';

import DialogTitle from '../../BoardCheck/Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getMat} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit(props) {
    const classes = Styles();
    var {id, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [etapas, setEtapas] = useState([]);
    async function carrega_dados(){
        setOpen(true);
        await api.get('/socorro/' + id,
            { 
                headers: { 

                        user: getMat(),
                        tipo: 1,
                    } 
                }
            )
        .then(function (response) {
            setOpen(false);
            
            var { data, placa, item, etapas } = response.data;
            setEtapas(etapas);
            document.getElementById('anomalia_data').value = data;
            document.getElementById('anomalia_placa').value = placa;
            document.getElementById('anomalia_item').value = item;
        });
    }

    useEffect (() => {
        
        carrega_dados();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    const [date, setDate] = useState("");
    const handleChange2 = (event) => {
        const value = event.target.value;
        setDate(value);
        
    };
    const [formEtapa, setEtapa] = useState({
        error: false,
        helperText: '',
    })
    const [formData, setData] = useState({
        error: false,
        helperText: '',
    })
    const [formHora, setHora] = useState({
        error: false,
        helperText: '',
    })
    function salva_etapas(){
        var etapa = document.getElementById('itens_socorro_etapa').value;
        var data = document.getElementById('itens_socorro_data').value;
        data = data.split("-").reverse().join("/");
        var hora = document.getElementById('itens_socorro_hora').value;
        setEtapa({
            error: false,
            helperText: '',
        })
        setData({
            error: false,
            helperText: '',
        })
        setHora({
            error: false,
            helperText: '',
        })
        
        if(etapa === ""){
            setEtapa({
                  helperText: 'Selecione a Etapa.',
                  error: true
              })
            return false;
        }
        if(data === ""){
            setData({
                  helperText: 'Preencha a data.',
                  error: true
              })
            return false;
        }
        if(hora === ""){
            setHora({
                  helperText: 'Preencha a hora.',
                  error: true
              })
            return false;
        } 
        api.post('/socorro/inclui',
        { 
            user: getMat(),
            etapa,
            data,
            hora,
            id
        })
        .then(function(response){
            var {status, erro} = response.data;
            if(status === 1){
                setOpen3({
                    show: true,
                    text: 'Etapa salva com sucesso!',
                    type: "success"
                });
                carrega_dados()
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
            }
           
        })
        
        
    }
    function handleCloase(){
        onClose();
        consulta();
    }
   
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={handleCloase} open4={false} open={open}>Editar Socorro</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_data">Data</InputLabel>
                               <TextField
                                    size="small"
                                    id="anomalia_data"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_placa">Placa</InputLabel>
                               <TextField
                                size="small"
                                    id="anomalia_placa"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_item">Item</InputLabel>
                               <TextField
                                    size="small"
                                    id="anomalia_item"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.linha} htmlFor="itens_socorro_etapa">Etapa</InputLabel>
                            <Select
                            native
                            className={classes.textField}
                            error={formEtapa.error}
                            inputProps={{
                                name: 'etapa',
                                id: 'itens_socorro_etapa',
                            }}
                            >
                                <option value="">Selecione...</option>
                                { etapas ? 
                                etapas.map((dados, index) => (
                                    <option key={dados.id} value={dados.id}>{dados.descr}</option>
                                ))
                                : null
                                }
                            </Select>
                            <FormHelperText  error={formEtapa.error}>{formEtapa.helperText}</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className={classes.linha} htmlFor="itens_socorro_data">Data</InputLabel>
                           <TextField
                                type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="itens_socorro_data" 
                                value={date}
                                error={formData.error}
                                helperText={formData.helperText}
                                onChange={handleChange2}
                                inputProps={{
                                    name: 'Data',
                                    id: 'itens_socorro_data',
                                }}
                                placeholder='dd/mm/aaaa'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className={classes.linha} htmlFor="itens_socorro_hora">Hora</InputLabel>
                           <TextField
                                id="time"
                                type="time"
                                className={classes.textField}
                                error={formHora.error}
                                helperText={formHora.helperText}
                                inputProps={{
                                    step: 60, // 1 min
                                    name: 'Hora',
                                    id: 'itens_socorro_hora',
                                }}
                            />
                        </Grid>
                        
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary"  onClick={handleCloase}>Cancelar</Button>
                    <Button color="primary" onClick={() => {salva_etapas()}} variant="contained">Salvar</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;