import React, {useState, useEffect} from 'react';

import { Logo2 } from './styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import logo_icon from '../../img/icon.png';

import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';
import Body from './components/Body';

import api from '../../services/api';

import {getEmpresas, getContratos, getFilials} from "../../services/auth";

import Styles from '../../components/Styles'

function Treinamentos({ history }) {
    const classes = Styles();
    const [empresas, setEmpresas] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [filials, setFilials] = useState([]);
    const [opes, setOpes] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [ano, setAno] = useState([]);

    const [loadding, setLoadding] = useState({ show: true });

    useEffect (() => {
        var empresas2 = getEmpresas();
        var temp = JSON.parse(empresas2);
        setEmpresas(temp);
        var contratos2 = getContratos();
        var temp2 = JSON.parse(contratos2);
        setContratos(temp2);
        var filials2 = getFilials();
        var temp3 = JSON.parse(filials2);
        setFilials(temp3);
        async function loadCheck(){
            var response = await api.get(`/treinamento/tipos_opeClient`,{
                headers:{
                    empresa: temp[0].id,
                    contrato: temp2[0].id,
                    filial: temp3[0].id
                }
                
            });
            var {ope, tipo} = response.data;
            setOpes(ope);
            setTipos(tipo);
            setLoadding({show: false});
            
        }
        async function carrega_ano(){
            await api.get('/ano',
                { 
                    headers: { 
                        } 
                    }
                )
            .then(function (response) {
                var ano = response.data;
                setAno(ano);
            });
        }
        carrega_ano();
        loadCheck();
    }, []);

  return (
    <>
        {loadding.show ? (
            <Logo2>
                <img src={logo_icon} alt="" />
                <CircularProgress />
            </Logo2>
        ) : (
            <>
                <Hearder history={history} />
                <Menu history={history} />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Body empresas={empresas} contratos={contratos} filiais={filials} opes={opes} tipos={tipos} anos={ano}/>
                </main>
            </>
        )}
    </>
  );
}

export default Treinamentos;