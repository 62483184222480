import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getEmpresa, getContrato, getFilial} from "../../services/auth";
import api from '../../services/api';
import soundfile from '../../assets/95991915.m4a';
import soundfile2 from '../../assets/113804430.mp3';
import soundSocorro from '../../assets/socorro.m4a';
import NetworkDetector from './components/NetworkDetector';
import './App.css';


import List from './components/List';
import Modal from './components/Modal';

import logo_icon from '../../img/icon.png';

import { Priva, Title, Container, Logo } from './styles';
import AnomaliaList from './components/AnomaliaList';

const useStyles = makeStyles((theme) => ({
  textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "250px",
      fontSize: "25px",
      color: "#FFF",
  },
  alarm: {
      display: "none",
  }
}));

function Os() {
  const classes = useStyles();
  const [periodo, setPeriodo] = useState(1);
  const [pendentes, setPendentes] = useState(0);
  const [loadding, setLoadding] = useState({ show: true });
  const [servicos, setServicos] = useState([]);
  const [anomalias, setAnomalias] = useState([]);
  const [audio] = useState(new Audio(soundfile));
  const [audio2] = useState(new Audio(soundfile2));
  const [open, setOpen] = useState(false);
  const [socorro, setSocorro] = useState([]);
  const [soundsocorro] = useState(new Audio(soundSocorro));
 

  function onShow() {
    setLoadding({ show: true })
  }
  function onHide() {
    setLoadding({ show: false })
  }
  const handleChange = (event) => {
    onShow();
    setPeriodo(event.target.value);

  };
  const empresa = getEmpresa();
  const contrato = getContrato();
  const filial = getFilial();

  async function loadServicoes2(periodo){
    var response = await api.get(`/servicos`,{
      headers:{
          empresa,
          contrato,
          filial,
          periodo,
      }
    });
    if(response.data != null && response.data !== ''){
      var {servicos, socorro, anomalias, externo} = response.data;
      if(servicos != null){
        var data = servicos;
        var pend = false;
          // eslint-disable-next-line array-callback-return
          data.map((servico, index) => {
              if (servico.novo === 1) {
                pend = true;
              }
            });
        if(pend){
          toggle();
        }
        setServicos(data)
        setPendentes(data.length)
        if(anomalias != null && anomalias != ''){
          if(anomalias.length > 0){
            document.getElementById("alarm3").click();
            setAnomalias(anomalias)
          }else{
            setAnomalias([])
          }
        }else{
          setAnomalias([])
        }
      }
      if(socorro != null){
        setSocorro(socorro);
        setOpen(true);
        document.getElementById("alarm2").click();
      }else{
        setSocorro([]);
        setOpen(false);
      }
      setExterno(externo);
    }
  };
  function toggle(){
    document.getElementById("alarm").click();
  }
  function autoScrollandReload(scrollDistance,pause){
    /// start the interval with the pause in between executions
    var i = 1;
    var intervalScroll = setInterval(function(){
      let altura = document.body.scrollHeight;
      var fracao = altura/scrollDistance;
      if(fracao < i){
        window.scrollTo({top: 0, behavior: 'smooth' })
        i = 1;
        //clearInterval(interval);
      }else{
        window.scrollTo({top: i*scrollDistance, behavior: 'smooth' })
        i++
      };                

    },pause);

};
  const [externo, setExterno] = useState(false);
  useEffect (() => {
    async function loadServicoes(){
      onShow();
      var response = await api.get(`/servicos`,{
        headers:{
            empresa,
            contrato,
            filial,
            periodo,
        }
      });
      if(response.data != null && response.data !== ''){
        var {servicos, socorro, externo, anomalias} = response.data;
        if(servicos != null){
          var data = servicos;
          setServicos(data)
          setPendentes(data.length)
          if(anomalias != null && anomalias != ''){
            if(anomalias.length > 0){
              setAnomalias(anomalias)
              document.getElementById("alarm3").click();
            }else{
              setAnomalias([])
            }
          }else{
            setAnomalias([])
          }
          var pend = false;
          // eslint-disable-next-line array-callback-return
          data.map((servico, index) => {
            if(servico.novo === 1){
              pend = true;
            }
          });
          if(pend){
            toggle();
          }
        }
        if(socorro != null){
          setSocorro(socorro);
          setOpen(true);
          document.getElementById("alarm2").click();
        }else{
          setSocorro([]);
          setOpen(false);
        }
        setExterno(externo);
      }
      onHide()
    };
    loadServicoes()
    
    let interval = setInterval(() => loadServicoes2(periodo), 20000);
    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodo]);
  async function atualizaProlog(){
    var response = await api.get(`/prolog`,{
      headers:{
          empresa,
          contrato,
          filial,
      }
    });
    if(response.data != null && response.data !== ''){
      console.log(response.data)
    }
  }
  useEffect (() => {
    if(externo){
      atualizaProlog()
      let interval = setInterval(() => atualizaProlog(), 60000);
      return () => {
        clearInterval(interval);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externo]);
  useEffect (() => {
    autoScrollandReload(300, 15000);
  }, []);
  return (
    <>
    <div className={classes.alarm}>
      <button id="alarm" onClick={() => audio.play()}></button>
    </div>
    <div className={classes.alarm}>
      <button id="alarm2" onClick={() => soundsocorro.play()}></button>
    </div>
    <div className={classes.alarm}>
      <button id="alarm3" onClick={() => audio2.play()}></button>
    </div>
    {loadding.show ? (
      <Logo>
        <img src={logo_icon} alt="" />
        <CircularProgress />
      </Logo>
    ) : (
      <Priva>
          <Container>
            <div style={{background: "#212121", height: 80, position: 'fixed', width: 'calc(100% - 40px)'}}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={1}>
                  <Title><img src={logo_icon} alt="CILog" /></Title>
                </Grid>
                <Grid item xs={5}>
                  <Title>OS pendentes: {pendentes}</Title>
                </Grid>  
                <Grid item xs={2}>
                  <Title> 
                    Periodo:
                    </Title>
                </Grid>
                <Grid itemxs={2}>
                  <Select 
                    className={classes.textField}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={periodo}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Hoje</MenuItem>
                    <MenuItem value={7}>Ultimos 7 dias</MenuItem>
                    <MenuItem value={15}>Ultimos 15 dias</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </div>
            <div style={{background: "#212121", height: 80}}></div>
            {anomalias.map((anomalia) => (<AnomaliaList key={anomalia.id} anomalia={anomalia} />))}
            {servicos.length > 0 && (
              externo ? (
                servicos.map((servico) => (<List key={servico.id}  data={servico} externo={externo} />))
              ) : (
                servicos.map((servico) => (<List key={servico.os}  data={servico} externo={externo} />))
              )
            )}
          </Container>
          {open ? (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    maxWidth="lg"
                    fullWidth={true}
                    data={socorro}
                />
            ):null}
            <div id="contact-us"></div>
      </Priva>
    )}
    </>
  );
}

export default NetworkDetector(Os);