import React from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

const styles2 = StyleSheet.create({
    tab: {
        width: "94%",
        flexDirection: "row",
        borderBottom: 0.5,
        borderColor: "#a3a3a3",
        padding: 5,
        justifyContent: "space-around",
    },
    
    image: {
        width: 100,
        height: 30
    },
    text: {
        fontSize: 8,
        width: "31%",
    },
    text2: {
        fontSize: 8,
        width: "7%",
    }
    
});


function Body({dados}) {
  return (
    <View style={styles2.tab}>
         <Text style={styles2.text2}>{dados.Mat}</Text>
         <Text style={styles2.text}>{dados.Nome}</Text>
         <Text style={styles2.text}>{dados.Função}</Text>
         <Text style={styles2.text}>{dados.Assinatura}</Text>
    </View>
);
}

export default Body;