import React , {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { Badge } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from './styles';

const colors = [
    '#000',
    '#f8a23d60',
];
const useStyles = makeStyles({
    sucess: {
        background: "#28a745 !important",
        color: "#000",
        font: "20px 'Roboto', sans-serif",
    },
    danger: {
        font: "20px 'Roboto', sans-serif",
        color: "#000",
        background: "#f8a23d !important",
    },
    dangerBadge: {
        font: "20px 'Roboto', sans-serif",
        color: "#000",
        background: "#EE4B2B !important",
    },
    alert: {
        background: "#414141 !important",
    },
    alert2: {
        background: "#000 !important",
    }
});

function AnomaliaList( {anomalia}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    useEffect (() => {
        let interval = setInterval(() => {
            setValue((v) => (v === 1 ? 0 : 1));}, 1000);
            return () => {
            clearInterval(interval);
        };
    }, [anomalia]);

    return (
        <Container style={{ backgroundColor: colors[value] }}>
            <Badge pill variant="warning" className={classes.danger}>
                Anomalia de Checklist
            </Badge>
            <Grid container spacing={1} justifyContent="center" >
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            Anomalia: {anomalia.id}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            Veiculo: {anomalia.placa}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            Data: {anomalia.data}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9} >
                    <Grid container>
                        <Grid item xs={4}>
                            Prefixo: {anomalia.prefixo}
                        </Grid>
                        <Grid item xs={4}>
                            CheckList: {anomalia.tipoanomalia}
                        </Grid>
                        <Grid item xs={4}>
                            Prazo: {anomalia.prazo} dias
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            Motorista: {anomalia.motorista}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={12}>
                                Item: {anomalia.item} - {anomalia.obs}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AnomaliaList;