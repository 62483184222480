import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'

import Styles from '../../Styles';

import DialogTitle from '../Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit(props) {
    const classes = Styles();
    var {id, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [placehorder, setplacehorder] = useState(null);

    const [formOs, setOs] = useState({
        errorOs: false,
        helperTextOs: '',
    })

    useEffect (() => {
        async function carrega_dados(){
            setOpen(true);
            await api.get('/checkrelatorio/' + id,
                { 
                    headers: { 

                            user: getUser(),
                            tipo: 1,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                var { data, placa, item, os } = response.data;
                document.getElementById('anomalia_data').value = data;
                document.getElementById('anomalia_placa').value = placa;
                document.getElementById('anomalia_item').value = item;
                if(os != null){
                    if(os === '123'){
                        setplacehorder(os)
                    }else{
                        document.getElementById('anomalia_os').value = os;
                    }
                }
            });
        }
        carrega_dados();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salva_com_os(){
        var os_change = document.getElementById('anomalia_os').value;
        setOs({
            errorData: false,
            helperTextData: '',
        })
       
        if(os_change === ""){
            setOs({
                helperTextOs: 'Digite uma OS.',
                errorOs: true
            })
            return false;
        }
        setOpen(true);
        api.get('/checkrelatorio/'+id+'/edit',
        { 
            headers: { 
                user: getUser(),
                tipo: 2,
                os: os_change,
            } 
        })
        .then(function(response){
            var {status} = response.data;
            if(status === 1){
                setOpen3({
                    show: true,
                    text: "OS não encontrada no ERP!",
                    type: "error"
                });
            }else{
                setOpen3({
                    show: true,
                    text: 'Vinculado com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }
             setOpen(false);
        })
    }
    function salva_sem_os(){
        setOpen(true);
        api.get('/checkrelatorio/'+id+'/edit',
        { 
            headers: { 
                user: getUser(),
                tipo: 1,
            } 
        })
        .then(function(response){
            setOpen(false);
            onClose();
            consulta();
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Vincular OS na anomalia</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_data">Data</InputLabel>
                               <TextField
                                    size="small"
                                    id="anomalia_data"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_placa">Placa</InputLabel>
                               <TextField
                                    size="small"
                                    id="anomalia_placa"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_item">Item</InputLabel>
                               <TextField
                                    size="small"
                                    id="anomalia_item"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="anomalia_os">OS</InputLabel>
                               <TextField
                                size="small"
                                error={formOs.errorOs}
                                placeholder={placehorder}
                                helperText={formOs.helperTextOs}
                                    id="anomalia_os"
                                    type="number"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary"  onClick={onClose}>Cancelar</Button>
                    <Button color="secondary" onClick={() => {salva_sem_os()}} variant="contained">Sem OS</Button>
                    <Button color="primary" variant="contained" onClick={() => {salva_com_os()}}>Com OS</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;