import React, {useState, useEffect} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { Form } from 'react-bootstrap';
import api from '../../../../../../services/api'


import {L10n} from '@syncfusion/ej2-base';

import { Logo2, Header } from './styles';
import logo_icon from '../../../../../../img/icon.png';

import { getUser} from "../../../../../../services/auth";

import TableRowAgenda from './components/TableRowAgenda';
import TableRowQuiz from './components/TableRowQuiz';
import ModalAddQuiz from './components/ModalAddQuiz';
import ModalAddAgenda from './components/ModalAddAgenda';
import DialogTitle from '../DialogTitle';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

L10n.load({
    "pt-PT": {
        "uploader": {
            "Browse"  : "Selecionar arquivo",
            "Clear" : "Limpar",
            "Upload" : "Enviar",
            "cancel": "Cancelar",
            "delete": "Apagar",
            "dropFilesHint" : "ou solte os arquivos aqui",
            "inProgress": "Téléchargement",
            "invalidFileType" : "Tipo de arquivo não permitido",
            "uploadFailedMessage" : "Não foi possivel enviar o aquivo!",
            "uploadSuccessMessage" : "Arquivo enviado com sucesso!",
            "readyToUploadMessage": "Pronto para Enviar!",
        }
    }
})

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    center: {
        display: "flex",
        justifyContent: "center"
    }
  }));

function ModalDetals(props) {
    const classes = useStyles();
    const [lista, setLista] = useState(false);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista2, setLista2] = useState(false);
    const [columns2, setColumns2] = useState(null);
    const [products2, setProducts2] = useState(null);

    const [clientes, setClientes] = useState([]);
    const [areas, setAreas] = useState([]);
    const open4 = false;
    const [open, setOpen] = useState(true);
    const [online, setOnline] = useState(true);
    var {empresa, contrato, filial, id, onClose, ...rest} = props;
    const [altera, setAltera] = useState(0);

    const [modalShowAgenda, setModalShowAgenda] = useState({
        status: false,
        id: 0,
    });
    function add_agenda(id, idAgenda){
        setModalShowAgenda({
            status: true,
            idTreina: id.id,
            id: idAgenda
        });
    }

    useEffect (() => {
        async function carrega_placas(){
            setOpen(true);
            await api.get('/treinamento/tipos_opeClient',
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                var {ope, tipo} = response.data;
                setAreas(tipo)
                setClientes(ope);
            });
            await api.get('/treinamento/details2/' + id.id,
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                var {data, tipo, cliente, titulo, objetivo, mat, nome, tempo, online, datas, perguntas} = response.data;
                document.getElementById('titulo_incluir').value = titulo;
                document.getElementById('objetivo_incluir').value = objetivo;
                document.getElementById('area_inclui').value = tipo;
                document.getElementById('cliente_inclui').value = cliente;
                document.getElementById('data').value = data;
                document.getElementById('hora_incluir').value = tempo;
                document.getElementById('mat_incluir').value = mat;
                document.getElementById('nome_incluir').value = nome;
                document.getElementById('tipo_inclui').value = online;

                var json = datas;
                data = JSON.stringify(json);
                data = JSON.parse(data);
                if(json !== null && json !== ''  && json !== []){
                    let where2 = [];
                    var dados = json[0];
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                    setColumns(where2);
                    setProducts(json);
                    setLista(true);
                }else{
                    setLista(false);
                    setProducts(null);
                }
                var json2 = perguntas;
                data = JSON.stringify(json2);
                data = JSON.parse(data);
                if(json2 !== null && json2 !== ''  && json2 !== []){
                    let where2 = [];
                    var dados2 = json2[0];
                    for(var colunas2 in dados2) {
                        let o2 = { 
                            dataField: colunas2,
                            text: colunas2, 
                        };
                        where2.push(o2);
                    }
                    setColumns2(where2);
                    setProducts2(json2);
                    setLista2(true);
                }else{
                    setLista2(false);
                    setProducts2(null);
                }


            });
            
        }
        carrega_placas();
        if(id.new === 1){
            add_agenda(id, id.idAgenda)
            id.new = 0;
        }
      }, [contrato, empresa, filial, id, altera]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
   
    function get_func(){
        var mat_mot = document.getElementById('mat_incluir').value;
        if(mat_mot !== "" && mat_mot !== "0"){
            api.get('/funcionarios/' + mat_mot,
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                        } 
                    }
                )
            .then(function (response) {
                var {NOMEFUNC} = response.data;
                document.getElementById('nome_incluir').value = NOMEFUNC;
            });
        }else{
            document.getElementById('nome_incluir').value = "";
        }
    }
    const [modalShow, setModalShow] = useState({
        status: false,
        id: 0,
    });
    function add_quiz(id){
        setModalShow({
            status: true,
            id: id.id,
        });
    }

   
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open={open} open4={open4}>Treinamento</DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Grid container spacing={2} >
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="titulo_incluir">Titulo</InputLabel>
                               <TextField
                                    size="small"
                                    id="titulo_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={9}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="objetivo_incluir">Objetivo</InputLabel>
                               <TextField
                                    size="small"
                                    id="objetivo_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="area_inclui">Área</InputLabel>
                                <Select
                                native
                                size="small"
                                id="area_inclui"
                                className={classes.textField}
                                disabled
                                >
                                    {areas.map((dados) => (
                                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                                    ))}
                                    
                                </Select>
                            </Form.Group>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="cliente_inclui">Cliente</InputLabel>
                                <Select
                                native
                                size="small"
                                id="cliente_inclui"
                                className={classes.textField}
                                disabled
                                >
                                    <option value={''}>Selecione...</option>
                                    {clientes.map((dados) => (
                                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                                    ))}
                                </Select>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="data">Data</InputLabel>
                               <TextField
                                    type="text"
                                    className={classes.textField}
                                    locale='pt-PT' 
                                    id="data" 
                                    inputProps={{
                                        name: 'data',
                                        id: 'data',
                                    }}
                                    placeholder='dd/mm/aaaa'
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="hora_incluir">Carga Horária (min)</InputLabel>
                               <TextField
                                    size="small"
                                    id="hora_incluir"
                                    type="number"
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <AvTimerIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="tipo_inclui">Tipo</InputLabel>
                                <Select
                                native
                                size="small"
                                id="tipo_inclui"
                                className={classes.textField}
                                disabled
                                >
                                    <option value="0">Presencial</option>
                                    <option value="1">Online</option>
                                </Select>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={1}>
                            <Form.Group> 
                                <InputLabel className={classes.linha}  htmlFor="mat_incluir">Instrutor</InputLabel>
                               <TextField
                                    size="small"
                                    id="mat_incluir"
                                    type="number"
                                    className={classes.textField}
                                    onChange={() => get_func()}
                                    onBlur={() => get_func()}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        { /*<Grid item xs={1}>
                            <FontAwesomeIcon icon={faBinoculars} size="2x"/>
                        </Grid>*/ }
                        <Grid item xs={6}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="nome_incluir">Nome</InputLabel>
                               <TextField
                                    size="small"
                                    id="nome_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Header></Header>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={5}>
                        </Grid>
                        <Grid item xs={2} className={classes.center}>
                            Quiz
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip title="Incluir Pergunta">
                                <Button
                                    color="primary" 
                                    size="small"
                                    disableElevation
                                    startIcon={<AddCircleIcon fontSize="medium" />}
                                    onClick={() => add_quiz(id)}
                                >
                                    Incluir
                                </Button>
                            
                            </Tooltip>
                            
                        </Grid>
                    </Grid>
                    {lista2 && (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns2.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (
                                                <Table.ColHeader key={dados.text}>
                                                    <div className={classes.linha2}>
                                                        {dados.text}
                                                    </div>
                                                </Table.ColHeader>
                                            );
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products2.map((dados, index) => (
                                    <TableRowQuiz key={dados.id + "linha2"} 
                                        dados={dados} 
                                        columns={columns2}
                                        empresa={empresa}
                                        contrato={contrato}
                                        filial={filial}
                                        setAltera={setAltera}
                                        altera={altera}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                    <Header></Header>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={5}>
                        </Grid>
                        <Grid item xs={2} className={classes.center}>
                            Agendamentos
                            
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                        {online ? (
                            <Tooltip title="Incluir Agendamento">
                                <Button
                                    color="primary" 
                                    size="small"
                                    disableElevation
                                    startIcon={<AddCircleIcon fontSize="medium" />}
                                    onClick={() => add_agenda(id, 0)}
                                >
                                    Incluir
                                </Button>
                            </Tooltip>
                        ): null}
                        </Grid>
                    </Grid>
                    {lista && (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id" && dados.text !== "online"){
                                            return (
                                                <Table.ColHeader key={dados.text}>
                                                    <div className={classes.linha2}>
                                                        {dados.text}
                                                    </div>
                                                </Table.ColHeader>
                                            );
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <TableRowAgenda key={"linha" + dados.id} 
                                        dados={dados} 
                                        columns={columns}
                                        empresa={empresa}
                                        contrato={contrato}
                                        filial={filial}
                                        setAltera={setAltera}
                                        altera={altera}
                                        setOnline={setOnline}
                                        id_agenda={id}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </>
            )}
        </DialogContent>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        {modalShow.status ? (
            <ModalAddQuiz
            open={modalShow.status}
            onClose={() => setModalShow({
                'status': false,
                'id': 0
            })}
            maxWidth="sm"
            fullWidth={true}
            idTreina={modalShow.id}
            setAltera={setAltera}
            altera={altera}
            />
        ) : null}
        {modalShowAgenda.status ? (
            <ModalAddAgenda
            open={modalShowAgenda.status}
            onClose={() => setModalShowAgenda({
                'status': false,
                'id': 0
            })}
            empresa={empresa}
            contrato={contrato}
            filial={filial}
            maxWidth="md"
            fullWidth={true}
            idTreina={modalShowAgenda.idTreina}
            setAltera={setAltera}
            altera={altera}
            id={modalShowAgenda.id}
            />
        ) : null}

        </Dialog>
  );
}

export default ModalDetals;