import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from "./services/auth";

import Login from './pages/Login';
//import Plano from './pages/Plano';
import Privacy from './pages/Privacy';
import Os from './pages/Os/index';
import Treinamentos from './pages/Treinamentos/index';
import Home from './pages/Home/index';
import CheckList from './pages/CheckList/index';
import Socorro from './pages/Socorro/index';
import Abastecimento from './pages/Abastecimento/index';
import Avisos from './pages/Avisos/index';
import PreContra from './pages/PreContra/index';
import Cadastros from './pages/Cadastro/index'
import Calibragem from './pages/Calibragem/index';
import Faturamento from './pages/Faturamento';
import AtualizarOS from './pages/AtualizarOs';
import Absenteismo from './pages/Absenteismo';
import Erro404 from './pages/Erro404';
import Psicologico from './pages/Psicologico';
import Criticidade from './pages/Criticidade';
import SemAcesso from './pages/SemAcesso';
import Skap from './pages/Skap';

//<PrivateRoute path="/Plano" component={Plano}/>

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/privacy" component={Privacy}/>
                <PrivateRoute path="/os" component={Os}/>
                <PrivateRoute path="/treinamentos" component={Treinamentos}/>
                <PrivateRoute path="/pneus" component={Calibragem}/>
                <PrivateRoute path="/home" component={Home}/>
                <PrivateRoute path="/checklist" component={CheckList}/>
                <PrivateRoute path="/socorro" component={Socorro}/>
                <PrivateRoute path="/abastecimento" component={Abastecimento}/>
                <PrivateRoute path="/aviso" component={Avisos}/>
                <PrivateRoute path="/precontra" component={PreContra}/>
                <PrivateRoute path="/registration" component={Cadastros}/>
                <PrivateRoute path="/faturamento" component={Faturamento}/>
                <PrivateRoute path="/atualizaros" component={AtualizarOS}/>
                <PrivateRoute path="/absenteismo" component={Absenteismo}/>
                <PrivateRoute path="/psicologico" component={Psicologico}/>
                <PrivateRoute path="/criticidade" component={Criticidade}/>
                <PrivateRoute path="/skap" component={Skap}/>
                <PrivateRoute path="/erro" component={SemAcesso}/>
                
                <Route path="*" component={Erro404} />
            </Switch>
        </BrowserRouter>
    );
}