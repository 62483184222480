import { Box, CircularProgress, Grid, IconButton, InputLabel, Snackbar, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';

import { Logo2 } from '../../styles';
import { Progress } from '../../../BoardSocorro/styles'
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import ProgressBar from 'react-customizable-progressbar'

import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from '../../../Styles';
import logo_icon from '../../../../img/icon.png';
import api from '../../../../services/api';
import { getUser } from '../../../../services/auth';
import { IconContext } from 'react-icons';
import { FaStar } from 'react-icons/fa';

function Tab2({empresas, contratos, filiais, opes}) {
    const classes = Styles();
    const [open, setOpen] = useState(false);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState([]);
    const [lista, setLista] = useState(false);
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [exportCsv, setExportCsv] = useState([]);
    const [percent, setPercent] = useState({media: 0, percentual: 0});
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const [operacoes, setOperacoes] = useState(opes);
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
        var empresa = document.getElementById('nps_empresa').value;
        var contrato = document.getElementById('nps_contrato').value;
        var filial = document.getElementById('nps_filial').value;
        
        if(name === "empresa"){
            filter2(setOperacoes, event.target.value, contrato, filial);
        }
        if(name === "contrato"){
            filter2(setOperacoes, empresa, event.target.value, filial);
        }
        if(name === "filial"){
            filter2(setOperacoes, empresa, contrato, event.target.value);
        }
        setLista(false);
    };
    function filter2(local, val, val2, val3){
        let data = opes;
        // eslint-disable-next-line eqeqeq
        let data_filter = data.filter( element => element['empresa'] == val)
         // eslint-disable-next-line eqeqeq
        data_filter = data_filter.filter( element => element['contrato'] == val2)
         // eslint-disable-next-line eqeqeq
        data_filter = data_filter.filter( element => element['filial'] == val3)
        local(data_filter);
    }

    useEffect (() => {
        filter2(setOperacoes, empresas[0].id, contratos[0].id, filiais[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contratos, empresas, filiais]);
    const handleChange1 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange2 = (event) => {
        const value = event.target.value;
        setDatefin(value);
        
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    function consultar_nps(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        let ope = document.getElementById('nps_ope').value;
        var {empresa, contrato, filial} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/manutencao/nps/all',
            { 
                headers: { 
                        empresa,
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        ope,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let { data , media, percentual } = response.data
            if(data !== 'null' && data !== ''){
                setPercent({ media, percentual});
                let dataJson = JSON.stringify(data);
                dataJson = JSON.parse(dataJson);
                let where2 = [];
                var dados = dataJson[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(dataJson);
                
                var export_temp = dataJson.map((column)  => {
                    delete column.id;
                    delete column['Img Saida'];
                    delete column['Img Retorno'];
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }
    return (
        <div>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'nps_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'nps_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'nps_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="nps_ope">Operação</InputLabel>
                        <Select
                        native
                        value={state.ope}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'nps_ope',
                        }}
                        >
                            {operacoes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="nps_dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="nps_dateini" 
                            value={dateini}
                            onChange={handleChange1}
                            inputProps={{
                                name: 'dataini',
                                id: 'nps_dateini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                        
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="nps_datafim">Data final</InputLabel>
                        <TextField
                            type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="nps_datafim" 
                            value={datefin}
                            onChange={handleChange2}
                            inputProps={{
                                name: 'datafim',
                                id: 'nps_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_nps()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"check_relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                </Grid>
            </Form>
            {lista && (
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <Progress>
                            <ProgressBar
                                radius={70}
                                progress={percent.percentual}
                                strokeWidth={10}
                                strokeColor="#ffce54"
                                strokeLinecap="butt"
                                trackStrokeWidth={8}
                                trackStrokeLinecap="butt"
                                initialAnimation
                                rotate={-210}
                            >
                            <div className={classes.indicator2}>
                                NPS
                            </div>
                            <div className={classes.indicator}>
                                {percent.media}
                                <IconContext.Provider value={{ size: "1.1em"}}>
                                    <FaStar style={{marginTop: 4}} />
                                </IconContext.Provider>
                            </div>
                            </ProgressBar>
                        </Progress>
                    </Grid>
                </Grid>
            )}
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader className={classes.cabecario} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={index + "row"}>
                                            {columns.map((column, index2) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    if(name === "nps"){
                                                        return (
                                                            <Table.Col className={classes.linha} key={dados.id + name}>
                                                                {dados[name]}
                                                                <IconContext.Provider value={{ size: "1.1em"}}>
                                                                    <FaStar  style={{paddinTop: 4}} />
                                                                </IconContext.Provider>
                                                            </Table.Col>
                                                        ); 
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + name}>{dados[name]}</Table.Col>);
                                                    }
                                                }else{
                                                    return false;
                                                }
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Tab2;