import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'

import Styles from '../../Styles';

import DialogTitle from '../Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEditDocument(props) {
    const classes = Styles();
    var {id, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [form, setForn] = useState({
        error: false,
        helperText: '',
    })
    useEffect (() => {
        async function carrega_dados(){
            setOpen(true);
            await api.get('/checklist/documento/' + id,
                { 
                    headers: { 

                            user: getUser(),
                            tipo: 1,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let {data} = response.data
                document.getElementById('placa_doc').value = data.veiculo.placa;
                document.getElementById('prefixo_doc').value = data.veiculo.prefixo;
                document.getElementById('documemt_tipo').value = data.tipo.nome;
                document.getElementById('data_doc').value = data.data;
            });
        }
        carrega_dados();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salvar(){
        var change = document.getElementById('data_doc').value;
        setForn({
            errorData: false,
            helperTextData: '',
        })
       
        if(change === ""){
            setForn({
                helperText: 'Digite a data.',
                error: true
            })
            return false;
        }
        setOpen(true);
        api.get('/checklist/documento/'+id+'/edit',
        { 
            headers: { 
                user: getUser(),
                data: change,
            } 
        })
        .then(function(response){
            let {status, message} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Atualizado com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
            setOpen(false);
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Editar Documento</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="prefixo_doc">Prefixo</InputLabel>
                               <TextField
                                    size="small"
                                    id="prefixo_doc"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="placa_doc">Placa</InputLabel>
                               <TextField
                                    size="small"
                                    id="placa_doc"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="documemt_tipo">Documento</InputLabel>
                               <TextField
                                    size="small"
                                    id="documemt_tipo"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="data_doc">Data</InputLabel>
                               <TextField
                                type="date"
                                size="small"
                                error={form.error}
                                helperText={form.helperText}
                                    id="data_doc"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary"  onClick={onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => {salvar()}}>Salvar</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEditDocument;