import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput2 from "../../../../functions/CurrencyInput2"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../components/Styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'

import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput2
        {...other}
        
      />
    );
}

function ModalEditCalibragem(props) {
    const classes = Styles();
    var {id, onClose, consulta, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [save, setSave] = useState(false);
    const [formM1, setM1] = useState({
        error: false,
        helperText: '',
    })
    const [formM2, setM2] = useState({
        error: false,
        helperText: '',
    })
    const [posicoes, setPosicoes] = useState([]);

    useEffect (() => {
        async function carrega(){
            setOpen(true);
            await api.get('/calibragem/' + id,
                { 
                    headers: { 
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                console.log(response.data)
                let { status } = response.data;
                if(status === true){
                    let { data, carros, dados } = response.data.data;
                    setPosicoes(dados);
                    data = data.split("/").reverse().join("-");
                    document.getElementById('data_edit').value = data;
                    document.getElementById('placa_edit').value = carros.placa;
                    // document.getElementById('posicao_edit').value = posicao;
                    // document.getElementById('m1_edit').value = valor1;
                    // document.getElementById('m2_edit').value = valor2;
                    // document.getElementById('m3_edit').value = valor3;
                    // document.getElementById('m4_edit').value = valor4;
                }else{
                    let { message } = response.data;
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
            });
        }
        carrega();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    
    function salvar(){
        let data_edit = document.getElementById('data_edit').value;
        if(data_edit == null || data_edit == ''){
            setOpen3({
                show: true,
                text: "Preencha a data!",
                type: "error"
            });
            return false;
        }
        let dados = [];
        let ver = true;
        posicoes.forEach(element => {
            let calib = document.getElementById("calibragem_" + element.id).value;
            let id = element.id;
            if(calib == "" || calib == 0){
                setOpen3({
                    show: true,
                    text: "Preencha a calibragem!",
                    type: "error"
                });
                ver = false;
                return false;
            }
            dados.push({
                calib,
                id
            })
        });
        if(ver){
            setSave(true)
            api.get('/calibragem/'+id+'/edit',
            { 
                params: { 
                    user: getUser(),
                    data: data_edit,
                    dados
                } 
            })
            .then(function(response){
                setSave(false)
                var {status, erro} = response.data;
                if(status === true){
                    setOpen3({
                        show: true,
                        text: 'Alteração salva com sucesso!',
                        type: "success"
                    });
                    onClose();
                    consulta();
                }else{
                    setOpen3({
                        show: true,
                        text: erro,
                        type: "error"
                    });
                }
            }).catch(function(error){
                setSave(false);
                setOpen3({
                    show: true,
                    text:  "Ocorreu um erro!",
                    type: "error"
                });
            })
        }
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Alterar Calibragem</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={3}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="placa_edit">Placa</InputLabel>
                                <TextField
                                    size="small"
                                    id="placa_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="data_edit">Data</InputLabel>
                                <TextField
                                    size="small"
                                    id="data_edit"
                                    type="date"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        {posicoes.map((element, index) => (
                            <Grid item xs={2} key={index}>
                                <Form.Group> 
                                    <InputLabel className={classes.linha} htmlFor={"calibragem_" + element.id}>{element.posicao}</InputLabel>
                                    <TextField
                                        size="small"
                                        id={"calibragem_" + element.id}
                                        type="number"
                                        defaultValue={element.valor}
                                        className={classes.textField}
                                    />
                                </Form.Group>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={save} onClick={onClose}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button color="primary" disabled={save} onClick={() => {salvar()}} variant="contained">Salvar</Button>
                    {save && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEditCalibragem;