import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 63;

const Styles = makeStyles((theme) => ({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    linha: {
        fontSize: "12px !important",
    },
    textField: {
        width: "100%",
        fontSize: "12px !important",
        minHeight: "0px !important",
        padding: 0,
    },
    textFieldDate: {
        border: "none",
        borderBottom: "0.6px solid #000",
        borderRadius: 0,
        padding: "2px 10px",
        "&:hover": {
            borderBottom: "2px solid #000",
        },
        "&:focus": {
            border: "none",
            borderBottom: "2px solid #3f51b5",
            boxShadow: "none"
        },
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    root2: {
        flexGrow: 1,
        overflowY: "initial",
        backgroundColor: '#f5f5f5',
    },
    disabled: {
        backgroundColor: '#F4F4F4',
        width: "100%",
        fontSize: "12px !important",
        minHeight: "0px !important",
        padding: 0,
    },
    enable: {
        backgroundColor: '#FFFFFF',
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    image: {
        width: 200,
        height: 200,
    },
    image2: {
        width: 200,
        height: 250,
    },
    image_alt_item: {
        marginTop: -30,
        position: "absolute",
        height: 30,
        width: 200,
        backgroundColor: 'rgba(256, 256, 256, 0.5)',
        paddingLeft: 57
    },
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 300,
        },
    },
    cabecario: {
        fontSize: 12 + "px !important"
    },
    button: {
        minWidth: 100,
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        userSelect: "none",
        top:0,
        marginTop: 0,
        paddingTop: "40%",
        fontSize: 22,
    },
    indicator2: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        userSelect: "none",
        top:0,
        marginTop: "-50px",
        fontSize: 22,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        marginLeft: drawerWidth,
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    label: {
        fontSize: 12,
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    th: {
        textAlign: "center",
    }
}));

export default Styles;