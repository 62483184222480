import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputLabel, Snackbar, TextField, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';

import { Logo2 } from '../styles';
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo_icon from '../../../../img/icon.png';
import api from '../../../../services/api';
import { getUser } from '../../../../services/auth';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ModalAdd from '../ModalAdd';
import Styles from '../../../../components/Styles';
import { Check, DateRangeRounded } from '@material-ui/icons';
import ModalVencimento from '../ModalVencimento';
import { BiTrash } from 'react-icons/bi';

function Tab1({empresas, contratos, filiais, type}) {
    const classes = Styles();
    const [open, setOpen] = useState(false);
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState([]);
    const [lista, setLista] = useState(false);
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [id_temp, setId_temp] = useState(0);
    const [ modalVencimento, showModalVencimento] = useState({
        show: false,
        id: 0
    });
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const handleChange1 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange2 = (event) => {
        const value = event.target.value;
        setDatefin(value);
        
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning',
        time: 6000
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning',
            time: 6000
        });
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    function consultar(){
        for(let dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning',
                    time: 6000
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning',
                time: 6000
            });
            return false
        }
        handleToggle();
        let {empresa, contrato, filial} = state;
        let dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        let datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/financeiro/faturamento',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            try{
                let {data} = response.data;
                let json = data;
                data = JSON.stringify(json);
                data = JSON.parse(data);
                if(json !== null && json !== ''  && json !== []){
                    let where2 = [];
                    json.forEach(dados => {
                        for(let colunas in dados) {
                            let o2 = { 
                                dataField: colunas,
                                text: colunas, 
                            };
                            where2.push(o2);
                        }
                    });
                    let uniques = [];
                    let itemsFound = {};
                    for(let i = 0, l = where2.length; i < l; i++) {
                        let stringified = JSON.stringify(where2[i]);
                        if(itemsFound[stringified]) { continue; }
                        uniques.push(where2[i]);
                        itemsFound[stringified] = true;
                    }
                    
                    setColumns(uniques);
                    setProducts(json);
                    
                    let export_temp = data.map((column)  => {
                        delete column.id;
                        let name = column.text;
                        if(name === "status"){

                        }
                        return column;
                    })
                    setExportCsv(export_temp);
                    setLista(true);
                }else{
                    setLista(false);
                    setProducts([]);
                }
                handleClose();
            }catch(erro){
                handleClose();
                setOpen3({
                    show: true,
                    text: 'Ocorreu um erro!',
                    type: 'error',
                    time: 1000000
                });
            }
        }).catch((error) => {
            handleClose();
            setOpen3({
                show: true,
                text: 'Ocorreu um erro!',
                type: 'error',
                time: 1000000
            });
        });
    }
    const handleClickOpen4 = (id) => {
        setOpen4(true);
        setId_temp(id);
    };
    const handleClose4 = () => {
        setOpen4(false);
        setId_temp(0);
    };
    const baixarDoc = () => {
        if(id_temp !== 0){
            setOpen4(false);
            handleToggle();
            api.delete('/financeiro/faturamento/' + id_temp)
            .then(function (response) {
                const { status, message } = response.data;
                if(status === true){
                    consultar()
                }else{
                    setOpen3({
                        show: true,
                        text: message,
                        type: 'error'
                    });
                }
            }).catch((error) => {
                setOpen3({
                    show: true,
                    text: "Ocorreu um erro!",
                    type: 'error'
                });
            })
        }
    }
    return (
        <div>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'nps_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'nps_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha}  htmlFor="nps_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'nps_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="nps_dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="nps_dateini" 
                            value={dateini}
                            onChange={handleChange1}
                            inputProps={{
                                name: 'dataini',
                                id: 'nps_dateini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                        
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="nps_datafim">Data final</InputLabel>
                        <TextField
                            type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="nps_datafim" 
                            value={datefin}
                            onChange={handleChange2}
                            inputProps={{
                                name: 'datafim',
                                id: 'nps_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"check_relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid>  
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Importar faturamento">
                                <IconButton  aria-label="Importar faturamento" onClick={() => setModalShow(true)} variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader className={classes.cabecario} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }else{
                                                return false;
                                            }
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                let name = column.text;
                                                if(name !== "id"){
                                                    switch (name) {
                                                        case 'Data doc':
                                                            return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Venc Líquid':
                                                            return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Compensaç.':
                                                            return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                {dados[name] != null && dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Dt. lçto.':
                                                            return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                {dados[name].split("-").reverse().join("/")}
                                                            </Table.Col>);
                                                        case 'Mont. em MI':
                                                            return (<Table.Col className={classes.linha} key={index+ column.text}>
                                                                {((dados[name])/1).toLocaleString('pt-br',{   
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                            </Table.Col>);
                                                        case 'Vencimento Ambev':
                                                            if(dados[name] == null){
                                                                return (
                                                                    <Table.Col className={classes.linha} key={index + column.text}>
                                                                        <Tooltip title="Vencimento">
                                                                            <IconButton  size="small" color="default" onClick={() => showModalVencimento({show: true, id: dados.Doc})}  aria-label="Vencimento" variant="contained">
                                                                                <DateRangeRounded fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Table.Col>
                                                                )
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                    {dados[name]}
                                                                </Table.Col>);
                                                            }
                                                        case 'baixar':
                                                            if(dados[name] === true){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                   <Tooltip title="Baixar">
                                                                        <IconButton  size="small" onClick={() => handleClickOpen4(dados.id)} color="default" aria-label="Baixar" variant="contained">
                                                                            <Check color='blue' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                 </Table.Col>);
                                                            }
                                                        default:
                                                            return (<Table.Col className={classes.linha} key={index + column.text}>
                                                                {dados[name]}
                                                            </Table.Col>);
                                                    }
                                                }
                                                return false;
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
            {modalShow && (
                <ModalAdd
                    open={modalShow}
                    onClose={() => setModalShow(false)}
                    maxWidth="sm"
                    fullWidth={true}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                />
            )}
            {modalVencimento.show && (
                <ModalVencimento
                    open={modalVencimento.show}
                    onClose={() => showModalVencimento({
                        show: false,
                        id: 0
                    })}
                    fullWidth={true}
                    modalVencimento={modalVencimento}
                    empresa={state.empresa}
                    contrato={state.contrato}
                    filial={state.filial}
                    consultar={consultar}
                />
            )}
            <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Deseja baixar o documento?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Essa operação não pode ser desfeita!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose4} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={() => baixarDoc()} variant="contained" color="primary" autoFocus>
                    Baixar
                </Button>
            </DialogActions>
        </Dialog>
            <Snackbar open={open3.show} autoHideDuration={open3.time} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Tab1;