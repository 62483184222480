import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Form } from 'react-bootstrap';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import api from '../../../../services/api'
import Styles from '../../../Styles';


import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";
  
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalObs(props) {
    const classes = Styles();
    var {id, empresa, contrato, filial, onClose, opcao, funcao, ...rest} = props;
    const [open, setOpen] = useState(false);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };


    function salvar_cad_obs_item_check(){
        var descr = document.getElementById('item_obs').value
        
        // eslint-disable-next-line eqeqeq
        if(descr == ""){
            setOpen3({
                show: true,
                text: 'Descrição não pode ser vazia!',
                type: 'error'
            });
        }else{
            setOpen(true);
            api.post('http://www.cilog.com.br/backend/public/itensobs',
            { 
                empresa,
                id_ver: id, 
                id_ver_obs: opcao.id,
                contrato, 
                filial,
                descricao: descr,
                user: getUser(),
            })
            .then(function(response){
                setOpen(false);
                onClose();
                let { status, data} = response.data;
                if(status){
                    funcao(data);
                }
            }).catch(function(error){
                setOpen(false);
                console.log(error)
            });
        }
    }
    
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Observação Rapida {opcao.id}</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form.Group > 
                                <InputLabel htmlFor="item_obs">Texto</InputLabel>
                               <TextField
                                    size="small"
                                    id="item_obs"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>Cancelar</Button>
                <Button color="primary" variant="contained" onClick={salvar_cad_obs_item_check}>Enviar</Button>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalObs;