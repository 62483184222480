import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Form } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import logo_icon from '../../../../img/icon.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { CSVLink } from "react-csv";

import { makeStyles } from '@material-ui/core/styles';
import { Container2, Logo2,  Header } from './styles';
import { getUser} from "../../../../services/auth";
import api from '../../../../services/api'
import ModalAdd from '../ModalAdd';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    botao: {
        padding: 0,
    }
}));
function Body({empresas, contratos, filiais}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [columns, setColumns] = useState(null);
    const [modalAdd, setModalAdd] = useState(false);
    const [lista, setLista] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        
    };
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
        
    };
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const [products, setProducts] = useState(null);
    function consultar_abastecimento(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        handleToggle();
        var {empresa, contrato, filial} = state;
        api.get('/avisos',
            { 
                headers: { 
                        empresa, 
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                var dados = json[0];
                for(var colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                setColumns(where2);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }
    const confirmaExcluir = (id) => {
        api.delete('/avisos/' + id,
        { 
            user: getUser(),
            
        })
        .then(function(response){
            var {status, message} = response.data;
            if(status){
                setOpen3({
                    show: true,
                    text: message,
                    type: "success"
                });
                consultar_abastecimento();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
        })
    }
  return (
    <Container2>
    <Header>
        <Grid container spacing={3}>
            <Grid item xs="auto">
                Avisos
            </Grid>
            <Grid item xs="auto">
                <Tooltip title="Incluir Treinamento">
                    <Button
                        variant="contained" 
                        color="primary" 
                        disableElevation
                        size="small"
                        startIcon={<AddCircleIcon fontSize="inherit" />}
                        onClick={() => setModalAdd(true)}
                    >
                        Novo
                    </Button>
                    
                </Tooltip>
            </Grid>
        </Grid>
    </Header>
    <Tabs
    value={value}
    onChange={handleChange}
    variant="scrollable"
    scrollButtons="on"
    indicatorColor="primary"
    textColor="primary"
    aria-label="scrollable force tabs example"
    >
        <Tab label="Relatorio"  {...a11yProps(0)} />
    </Tabs>
    <TabPanel value={value} index={0}>
    <Form>
        <Grid container spacing={3}>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                <Select
                native
                value={state.empresa}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'empresa',
                    id: 'abast_empresa',
                }}
                >
                    {empresas.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                    
                </Select>
            </Grid>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                <Select
                native
                value={state.contrato}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'contrato',
                    id: 'abast_contrato',
                }}
                >
                    {contratos.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                </Select>
            </Grid>
            <Grid item xs="auto">
                <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                <Select
                native
                value={state.filial}
                onChange={handleChange2}
                className={classes.textField}
                inputProps={{
                    name: 'filial',
                    id: 'abast_filial',
                }}
                >
                    {filiais.map((dados, index) => (
                        <option key={dados.id} value={dados.id}>{dados.descr}</option>
                    ))}
                </Select>
            </Grid>
            
            <Grid item xs="auto">
                <Tooltip title="Consultar">
                    <IconButton aria-label="Consultar" onClick={() => consultar_abastecimento()} color="primary" variant="contained">
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs="auto">
                <CSVLink data={exportCsv} filename={"Avisos.csv"}>     
                    <Tooltip title="Exportar csv">
                        <IconButton aria-label="Exportar csv" variant="contained">
                            <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                        </IconButton>
                    </Tooltip>
                </CSVLink>
            </Grid> 
        </Grid>
    </Form>
    <Box component="span" m={1}>
        {open ? (
            <Logo2>
                <img src={logo_icon} alt="" />
                <CircularProgress />
            </Logo2>
        ) : (
            <>
                {lista && (
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id + dados.Data}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>); 
                                            }
                                            return false;
                                        })}
                                        <Table.Col className={classes.linha} key={dados.index}>
                                            <IconButton  
                                                style={{color: "#EE4B2B"}} 
                                                size="small" 
                                                onClick={() => confirmaExcluir(dados.id)}
                                                aria-label="Excluir">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Table.Col>
                                    </Table.Row>
                                ))}
                                    
                            </Table.Body>
                        </Table>
                    </>
                )}
            </>
        )}
        </Box>
    </TabPanel>
    {modalAdd ? (
        <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        maxWidth="md"
        fullWidth={true}
        empresa={state.empresa}
        contrato={state.contrato}
        filial={state.filial}
        />
    ):null}
    <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity={open3.type}>
            {open3.text}
        </Alert>
    </Snackbar>
</Container2>
  );
}

export default Body;