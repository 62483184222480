import React from 'react';

import { MdPriorityHigh } from 'react-icons/md'

import { Container, Colnome, Coldata } from './styles';

export default function Coment(data) {
    
  return (  
    <>
        {data.data.sistema === 1 ? (
            <Container sistema={data.data.sistema} user={data.data.user}>
                <div className="col1">
                    <MdPriorityHigh size={10} color="#999" /> 
                </div>
                <div className="col2">
                    {data.data.comentario} por {data.data.Nome}
                </div>
                <div className="col3">
                    {data.data.date_time}
                </div>
            </Container>
        ) : (
            <>
                {data.data.user ? (
                    <>
                        <Container sistema={data.data.sistema} user={data.data.user}>
                            <div className="colcoment2">
                                {data.data.comentario}
                            </div>
                        </Container>
                        <Coldata user={data.data.user}>
                            {data.data.lido === 1 ? (
                                    <>Visualizado - </>
                            ) : (
                                null
                            )}
                                {data.data.date_time}
                        </Coldata>
                    </>
                ):(
                    <>
                        <Colnome>
                            {data.data.Nome}
                        </Colnome>
                        <Container sistema={data.data.sistema} user={data.data.user}>
                            <div className="colcoment">
                                {data.data.comentario}
                            </div>
                        </Container>
                        <Coldata user={data.data.user}>
                            {data.data.date_time}
                        </Coldata>
                    </>
                )}
            </>
        )}
    </>
  );
}

