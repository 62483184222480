import React, {useState} from 'react';
import { Container2, Logo2,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Styles from '../../../components/Styles';
import { getUser, getMat} from "../../../services/auth";
import { TextField } from '@material-ui/core';
import { Form } from 'react-bootstrap';

import logo_icon from '../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react"; 
import { CSVLink } from "react-csv";
import ModalEdit from './ModalEdit';

function Alert(props) {
    return <MuiAlert elevation={6} letiant="filled" {...props} />;
}
function Body({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [columns2, setColumns2] = useState(null);
    const [products2, setProducts2] = useState(null);
    const [lista2, setLista2] = useState(false);
    const [exportCsv2, setExportCsv2] = useState([]);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [dateini2, setDateini2] = useState("");
    const [datefin2, setDatefin2] = useState("");
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const handleChange5 = (event) => {
        const value = event.target.value;
        setDateini2(value);
    };
    const handleChange6 = (event) => {
        const value = event.target.value;
        setDatefin2(value);
    };
    const handleChange3 = (event) => {
        const value = event.target.value;
        setDateini(value);
    };
    const handleChange4 = (event) => {
        const value = event.target.value;
        setDatefin(value);
    };
    function consultar(){
        for(let dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        let {empresa, contrato, filial} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/psicologica',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let {data} = response.data;
            let json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                let uniques = [];
                let itemsFound = {};
                for(let i = 0, l = where2.length; i < l; i++) {
                    let stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns(uniques);
                setProducts(json);
                
                let export_temp = data.map((column)  => {
                    delete column.id;
                    let name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }
    function consultarAgendamento(){
        for(let dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini2 === "" || datefin2 === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        let {empresa, contrato, filial} = state;
        var dataini = dateini2;
        dataini = dataini.split("-").reverse().join("/");
        var datafim = datefin2;
        datafim = datafim.split("-").reverse().join("/");
        api.get('/psicologica/agendamento',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafim,
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let {data} = response.data;
            let json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                let uniques = [];
                let itemsFound = {};
                for(let i = 0, l = where2.length; i < l; i++) {
                    let stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns2(uniques);
                setProducts2(json);
                
                let export_temp = data.map((column)  => {
                    delete column.id;
                    let name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv2(export_temp);
                setLista2(true);
            }else{
                setLista2(false);
                setProducts2(null);
            }
            handleClose();
        });
    }


    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const [validar, setValidar] = useState({
        show: false,
        id: 0,
    });
    return (
    <Container2>
        <Header>
            Segurança Psicologico
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        letiant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Agendamento"  {...a11yProps(0)} />
            <Tab label="Queixa/Relato"  {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini2}
                            onChange={handleChange5}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin2}
                            onChange={handleChange6}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultarAgendamento()} color="primary" letiant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv2} filename={"agendamento.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" letiant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista2 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns2.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                if(dados.text !== "visualizado"){
                                                    if(dados.text !== "idPsicologo"){
                                                        return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                                    }
                                                }
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products2.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns2.map((column, index) => {
                                                let name = column.text;
                                                if(name !== "id"){
                                                    if(name !== "visualizado"){
                                                        if(name !== "idPsicologo"){
                                                            if(name === "Status"){
                                                                if(dados[name] === 'Agendado' || dados[name] === 'Atendido'){
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                        <Badge color="success" className="mr-1">
                                                                            {dados[name]}
                                                                        </Badge>
                                                                    </Table.Col>);
                                                                }else{
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                        <Badge color="warning" className="mr-1">
                                                                            {dados[name]}
                                                                        </Badge>
                                                                    </Table.Col>);
                                                                }
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                        
                                                            }
                                                        }
                                                    }
                                                }
                                                return false;
                                            })}
                                            {// eslint-disable-next-line eqeqeq
                                                dados['Status'] !== 'Atendido' && (
                                                    getMat() == dados.idPsicologo && (
                                                        <Table.Col className={classes.linha} key={index}>
                                                            <Tooltip title="Agendar">
                                                                <IconButton  
                                                                    style={{backgroundColor: 'var(--warning)'}} 
                                                                    size="small" 
                                                                    onClick={() => setValidar({
                                                                        show: true,
                                                                        id: dados.id,
                                                                        data: dados['Data Solicitacao'],
                                                                        data_agendamento: dados['Data Agendamento'],
                                                                        mat: dados['Mat'],
                                                                        nome: dados['Funcionario'],
                                                                        psicologo: dados['Psicologo'],
                                                                        status: dados['Status'],
                                                                    })}
                                                                    aria-label="Agendar" 
                                                                    variant="warning">
                                                                    <PlaylistAddCheckIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Table.Col>
                                                    )
                                                )
                                            }
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dateini" 
                            value={dateini}
                            onChange={handleChange3}
                            inputProps={{
                                name: 'dataini',
                                id: 'abast_dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datefim" 
                            value={datefin}
                            onChange={handleChange4}
                            inputProps={{
                                name: 'datafim',
                                id: 'abast_datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar()} color="primary" letiant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" letiant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <>
                        {lista && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                let name = column.text;
                                                if(name !== "id"){
                                                    if(name === "Pagamento"){
                                                        if(dados[name] === 'Pago'){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <Badge color="success" className="mr-1">
                                                                    {dados[name]}
                                                                </Badge>
                                                            </Table.Col>);
                                                        }else{
                                                            if(dados[name] === 'Lançado'){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <Badge color="warning" className="mr-1">
                                                                        {dados[name]}
                                                                    </Badge>
                                                                </Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name]}
                                                                </Table.Col>);
                                                            }
                                                        }
                                                    }else{
                                                        if(name === "Emitido"){
                                                            if(dados[name] === 'Sim'){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <Badge color="success" className="mr-1">
                                                                        {dados[name]}
                                                                    </Badge>
                                                                </Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name]}
                                                                </Table.Col>);
                                                            }
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                        }
                                                    }
                                                }
                                                return false;
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </>
                )}
            </Box>
        </TabPanel>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        {validar.show && (
            <ModalEdit
                open={validar.show}
                onClose={() => setValidar({
                    show: false,
                    id: 0,
                    data: '',
                    data_agendamento: '',
                    mat: '',
                    nome: '',
                    psicologo: '',
                    status: '',
                })}
                consulta={consultarAgendamento}
                maxWidth="lg"
                fullWidth={true}
                id={validar.id}
                data={validar.data}
                data_agendamento={validar.data_agendamento}
                mat={validar.mat}
                nome={validar.nome}
                psicologo={validar.psicologo}
                status={validar.status}
                empresa={state.empresa}
                contrato={state.contrato}
                filial={state.filial}
            />
        )}
    </Container2>
    );
}

export default Body;