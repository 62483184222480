import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Container, Header } from './styles';

import CardBoard from '../../../../components/CardBoard';

function Body({dados, lista}) {
    return (
        <>
            {lista && 
                <>
                    <Container>
                        <Header>Dashboard / {dados.titulo}</Header>
                        <Grid container spacing={3}>
                        {lista.map((checklist, index) => (
                            <Grid item xs={6} sm={3} key={checklist.id}>
                                <CardBoard data={checklist}  />
                            </Grid>  
                        ))}
                        </Grid>
                    </Container>
                </>
            }
        </>
    );
}

export default Body;