import React, {useRef, useState, useEffect} from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { makeStyles,  } from '@material-ui/core/styles';

import { logout, getMat, setModulos, getModulos} from "../../services/auth";
import { config } from '../../services/constants';

const drawerWidth = 63;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    appBar: {
        background: '#f8a23d',
        color: '#FFF',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbar2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        width: '100%',
    },
    toolbaricon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 31,
        height: 31,
    },
    content: {
        height: '100%',
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
    tabs: {
        maxWidth: drawerWidth,
    },
}));
// import { Container } from './styles';

function Hearder({history}) {
    const classes = useStyles();
    const [visivel, setVisivel] = useState(false);
    const [url, setUrl] = useState("");

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }
    const mat = getMat();
    function seturlFoto(mat){
        var url2 = config.url.API_URL
        var url = url2+ "/images/photos/"+mat+".jpg";
        setUrl(url);
    }

    function sair() {
        logout();
        setModulos([]);
        history.push('/');
    }
    function privacidade(){
        history.push('/privacy');
    }
    function usuarios(){
        history.push('/registration');
    }

    function aviso(){
        history.push('/aviso');
    }

    useEffect (() => {
        seturlFoto(mat);
        var modulos =  JSON.parse(getModulos());
        // eslint-disable-next-line array-callback-return
        modulos.map((dados, index) => {
            // eslint-disable-next-line eqeqeq
            if(dados.modulo == 19){
                setVisivel(true)
            }
        })
    }, [mat]);

    return (<AppBar
            position="fixed"
            className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    CILog
                </Typography>
                <Button ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={handleToggle}>
                    <Avatar alt="Avatar" src={url}  />
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            {visivel ?
                            (
                                <MenuItem onClick={aviso}>Avisos</MenuItem>
                            ): null}
                            <MenuItem onClick={usuarios}>Cadastros</MenuItem>
                            <MenuItem onClick={privacidade}>Privacidade</MenuItem>
                            <MenuItem onClick={sair}>Sair</MenuItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
                </Popper>
                
            </Toolbar>
        </AppBar>);
}

export default Hearder;