import React, {useState, useEffect} from 'react';

import { Logo2 } from '../Treinamentos/styles';
import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';

import CircularProgress from '@material-ui/core/CircularProgress';
import logo_icon from '../../img/icon.png';

import api from '../../services/api';
import Body from './components/Body';
import Styles from '../../components/Styles'

import {getEmpresa, 
    getContrato, 
    getFilial, 
    getMat, 
    setModulos, 
    setOpes, 
    setEmpresas2,
    setContratos2,
    setFilials
} from "../../services/auth";

function Home({ history }) {
    const classes = Styles();
    const [loadding, setLoadding] = useState({ show: true });
    const [dados, setDados] = useState([]);
    const [lista, setLista] = useState([]);
    const [login, setLogin] = useState([]);

    useEffect (() => {
        
        async function loadCheck(){
            await api.get(`/main/cont2`,{
                headers:{
                    empresa: getEmpresa(),
                    contrato: getContrato(),
                    filial: getFilial()
                }
            }).then(function (response) {
                setDados(response.data.checklist);
                setLista(response.data.checklist.lista)
                setOpes(JSON.stringify(response.data.opes))
                setEmpresas2(JSON.stringify(response.data.empresas));
                setContratos2(JSON.stringify(response.data.contratos));
                setFilials(JSON.stringify(response.data.filias));
            });
           
            await api.post(`/login/acesso`, {
                empresa: getEmpresa(),
                sistema: "CILog",
                contrato: getContrato(),
                id_func: getMat(),
                filial: getFilial()
            }).then(function (response) {
                var {modulos} = response.data;
                if(modulos != null){
                    setLogin(modulos);
                    setModulos(JSON.stringify(modulos));
                }else{
                    history.push('/erro');
                }
                setLoadding({ show: false });
            });
        }
        loadCheck();
    }, [history]);

    return (
        <>
            {loadding.show ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Hearder history={history} />
                    <Menu history={history} />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {login && (<Body dados={dados} lista={lista} />)}
                    </main>
                </>
            )}
        </>
      );
}

export default Home;