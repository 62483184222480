import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import api from '../../../../services/api'
import DialogTitle from '../../../../components/BoardGas/Components/DialogTitle';

import Style from '../../../../components/Styles'
import { InputLabel, TextField } from '@material-ui/core';
import { getUser } from '../../../../services/auth';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModalVencimento({modalVencimento, empresa, contrato, filial, onClose, consultar, ...rest}) {
    const classes = Style();
    const [vencimentoEdit, setVencimentoEdit] = useState('')
    const [loading, setLoading] = useState(false)
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const handleChange1 = (event) => {
        const value = event.target.value;
        setVencimentoEdit(value);
        
    };
    function salva(){
        // eslint-disable-next-line eqeqeq
        if(vencimentoEdit == ''){
            setOpen3({
                show: true,
                text: "Preencha a data de vencimento!",
                type: 'error'
            });
            return false;
        }
        setLoading(true)
        api.get('/financeiro/faturamento/' + modalVencimento.id + '/edit',
        {
            params: {
                vencimento: vencimentoEdit,
                empresa, 
                contrato,
                filial,
                user: getUser(),
            }
        })
        .then(function(response){
            setLoading(false);
            let {status, message} = response.data;
            if(status === true){
                onClose();
                consultar()
            }else{
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
            }
        }).catch((error) => {
            console.log(error);
            setOpen3({
                show: true,
                text: 'Ocorreu um erro!',
                type: "error"
            });
            setLoading(false);
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false}>Alterar data de vencimento</DialogTitle>
        <DialogContent className={classes.root}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="vencimento_edit_fin">Vencimento</InputLabel>
                        <TextField
                            type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="nps_dateini" 
                                value={vencimentoEdit}
                                onChange={handleChange1}
                                inputProps={{
                                    name: 'vencimento_edit_fin',
                                    id: 'vencimento_edit_fin',
                                }}
                                placeholder='dd/mm/aaaa'
                            />
                        
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={loading} onClick={onClose}>Cancelar</Button>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={salva}
                    >
                    Salvar
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </DialogActions>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    </Dialog>
  );
}

export default ModalVencimento;