import React, {useState} from 'react';
import { Container2, Logo2, Progress,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../services/api'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import ProgressBar from 'react-customizable-progressbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import Styles from '../Styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IconContext } from "react-icons";
import { FaStar } from 'react-icons/fa';

import ModalEdit from './ModalEdit';

import { getUser, getusertype} from "../../services/auth";

import { Form, Dropdown, DropdownButton } from 'react-bootstrap';

import logo_icon from '../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react"; 
import { CSVLink } from "react-csv";
import { TextField } from '@material-ui/core';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BoardSocorro({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState(0);
    const [percent2, setPercent2] = useState(0);
    const [percent3, setPercent3] = useState(0);
    const [percent4, setPercent4] = useState(0);
    const [percent5, setPercent5] = useState({
        media: 0,
        percentual: 0
    });
    const [exportCsv, setExportCsv] = useState([]);

    var type = getusertype();

    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const handleChange5 = (event) => {
        const value = event.target.value;
        setDateini(value);
        
    };
    const handleChange6 = (event) => {
        const value = event.target.value;
        setDatefin(value);
    };
   
    function consultar_abastecimento(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial, tipo} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafin = datefin;
        datafin = datafin.split("-").reverse().join("/");
        api.get('/socorro/allClient',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafin,
                        contrato,
                        filial,
                        tipo,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            let data = response.data;
            let export_temp = null;
            if(state.tipo == 1){
                let json = data.relatorio;
                let {prazo, final, devolucao, jornada, rating} = data.grafico;
                setPercent(prazo);
                setPercent2(final);
                setPercent3(devolucao);
                setPercent4(jornada);
                setPercent5(rating)
                data = JSON.stringify(json);
                data = JSON.parse(data);
                if(json !== null && json !== ''  && json !== []){
                    let where2 = [];
                    let dados = json[0];
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                    setColumns(where2);
                    setProducts(json);
                    
                    export_temp = data.map((column)  => {
                        delete column.id;
                        delete column.Localização;
                        let name = column.text;
                        if(name === "status"){

                        }
                        return column;
                    })
                    setExportCsv(export_temp);
                    setLista(true);
                }else{
                    setLista(false);
                    setProducts(null);
                }
            }else{
                let json = data;
                data = JSON.stringify(json);
                data = JSON.parse(data);
                if(json !== null && json !== ''  && json !== []){
                    let where2 = [];
                    let dados = json[0];
                    for(let colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                    setColumns(where2);
                    setProducts(json);
                    
                    export_temp = data.map((column)  => {
                        delete column.id;
                        delete column.Localização;
                        let name = column.text;
                        if(name === "status"){

                        }
                        return column;
                    })
                    setExportCsv(export_temp);
                    setLista(true);
                }else{
                    setLista(false);
                    setProducts(null);
                }
            }
            
            handleClose();
        });
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        tipo: 1
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };

    function localizacao(latitude, longitude){
        var url = "https://www.google.com/maps/?q="+latitude+","+longitude;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    };
    const [modalShow3, setModalShow3] = useState({
        show: false,
        id: 0,
    });

    function edit_socorro(id){
        setModalShow3({
            show: true,
            id
        })
    }

    return (
    <Container2>
        <Header>
            Socorro em Rota
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Relatorio"  {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <Form>
            <Grid container spacing={3}>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                    <Select
                    native
                    value={state.empresa}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'empresa',
                        id: 'abast_empresa',
                    }}
                    >
                        {empresas.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                        
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                    <Select
                    native
                    value={state.contrato}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'contrato',
                        id: 'abast_contrato',
                    }}
                    >
                        {contratos.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                    <Select
                    native
                    value={state.filial}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'filial',
                        id: 'abast_filial',
                    }}
                    >
                        {filiais.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="dateini">Data inicial</InputLabel>
                   <TextField
                        type="date"
                        className={classes.textField}
                        locale='pt-PT' 
                        id="dateini" 
                        value={dateini}
                        onChange={handleChange5}
                        inputProps={{
                            name: 'dataini',
                            id: 'abast_dataini',
                        }}
                        placeholder='dd/mm/aaaa'
                    />
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="datefim">Data final</InputLabel>
                   <TextField
                        type="date"
                        className={classes.textField}
                        locale='pt-PT' 
                        id="datefim" 
                        value={datefin}
                        onChange={handleChange6}
                        inputProps={{
                            name: 'datafim',
                            id: 'abast_datafim',
                        }}
                        placeholder='dd/mm/aaaa'
                    />
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="socorro_tipo">Tipo</InputLabel>
                    <Select
                    native
                    value={state.tipo}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'tipo',
                        id: 'socorro_tipo',
                    }}
                    >
                        <option value={1}>Geral</option>
                        <option value={2}>Problemas</option>
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title="Consultar">
                        <IconButton aria-label="Consultar" onClick={() => consultar_abastecimento()} color="primary" variant="contained">
                            <SearchIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs="auto">
                    <CSVLink data={exportCsv} filename={"socorro.csv"}>     
                        <Tooltip title="Exportar csv">
                            <IconButton aria-label="Exportar csv" variant="contained">
                                <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                            </IconButton>
                        </Tooltip>
                    </CSVLink>
                </Grid> 
            </Grid>
        </Form>
        <Box component="span" m={1}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    {lista && (
                        <>
                            {state.tipo == 1 && (
                                <Grid container spacing={3}>
                                    <Grid item xs="auto">
                                        <Progress>
                                            <ProgressBar
                                                radius={70}
                                                progress={percent}
                                                strokeWidth={10}
                                                strokeColor="#ffce54"
                                                strokeLinecap="butt"
                                                trackStrokeWidth={8}
                                                trackStrokeLinecap="butt"
                                                initialAnimation
                                                rotate={-210}
                                            >
                                            <div className={classes.indicator2}>
                                                Atendimento dentro do prazo
                                            </div>
                                            <div className={classes.indicator}>
                                                {percent}%
                                            </div>
                                            </ProgressBar>
                                        </Progress>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Progress>
                                            <ProgressBar
                                                radius={70}
                                                progress={percent2}
                                                strokeWidth={10}
                                                strokeColor="#ffce54"
                                                strokeLinecap="butt"
                                                trackStrokeWidth={8}
                                                trackStrokeLinecap="butt"
                                                initialAnimation
                                                rotate={-210}
                                            >
                                            <div className={classes.indicator2}>
                                                Socorros Finalizados
                                            </div>
                                            <div className={classes.indicator}>
                                                {percent2}%
                                            </div>
                                            </ProgressBar>
                                        </Progress>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Progress>
                                            <ProgressBar
                                                radius={70}
                                                progress={percent3}
                                                strokeWidth={10}
                                                strokeColor="#ffce54"
                                                strokeLinecap="butt"
                                                trackStrokeWidth={8}
                                                trackStrokeLinecap="butt"
                                                initialAnimation
                                                rotate={-210}
                                            >
                                            <div className={classes.indicator2}>
                                                Socorros sem devolução
                                            </div>
                                            <div className={classes.indicator}>
                                                {percent3}%
                                            </div>
                                            </ProgressBar>
                                        </Progress>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Progress>
                                            <ProgressBar
                                                radius={70}
                                                progress={percent4}
                                                strokeWidth={10}
                                                strokeColor="#ffce54"
                                                strokeLinecap="butt"
                                                trackStrokeWidth={8}
                                                trackStrokeLinecap="butt"
                                                initialAnimation
                                                rotate={-210}
                                            >
                                            <div className={classes.indicator2}>
                                                Socorros batendo JL
                                            </div>
                                            <div className={classes.indicator}>
                                                {percent4}%
                                            </div>
                                            </ProgressBar>
                                        </Progress>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Progress>
                                            <ProgressBar
                                                radius={70}
                                                progress={percent5.percentual}
                                                strokeWidth={10}
                                                strokeColor="#ffce54"
                                                strokeLinecap="butt"
                                                trackStrokeWidth={8}
                                                trackStrokeLinecap="butt"
                                                initialAnimation
                                                rotate={-210}
                                            >
                                            <div className={classes.indicator2}>
                                                Rating
                                            </div>
                                            <div className={classes.indicator}>
                                                {percent5.media}
                                                <IconContext.Provider value={{ size: "1.1em"}} >
                                                    <FaStar style={{marginTop: '5px', marginLeft: '5px'}}/>
                                                </IconContext.Provider>
                                            </div>
                                            </ProgressBar>
                                        </Progress>
                                    </Grid>
                                </Grid>
                            )}
                            {state.tipo == 2 && (
                                <ResponsiveContainer  width='100%' height={300}>
                                    <BarChart data={products}>
                                        <XAxis dataKey="nome" />
                                        <YAxis />
                                        <Bar dataKey="Quantidade" fill="#f8a23d" />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    if(name !== "Localização"){
                                                        if(name === "status"){
                                                            if(dados[name] === "Finalizado"){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <Badge color="success" className="mr-1">
                                                                        Finalizado
                                                                    </Badge>
                                                                </Table.Col>);
                                                            }else{
                                                                if(type === "a"){
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                                <DropdownButton size="sm" variant="warning"
                                                                                title="Pendente">
                                                                                    <Dropdown.Item onClick={() => edit_socorro(dados.id)}>
                                                                                        <EditIcon fontSize="small" />
                                                                                        Alterar
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item disabled>
                                                                                        <DeleteIcon fontSize="small" />
                                                                                        Excluir
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </Table.Col>);
                                                                }else{
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                        <Badge color="warning" className="mr-1">
                                                                            Em andamento
                                                                        </Badge>
                                                                    </Table.Col>);
                                                                }
                                                            }
                                                        }else{
                                                            if(name === "Rating"){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name]}
                                                                    <IconContext.Provider value={{ size: "1.1em"}}>
                                                                        <FaStar  style={{marginTop: 4}}/>
                                                                    </IconContext.Provider>
                                                                </Table.Col>); 
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                            }
                                                        }
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                            <Button aria-label="Localização" onClick={() => localizacao(dados[name].latitude, dados[name].longitude)} size="small">
                                                                <LocationOnIcon fontSize="small"/>
                                                            </Button>
                                                        </Table.Col>);
                                                    }
                                                }
                                                return false;
                                            })}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </>
            )}
            </Box>
        </TabPanel>
        {modalShow3.show ? (
            <ModalEdit
                open={modalShow3.show}
                onClose={() => setModalShow3({
                    show: false,
                    id: 0
                })}
                maxWidth="sm"
                fullWidth={true}
                empresa={state.empresa}
                contrato={state.contrato}
                filial={state.filial}
                consulta={consultar_abastecimento}
                id={modalShow3.id}
            />
        ):null}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    
    </Container2>
    );
}

export default BoardSocorro;