import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput2 from "../../../../functions/CurrencyInput2"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../components/Styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'

import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput2
        {...other}
        
      />
    );
}

function ModalEditMilimetragem(props) {
    const classes = Styles();
    var {id, onClose, consulta, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [save, setSave] = useState(false);
    const [formM1, setM1] = useState({
        error: false,
        helperText: '',
    })
    const [formM2, setM2] = useState({
        error: false,
        helperText: '',
    })

    useEffect (() => {
        async function carrega(){
            setOpen(true);
            await api.get('/milimetragem/' + id,
                { 
                    headers: { 
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let { status, data } = response.data;
                if(status === true){
                    let { date, posicao, placa, valor1, valor2, valor3, valor4} = data;
                    date = date.split("/").reverse().join("-");
                    document.getElementById('data_edit').value = date;
                    document.getElementById('placa_edit').value = placa;
                    document.getElementById('posicao_edit').value = posicao;
                    document.getElementById('m1_edit').value = valor1;
                    document.getElementById('m2_edit').value = valor2;
                    document.getElementById('m3_edit').value = valor3;
                    document.getElementById('m4_edit').value = valor4;
                }else{
                    let { message } = response.data;
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
            });
        }
        carrega();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    
    function salvar(){
        let m1_edit = document.getElementById('m1_edit').value;
        let m2_edit = document.getElementById('m2_edit').value;
        let m3_edit = document.getElementById('m3_edit').value;
        let m4_edit = document.getElementById('m4_edit').value;
        let data_edit = document.getElementById('data_edit').value;
        setM1({
            error: false,
            helperText: '',
        })
        setM2({
            error: false,
            helperText: '',
        })
        if(data_edit == null || data_edit == ''){
            setOpen3({
                show: true,
                text: "Preencha a data!",
                type: "error"
            });
            return false;
        }
        if(m1_edit == null || m1_edit == ''){
            setM1({
                helperText: 'Preencha as milimetragens.',
                error: true
            })
            return false;
        }
        if(m2_edit == null || m2_edit == ''){
            setM2({
                helperText: 'Preencha as milimetragens.',
                error: true
            })
            return false;
        }
        setSave(true)
        api.get('/milimetragem/'+id+'/edit',
        { 
            params: { 
                user: getUser(),
                sulco1: m1_edit,
                sulco2: m2_edit,
                sulco3: m3_edit,
                sulco4: m4_edit,
                data: data_edit,
            } 
        })
        .then(function(response){
            setSave(false)
            var {status, erro} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Alteração salva com sucesso!',
                    type: "success"
                });
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
            }
        }).catch(function(error){
            setSave(false);
            setOpen3({
                show: true,
                text:  "Ocorreu um erro!",
                type: "error"
            });
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Alterar Milimetragem</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={3}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="placa_edit">Placa</InputLabel>
                               <TextField
                                    size="small"
                                    id="placa_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="data_edit">Data</InputLabel>
                               <TextField
                                    size="small"
                                    id="data_edit"
                                    type="date"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="posicao_edit">Posição</InputLabel>
                               <TextField
                                    size="small"
                                    id="posicao_edit"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m1_edit">Sulco1</InputLabel>
                                <Input
                                size="small"
                                id="m1_edit"
                                error={formM1.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formM1.error}>{formM1.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m2_edit">Sulco2</InputLabel>
                                <Input
                                size="small"
                                id="m2_edit"
                                error={formM2.error}
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                                <FormHelperText  error={formM2.error}>{formM2.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m3_edit">Sulco3</InputLabel>
                                <Input
                                size="small"
                                id="m3_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="m4_edit">Sulco4</InputLabel>
                                <Input
                                size="small"
                                id="m4_edit"
                                type="text"
                                className={classes.textField}
                                inputComponent={TextMaskCustom}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={save} onClick={onClose}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button color="primary" disabled={save} onClick={() => {salvar()}} variant="contained">Salvar</Button>
                    {save && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEditMilimetragem;