import React from 'react';

import { Logo2 } from '../Treinamentos/styles';
import logo_icon from '../../img/icon.png';

function Erro404() {
  return (
    <Logo2>
        <img src={logo_icon} alt="" />
        <div style={{textAlign: 'center', fontSize: 30}}>
            Erro 404<br></br>
            Pagina não encontrada!
        </div>
    </Logo2>
  );
}

export default Erro404;