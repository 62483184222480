import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import Switch from '@material-ui/core/Switch';
import Styles from '../../../../../components/Styles';

import DialogTitle from '../../../../../components/BoardCheck/Components/DialogTitle'

import { Logo2 } from '../../../../../components/BoardCheck/styles';
import api from '../../../../../services/api'
import logo_icon from '../../../../../img/icon.png';
import { getUser} from "../../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit(props) {
    const classes = Styles();
    var {id, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [modulos, setModulos] = useState([]);
    const [open2, setOpen2] = useState(false);
    const [state, setState] = useState();
    
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    useEffect (() => {
        async function carrega_dados(){
            setOpen(true);
            api.get('/usuarios/' + id,
            { 
                headers: { 
                        user: getUser(),
                    } 
                }
            )
            .then(function (response) {
                setOpen(false);
                let { email, usuario, funcionario, filial, modulos} = response.data;
                document.getElementById('user_nome').value = usuario;
                document.getElementById('user_email').value = email;
                document.getElementById('user_funcionario').value = funcionario.NOMEFUNC;
                let newValue = {
                    id: id
                };
                let modulosCadastro = filial.modulos;
                modulosCadastro.forEach(element => {
                    let ver = false;
                    modulos.forEach(element2 => {
                        if(element2.modulo === element.modulo){
                            ver = true;
                        }
                    });
                    newValue["modulo"+ element.modulo]= ver; 
                });
                setState(newValue);
               
                setModulos(modulosCadastro);
            }).catch(function(error){
                console.log(error);
            });
        }
        carrega_dados();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salvaEdit(){
        setOpen2(true);
        api.put('/usuarios/' + id,
        { 
            user: getUser(),
            modulos: state
        }).then(function (response) {
            setOpen2(false);
            let {status, message} = response.data;
            if(status){
                setOpen3({
                    show: true,
                    text: message,
                    type: 'success'
                })
                onClose();
                consulta();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: 'error'
                })
            }
        }).catch(function(error){
            console.log(error);
        });
        
    }
    const sendEmail = () => {
        setOpen2(true);
        api.get('/usuarios/' + id + '/edit',
        { 
            user: getUser(),
            modulos: state
        }).then(function (response) {
            console.log(response.data);
            setOpen2(false);
            let {status, message} = response.data;
            if(status){
                setOpen3({
                    show: true,
                    text: message,
                    type: 'success'
                })
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: 'error'
                })
            }
        }).catch(function(error){
            console.log(error);
        });
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Alterar Usuário</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="user_nome">Usuario</InputLabel>
                               <TextField
                                size="small"
                                    id="user_nome"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="user_email">E-mail</InputLabel>
                               <TextField
                                size="small"
                                    id="user_email"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="user_funcionario">Funcionario</InputLabel>
                               <TextField
                                size="small"
                                    id="user_funcionario"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            Modulos
                        </Grid>
                        {modulos && (
                            modulos.map((dados, index) => (
                                <Grid item xs={3} key={dados.id}>
                                    <Form.Group style={{fontSize: "10px"}}> 
                                        <Switch
                                            checked={state["modulo"+ dados.modulo]}
                                            onChange={handleChange}
                                            name={"modulo"+ dados.modulo}
                                            color="primary"
                                        />
                                        {dados.modulos.descr}
                                    </Form.Group>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button disabled={open2} onClick={() => sendEmail()}>Cadastrar senha</Button>  
                    <Button color="secondary" disabled={open2} onClick={onClose}>Cancelar</Button>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={open2}
                            onClick={salvaEdit}
                            >
                            Salvar
                        </Button>
                        {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit;