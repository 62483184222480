import React from 'react';

import { Text, View, StyleSheet, Image as ImagePdf } from '@react-pdf/renderer';

// import { Container } from './styles';

const styles2 = StyleSheet.create({
    tab: {
        width: "94%",
        flexDirection: "row",
        borderBottom: 1,
        borderColor: "#000",
        padding: 5,
        justifyContent: "space-between"
    },
    
    image: {
        width: 150,
        height: 30
    },
    text: {
        fontSize: 10,
    }
    
});


function Header({dados, base64}) {
  return (
      <View>
        <View style={styles2.tab}>
            <View >
            </View>
            <View  >
                {base64 &&
                    <ImagePdf 
                    allowDangerousPaths
                    style={styles2.image}  
                    src={base64}
                    alt="Logo" />
                }
            </View>
            <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
            </View>
            <View >
                <Text>{dados.titulo}</Text>
            </View>
            <View >
                <Text>Nª: {dados.numero}</Text>
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Data: {dados.data}</Text>
            </View>
            <View >
            </View>
            <View >
            </View>
            <View >
                <Text style={styles2.text}>Placa: {dados.placa}</Text>
            </View>
            <View >
                <Text style={styles2.text}>Prefixo: {dados.prefixo}</Text>
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Requisitante: {dados.requisitante}</Text>
            </View>
            <View >
            </View>
            <View >
            </View>
            <View >
            </View>
            <View >
                <Text style={styles2.text}>Operação: {dados.operacao}</Text>
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Fornecedor: {dados.objetivo}</Text>
            </View>
            <View >
            </View>
            <View >
                <Text style={styles2.text}>Validador: {dados.assinatura}</Text>
            </View>
        </View>
    </View>
  );
}

export default Header;