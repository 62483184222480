import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import CurrencyInput from '../../../functions/CurrencyInput'
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'



import DialogTitle from '../Components/DialogTitle';

import { Logo2 } from './styles';
import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

import Style from '../../Styles'



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput
        {...other}
        
      />
    );
}
const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  });
  
function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
function ModalAdd(props) {
    const classes = Style();
    const [placas, setPlacas] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [open, setOpen] = useState(true);
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    var {empresa, contrato, filial, onClose, liberacao, ...rest} = props;

    const [formPlaca, setPlaca] = useState({
        errorPlaca: false,
        helperTextPlaca: '',
    })
    const [formData, setData] = useState({
        errorData: false,
        helperTextData: '',
    })
    const [formKm, setKm] = useState({
        errorKm: false,
        helperTextKm: '',
    })
    const [formLitro, setLitro] = useState({
        errorLitro: false,
        helperTextLitro: '',
    })
    const [formMot, setMot] = useState({
        errorMot: false,
        helperTextMot: '',
    })
    const [formManobra, setManobra] = useState({
        error: false,
        helperText: '',
    })
    const [formFornecedor, setFormFornecedor] = useState({
        error: false,
        helperText: '',
    })

    useEffect (() => {
        async function carrega_placas(){
            setOpen(true);
            await api.get('/abastecimento',
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                            type: 1,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                let {placas, fornecedor} = response.data;
                if(placas != null && placas != []){
                    setPlacas(placas);
                }
                if(fornecedor != null && fornecedor != []){
                    setFornecedores(fornecedor)
                }
                
            });
        }
        carrega_placas();
      }, [empresa, 
        contrato,
        filial,]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    function carrega_cap_tanque(){
        var placa = document.getElementById('abast_placa_inclui').value;
        var data = document.getElementById('abast_data_incluir').value;
        data = data.split("-").reverse().join("/");
        if(placa !== ""){
            api.get('/abastecimento/' + placa,
                { 
                    headers: { 
                            user: getUser(),
                            type: 1,
                            tipo: value
                        } 
                    }
                )
            .then(function (response) {
                var {capacidade} = response.data;
                document.getElementById('abast_cap_incluir').value = capacidade;
            });
            if(data !== ""){
                api.get('/abastecimento/' + placa,
                    { 
                        headers: { 
                                user: getUser(),
                                type: 2,
                                data,
                                tipo: value  
                            } 
                        }
                    )
                .then(function (response) {
                    var {ultimo_km} = response.data;
                    var {km_atual} = response.data;
                    var hodo_ant = ultimo_km;
                    document.getElementById('abast_hod_ant_incluir').value = hodo_ant;
                    if(value === "E"){
                        if(km_atual !== null){
                            document.getElementById('abast_hodometro_incluir').value = km_atual.km;
                        }
                    }
                    if(km_atual !== null){
                        var {motoristas} = km_atual;
                        if(motoristas !== null){
                            var {CODFUNC} = motoristas;
                            document.getElementById('abast_mat_mot_incluir').value = CODFUNC;
                            get_func('abast_mat_mot_incluir', 'abast_nome_incluir');
                        }
                    }
                });
            }
        }
        
    }
    function calc_kml(){
        var kmant = document.getElementById('abast_hod_ant_incluir').value;
        var km = document.getElementById('abast_hodometro_incluir').value;
        var litros = document.getElementById('abast_litros_incluir').value;
        litros = litros.replace(".", "")
        litros = litros.replace(",", ".");
        var kml1 = parseFloat(km) - parseFloat(kmant);
        var kml = parseFloat(kml1) / parseFloat(litros);
        if(!Number.isNaN(kml)){
            document.getElementById('abast_kml_incluir').value = kml.toFixed(2).replace(".", ",");
        }
        
    }
    function get_func(mat, campo){
        var mat_mot = document.getElementById(mat).value;
        if(mat_mot !== "" && mat_mot !== "0"){
            api.get('/funcionarios/' + mat_mot,
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                        } 
                    }
                )
            .then(function (response) {
                var {NOMEFUNC} = response.data;
                document.getElementById(campo).value = NOMEFUNC;
            });
        }else{
            document.getElementById(campo).value = "";
        }
    }

    function salva_abast(){
        var placa = document.getElementById('abast_placa_inclui').value;
        var data = document.getElementById('abast_data_incluir').value;
        data = data.split("-").reverse().join("/");
        var km = document.getElementById('abast_hodometro_incluir').value;
        var litros = document.getElementById('abast_litros_incluir').value;
        var mat_mot = document.getElementById('abast_mat_mot_incluir').value;
        setPlaca({
            errorPlaca: false,
            helperTextPlaca: '',
        })
        setData({
            errorData: false,
            helperTextData: '',
        })
        setKm({
            errorKm: false,
            helperTextKm: '',
        })
        setMot({
            errorMot: false,
            helperTextMot: '',
        })
        setLitro({
            errorLitro: false,
            helperTextLitro: '',
        })
        if (value === '') {
            setHelperText("Selecione o tipo!")
            setError(true);
            return false;
        }
        if(placa === ""){
            setPlaca({
                  helperTextPlaca: 'Selecione uma placa.',
                  errorPlaca: true
              })
            return false;
        }
        if(data === ""){
            setData({
                helperTextData: 'Selecione uma data.',
                errorData: true
            })
            return false;
        }
        if(km === ""){
            setKm({
                helperTextKm: 'Digite o Hodometro',
                errorKm: true
              })
            return false;
        }
        if(litros === ""){
            setLitro({
                errorLitro: true,
                helperTextLitro: 'Digite os litros.',
            })
            return false;
        }
        if(mat_mot === ""){
            setMot({
                helperTextMot: 'Digite a matricula do motorista.',
                errorMot: true
              })
            return false;
        }
        var mat_manobra = null;
        var fornecedor = null;
        if (value === 'E') {
            mat_manobra = document.getElementById('abast_mat_manobra_incluir').value;
            if(mat_manobra === ""){
                setManobra({
                    error: true,
                    helperText: 'Digite a matricula do Manobra.',
                })
                return false;
            }
            fornecedor = document.getElementById('abast_fornecedor_inclui').value;
            if(fornecedor === ""){
                setFormFornecedor({
                    error: true,
                    helperText: 'Selecione o fornecedor!',
                })
                return false;
            }
        }
        var kml2 = document.getElementById('abast_kml_incluir').value.replace(",", ".");
		if(kml2 < 0){
            setOpen3({
                show: true,
                text: 'km/L não pode ser negativo!',
                type: "warning"
            });
            return false;
        }
        var cap = document.getElementById('abast_cap_incluir').value;
        cap = cap/1;
        if(cap !== 0 && cap !== ""){
            var litros2 = litros.replace(".", "");
            litros2 = litros2.replace(",", ".");
            litros2 = litros2/1;
            if(litros2 > cap){
                setOpen3({
                    show: true,
                    text: 'Quantidade de litros abastecido não pode ser maior que a capacidade do tanque!',
                    type: "warning"
                });
                return false;
            }
        }
        var km_ant = document.getElementById('abast_hod_ant_incluir').value;
        km_ant = km_ant/1;
        km = km/1;
        if(km_ant > km){
            setOpen3({
                show: true,
                text: 'Hodometro atual menor que o anterior!',
                type: "warning"
            });
            return false;
        }
        setOpen(true);
        api.post('/abastecimento/inclui',
        { 
            user: getUser(),
            empresa,
            contrato,
            filial,
            carro: placa,
            data, 
            km, 
            litros,
            mat_mot,
            mat_manobra,
            tipo: value,
            fornecedor
        })
        .then(function(response){
            var {status, erro} = response.data;
            if(status === 1){
                setOpen3({
                    show: true,
                    text: 'Abastecimento salvo com sucesso!',
                    type: "success"
                });
                if(value === "E"){
                    var {dados} = response.data;
                    liberacao({
                        show: true,
                        id: dados.id
                    });
                }
                onClose();
            }else{
                setOpen3({
                    show: true,
                    text: erro,
                    type: "error"
                });
                setOpen(false);
            }
        })
    }
    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText('');
        setError(false);
        if(event.target.value === "E"){
            setVisible(true);
        }else{
            setVisible(false);
        }
      };
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false} open={open}>Incluir Abastecimento</DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_placa_inclui">Tipo</InputLabel>
                                <RadioGroup row value={value} onChange={handleRadioChange}  aria-label="Tipo" name="customized-radios">
                                    <FormControlLabel classes={{ label: classes.label}} value="I" control={<StyledRadio />} label="Interno" />
                                    <FormControlLabel classes={{ label: classes.label}}  value="E" control={<StyledRadio />} label="Externo" />
                                </RadioGroup>
                                <FormHelperText  error={error}>{helperText}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="abast_placa_inclui">Placa</InputLabel>
                            <Select
                            native
                            error={formPlaca.errorPlaca}
                            size="small"
                            id="abast_placa_inclui"
                            onChange={() => {carrega_cap_tanque()}}
                            className={classes.textField}
                            >
                                <option value={''}>Selecione...</option>
                                {placas.map((dados) => (
                                    <option key={dados.id} value={dados.id}>{dados.placa}</option>
                                ))}
                            </Select>
                            <FormHelperText  error={formPlaca.errorPlaca}>{formPlaca.helperTextPlaca}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="abast_cap_incluir">Cap. tanque</InputLabel>
                           <TextField
                                size="small"
                                id="abast_cap_incluir"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={6}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="abast_data_incluir">Data</InputLabel>
                           <TextField
                                type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="abast_data_incluir" 
                                onChange={() => {carrega_cap_tanque()}}
                                placeholder='dd/mm/aaaa'
                            />
                            <FormHelperText  error={formData.errorData}>{formData.helperTextData}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={6}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="abast_hod_ant_incluir">Hodometro ant</InputLabel>
                           <TextField
                            size="small"
                                id="abast_hod_ant_incluir"
                                type="text"
                                disabled
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="abast_hodometro_incluir">Hodometro</InputLabel>
                           <TextField
                            size="small"
                            error={formKm.errorKm}
                            helperText={formKm.helperTextKm}
                                id="abast_hodometro_incluir"
                                type="number"
                                onChange={() => calc_kml()}
                                className={classes.textField}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="abast_litros_incluir">Litros</InputLabel>
                            <Input
                            size="small"
                            id="abast_litros_incluir"
                            error={formLitro.errorLitro}
                            type="text"
                            onChange={() => calc_kml()}
                            className={classes.textField}
                            inputComponent={TextMaskCustom}
                            />
                            <FormHelperText  error={formLitro.errorLitro}>{formLitro.helperTextLitro}</FormHelperText>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={4}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha} htmlFor="abast_kml_incluir">km/L</InputLabel>
                           <TextField
                            size="small"
                                id="abast_kml_incluir"
                                type="text"
                                className={classes.textField}
                                disabled
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={3}>
                        <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="abast_mat_mot_incluir">Motorista</InputLabel>
                           <TextField

                            size="small"
                            error={formMot.errorMot}
                            helperText={formMot.helperTextMot}
                                id="abast_mat_mot_incluir"
                                type="number"
                                className={classes.textField}
                                onChange={() => get_func("abast_mat_mot_incluir", "abast_nome_incluir")}
                            />
                        </Form.Group>
                    </Grid>
                    { /*<Grid item xs={1}>
                        <FontAwesomeIcon icon={faBinoculars} size="2x"/>
                    </Grid>*/ }
                    <Grid item xs={9}>
                        <Form.Group className={classes.disabled}> 
                            <InputLabel className={classes.linha}  htmlFor="abast_nome_incluir">Nome</InputLabel>
                           <TextField
                            size="small"
                                id="abast_nome_incluir"
                                type="text"
                                className={classes.textField}
                                disabled
                            />
                        </Form.Group>
                    </Grid>
                    {visible ? (
                    <>
                        <Grid item xs={3}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_mat_manobra_incluir">Manobra</InputLabel>
                               <TextField
                                size="small"
                                error={formManobra.error}
                                helperText={formManobra.helperText}
                                    id="abast_mat_manobra_incluir"
                                    type="number"
                                    className={classes.textField}
                                    onChange={() => get_func("abast_mat_manobra_incluir", "abast_nome_manobra_incluir")}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={9}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha}  htmlFor="abast_nome_manobra_incluir">Nome</InputLabel>
                               <TextField
                                size="small"
                                    id="abast_nome_manobra_incluir"
                                    type="text"
                                    className={classes.textField}
                                    disabled
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="abast_fornecedor_inclui">Fornecedor</InputLabel>
                                <Select
                                native
                                error={formFornecedor.error}
                                size="small"
                                id="abast_fornecedor_inclui"
                                className={classes.textField}
                                >
                                    <option value={''}>Selecione...</option>
                                    {fornecedores.map((dados) => (
                                        <option key={dados.id} value={dados.id}>{dados.nome}</option>
                                    ))}
                                </Select>
                                <FormHelperText  error={formFornecedor.error}>{formFornecedor.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                    </>
                    ) : null}
                </Grid>
            )}
        </DialogContent>
        {open ? false : (
            <DialogActions>
                <Button color="secondary" onClick={onClose}>Cancelar</Button>
                <Button color="primary" variant="contained" onClick={() => {salva_abast()}}>Salvar</Button>
            </DialogActions>
        )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        </Dialog>
  );
}

export default ModalAdd;