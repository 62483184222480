import React , {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { Badge } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from './styles';

const colors = [
    '#000',
    '#414141',
];

const useStyles = makeStyles({
    sucess: {
        background: "#28a745 !important",
        color: "#000",
        font: "20px 'Roboto', sans-serif",
    },
    danger: {
        font: "20px 'Roboto', sans-serif",
        color: "#000",
        background: "#f8a23d !important",
    },
    dangerBadge: {
        font: "20px 'Roboto', sans-serif",
        color: "#000",
        background: "#EE4B2B !important",
    },
    alert: {
        background: "#414141 !important",
    },
    alert2: {
        background: "#000 !important",
    }
});

function List( {data, externo}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    useEffect (() => {
        var pend = false;
        // eslint-disable-next-line eqeqeq
        if(data.novo == 1){
            pend = true;
        }
        var interval = null;
        if(pend){
            interval = setInterval(() => {
                setValue((v) => (v === 1 ? 0 : 1));}, 1000);
                return () => {
                clearInterval(interval);
            };
        }else{
            setValue(0)
            if(interval){
                clearInterval(interval);
            }
        }
      }, [data]);
    
  return (
    <Container style={{ backgroundColor: colors[value] }}>
         <Grid container spacing={1} justifyContent="center" >
            {!externo ? (
                <>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6}>
                                Nº OS:
                            </Grid>
                            <Grid item xs={6}>
                                {data.os}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                Veiculo:
                            </Grid>
                            <Grid item xs={6}>
                                {data.placa}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                Data:
                            </Grid>
                            <Grid item xs={6}>
                            {data.data}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                Tipo:
                            </Grid>
                            <Grid item xs={6}>
                                {data.tipo}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} >
                        <Grid container>
                            <Grid item xs={2}>
                                Tempo:
                            </Grid>
                            <Grid item xs={10}>
                                {data.tempo}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={2}>
                                Responsável:
                            </Grid>
                            <Grid item xs={10}>
                                {data.mecanico}
                            </Grid>
                        </Grid>
                        {data.data.length > 0 && (
                            <Grid container>
                                { data.servico.map((servico, index) => (
                                    <Grid container key={servico.id}>
                                        {servico.status === 1 ?( 
                                        <>
                                            <Grid item xs={2}>
                                                Serviço:
                                            </Grid>
                                            <Grid item xs={8}>
                                                {servico.grupo} - {servico.defeito}
                                            </Grid>
                                            <Grid item xs={2} >
                                                    <Badge pill variant="warning" className={classes.danger}>
                                                        Em execução  
                                                    </Badge>
                                            </Grid> 
                                            </>
                                        ):(
                                            servico.status === 2 ?( 
                                                <>
                                                    <Grid item xs={2}>
                                                        Serviço:
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {servico.grupo} - {servico.defeito}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Badge pill variant="success" className={classes.sucess}>
                                                            Finalizado  
                                                        </Badge>
                                                    </Grid> 
                                                </>
                                            ):(
                                                <>
                                                    <Grid item xs={2}>
                                                        Serviço:
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {servico.grupo} - {servico.defeito}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        
                                                    </Grid> 
                                                </>
                                            )
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>

                </>
            ):(
                <>
                    <Grid container>
                        <Grid item xs={4}>
                            Nº OS:
                            {" " + data.os}
                        </Grid>
                        <Grid item xs={4}>
                            Veiculo:
                            {" " +data.placa}
                        </Grid>
                        <Grid item xs={4}>
                            Tipo:
                            {" " +data.tipo}
                        </Grid>
                        <Grid item xs={12}>
                            Motorista:
                            {" " +data.mecanico}
                        </Grid>
                    </Grid>
                    {data.data.length > 0 && (
                        <Grid container>
                            <Grid item xs={2} className={classes.alert}>
                                Abertura
                            </Grid>
                            <Grid item xs={2} className={classes.alert}>
                                Limite
                            </Grid>
                            <Grid item xs={2} className={classes.alert}>
                                Prioridade
                            </Grid>
                            <Grid item xs={5} className={classes.alert}>
                                Serviço
                            </Grid>
                            <Grid item xs={1} className={classes.alert}>
                                Status
                            </Grid>
                            { data.servico.map((servico, index) => (
                                <Grid container key={servico.id}>
                                    <Grid item xs={2} className={classes.alert}>
                                        {servico.data}
                                    </Grid>
                                    <Grid item xs={2} className={classes.alert}>
                                        {servico.limite}
                                    </Grid>
                                    {servico.status === 1 ?( 
                                        <>
                                            <Grid item xs={2} className={classes.alert}>
                                                {servico.prioridade === 'CRITICA' ? (
                                                    <Badge pill variant="danger" className={classes.dangerBadge}>CRITICA</Badge>
                                                ): (
                                                    servico.prioridade === 'ALTA' ? (
                                                        <Badge pill variant="warning" className={classes.danger}>ALTA</Badge>
                                                    ) : (
                                                        <Badge pill variant="warning" className={classes.alert}>{servico.prioridade}</Badge>
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={5} className={classes.alert}>
                                                {servico.grupo} - {servico.defeito}
                                            </Grid>
                                            <Grid item xs={1} className={classes.alert}>
                                                    <Badge pill variant="warning" className={classes.danger}>
                                                        Em execução  
                                                    </Badge>
                                            </Grid> 
                                        </>
                                    ):(
                                        servico.status === 2 ?( 
                                            <>
                                                <Grid item xs={2} className={classes.alert}>
                                                    {servico.prioridade === 'CRITICA' ? (
                                                        <Badge pill variant="danger" className={classes.dangerBadge}>CRITICA</Badge>
                                                    ): (
                                                        servico.prioridade === 'ALTA' ? (
                                                            <Badge pill variant="warning" className={classes.danger}>ALTA</Badge>
                                                        ) : (
                                                            <Badge pill variant="warning" className={classes.alert}>{servico.prioridade}</Badge>
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={5} className={classes.alert}>
                                                    {servico.grupo} - {servico.defeito}
                                                </Grid>
                                                <Grid item xs={1} className={classes.alert}>
                                                    <Badge pill variant="success" className={classes.sucess}>
                                                        Finalizado  
                                                    </Badge>
                                                </Grid> 
                                            </>
                                        ):(
                                            <>
                                                <Grid item xs={2} className={classes.alert}>
                                                    {servico.prioridade === 'CRITICA' ? (
                                                        <Badge pill variant="danger" className={classes.dangerBadge}>CRITICA</Badge>
                                                    ): (
                                                        servico.prioridade === 'ALTA' ? (
                                                            <Badge pill variant="warning" className={classes.danger}>ALTA</Badge>
                                                        ) : (
                                                            <Badge pill variant="warning" className={classes.alert}>{servico.prioridade}</Badge>
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={5} className={classes.alert}>
                                                    {servico.grupo} - {servico.defeito}
                                                </Grid>
                                                <Grid item xs={1} className={classes.alert}>
                                                    
                                                </Grid> 
                                            </>
                                        )
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    </Container>);
}

export default List;