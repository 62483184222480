import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import DialogTitle from '../../../../components/BoardGas/Components/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';


import { getUser} from "../../../../services/auth";

import Style from '../../../../components/Styles'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModalAdd(props) {
    const classes = Style();
    var {empresa, contrato, filial, onClose, ...rest} = props;
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [open2, setOpen2] = useState(false);
    const [formMat, setMat] = useState({
        error: false,
        helperText: '',
    })
    const [formData, setData] = useState({
        error: false,
        helperText: '',
    })
    const [formData2, setData2] = useState({
        error: false,
        helperText: '',
    })
    function get_func(){
        var mat_mot = document.getElementById('mat_incluir').value;
        if(mat_mot !== "" && mat_mot !== "0"){
            api.get('/funcionarios/' + mat_mot,
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                        } 
                    }
                )
            .then(function (response) {
                var {NOMEFUNC} = response.data;
                document.getElementById('nome_incluir').value = NOMEFUNC;
            });
        }else{
            document.getElementById('nome_incluir').value = "";
        }
    }
    function salva(){
        var mat = document.getElementById('mat_incluir').value;
        var dataini = document.getElementById('dataini').value;
        var datafim = document.getElementById('datafim').value;
        setMat({
            error: false,
            helperText: ''
        });
        setData({
            error: false,
            helperText: '',
        });
        setData2({
            error: false,
            helperText: '',
        });
        if(mat === 0 || mat === ""){
            setMat({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(dataini === 0 || dataini === ""){
            setData({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(datafim === 0 || datafim === ""){
            setData2({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        setOpen2(true);
       
        api.post('/absenteismo',
        {
            empresa,
            contrato,
            filial,
            mat,
            dataini,
            datafim,
            user: getUser(),
        }
        ).then(function(response){
            var {status, message} = response.data;
            if(status === true){
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "success"
                    });
                    onClose()
                }
            }else{
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
                setOpen2(false);
            }
        }).catch((error) => {
            console.log(error);
            console.log(error.request.response);
            setOpen3({
                show: true,
                text: 'Ocorreu um erro!',
                type: "error"
            });
            setOpen2(false);
        })
    }
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 500);
        return () => clearTimeout(timer);
    }, [debouncedTerm])
    useEffect(() => {
        if(term !== ''){
            get_func()
        }else{

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false}>Cadastrar entrevista</DialogTitle>
        <DialogContent className={classes.root}>
            <Grid container spacing={1} justifycontent="center" alignItems="center">
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha}  htmlFor="mat_incluir">Matricula</InputLabel>
                       <TextField
                            size="small"
                            error={formMat.error}
                            id="mat_incluir"
                            type="number"
                            className={classes.textField}
                            onChange={e => setDebouncedTerm(e.target.value)}
                            value={debouncedTerm}
                        />
                        <FormHelperText  error={formMat.error}>{formMat.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={9}>
                    <Form.Group className={classes.disabled}> 
                        <InputLabel className={classes.linha} htmlFor="nome_incluir">Nome</InputLabel>
                       <TextField
                            size="small"
                            id="nome_incluir"
                            type="text"
                            className={classes.textField}
                            disabled
                        />
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="data">Data Inicio</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="dataini" 
                            inputProps={{
                                name: 'dataini',
                                id: 'dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                            error={formData.error}
                        />
                        <FormHelperText  error={formData.error}>{formData.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="data">Data Fim</InputLabel>
                       <TextField
                        type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="datafim" 
                            inputProps={{
                                name: 'datafim',
                                id: 'datafim',
                            }}
                            placeholder='dd/mm/aaaa'
                            error={formData.error}
                        />
                        <FormHelperText  error={formData.error}>{formData.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={open2} onClick={onClose}>Cancelar</Button>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={open2}
                    onClick={salva}
                    >
                    Salvar
                </Button>
                {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </DialogActions>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    </Dialog>
  );
}

export default ModalAdd;