import React, {useState} from 'react';

import ModalEdit from '../ModalEdit';
import ModalAdd from '../ModalAdd';

import {Logo2} from '../../../../PreContra/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import Styles from '../../../../../components/Styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { getUser, getusertype} from "../../../../../services/auth";

import { Form } from 'react-bootstrap';

import logo_icon from '../../../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';

import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 

import { CSVLink } from "react-csv";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Body({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    var type = getusertype();
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    function consultar_funcionarios(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        handleToggle();
        var {empresa, contrato, filial, mes} = state;
        api.get('/funcionarios/app',
        { 
            headers: { 
                    empresa, 
                    mes, 
                    contrato,
                    filial,
                    user: getUser(),
                } 
            }
        )
        .then(function (response) {

            console.log(response.data)
            var data = response.data;
            if(data != null){
                if(data.length > 0){
                    var json = data;
                    data = JSON.stringify(json);
                    data = JSON.parse(data);
                    if(json !== null && json !== ''  && json !== []){
                        let where2 = [];
                        var dados = json[0];
                        for(var colunas in dados) {
                            let o2 = { 
                                dataField: colunas,
                                text: colunas, 
                            };
                            where2.push(o2);
                        }
                        setColumns(where2);
                        setProducts(json);
                        
                        var export_temp = data.map((column)  => {
                            delete column.id;
                            return column;
                        })
                        setExportCsv(export_temp);
                        setLista(true);
                    }else{
                        setLista(false);
                        setProducts(null);
                    }
                }else{
                    setLista(false);
                    setProducts(null);
                }
            }
            handleClose();
        });
    }
    const [modalShow3, setModalShow3] = useState({
        show: false,
        id: 0,
    });
    function deleteFuncionario(){
        if(id_temp !== 0){
            setOpen4(false);
            handleToggle();
            api.delete('/funcionarios/' + id_temp,
            { 
                headers: { 
                        user: getUser(),
                    } 
                }
            )
            .then(function (response) {
                var {status, message} = response.data;
                if(status){
                    setOpen3({
                        show: true,
                        text: 'Excluido com sucesso!',
                        type: 'success'
                    });
                }else{
                    setOpen3({
                        show: true,
                        text: message,
                        type: 'error'
                    });
                }
                
                consultar_funcionarios();
            });
        }
    }
    const [open4, setOpen4] = useState(false);
    const [id_temp, setId_temp] = useState(0);
    const handleClose4 = () => {
        setOpen4(false);
        setId_temp(0);
    };
return ( 
    <>
        <Form>
            <Grid container spacing={3}>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                    <Select
                    native
                    value={state.empresa}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'empresa',
                        id: 'abast_empresa',
                    }}
                    >
                        {empresas.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                        
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                    <Select
                    native
                    value={state.contrato}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'contrato',
                        id: 'abast_contrato',
                    }}
                    >
                        {contratos.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                    <Select
                    native
                    value={state.filial}
                    onChange={handleChange2}
                    className={classes.textField}
                    inputProps={{
                        name: 'filial',
                        id: 'abast_filial',
                    }}
                    >
                        {filiais.map((dados, index) => (
                            <option key={dados.id} value={dados.id}>{dados.descr}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title="Consultar">
                        <IconButton aria-label="Consultar" onClick={() => consultar_funcionarios()} 
                        color="primary" variant="contained">
                            <SearchIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs="auto">
                    <CSVLink data={exportCsv} filename={"usuarios.csv"}>     
                        <Tooltip title="Exportar csv">
                            <IconButton aria-label="Exportar csv" variant="contained">
                                <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                            </IconButton>
                        </Tooltip>
                    </CSVLink>
                </Grid> 
                {type === "a" && (
                    <Grid item xs="auto">
                        <Tooltip title="Cadastrar Usuario">
                            <IconButton  aria-label="Cadastrar Usuario" onClick={() => setModalShow(true)} variant="contained" color="primary">
                                <AddCircleIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        </Form>
        <Box component="span" m={1}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    {lista && (
                        <>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader className={classes.th} key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                        <Table.ColHeader className={classes.th}></Table.ColHeader>
                                        <Table.ColHeader className={classes.th}></Table.ColHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id}>
                                            {columns.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}><div className={classes.linha2}>{dados[name]}</div></Table.Col>);
                                                }
                                                return false;
                                            })}
                                            {type === "a" && (
                                                <>
                                                    {/* <Table.Col className={classes.linha}>
                                                        <div className={classes.linha2}>
                                                            <IconButton className={classes.botao} color="default" aria-label="Editar" size="small" onClick={() => editUser(dados.id)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </div>
                                                    </Table.Col> */}
                                                    {/* <Table.Col className={classes.linha}>
                                                        <div className={classes.linha2}>
                                                            <IconButton className={classes.botao} color="secondary" aria-label="Excluir" size="small" onClick={() => handleClickOpen4(dados.id)}>
                                                                <DeleteForeverIcon fontSize="small"/>
                                                            </IconButton>
                                                        </div>
                                                    </Table.Col> */}
                                                </>
                                            )}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </>
            )}
        </Box>
        <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Essa operação não pode ser desfeita!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose4} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={() => deleteFuncionario()} variant="contained" color="secondary" autoFocus>
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
        {modalShow3.show ? (
            <ModalEdit
                open={modalShow3.show}
                onClose={() => setModalShow3({
                    show: false,
                    id: 0
                })}
                maxWidth="md"
                fullWidth={true}
                empresa={state.empresa}
                contrato={state.contrato}
                filial={state.filial}
                consulta={consultar_funcionarios}
                id={modalShow3.id}
            />
        ):null}
        {modalShow ? (
            <ModalAdd
                open={modalShow}
                onClose={() => setModalShow(false)}
                maxWidth="md"
                fullWidth={true}
                empresas={empresas}
                contratos={contratos}
                filiais={filiais}
                funcaoAnt={() => consultar_funcionarios()}
            />
        ):null}
         <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    </>
);
}

export default Body;