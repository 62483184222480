import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { login, setMat, setEmpresa, setusertype, setusernivel, setUser, setContrato2, setFilial2 } from "../services/auth";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import logo_icon from '../img/icon.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import CircularProgress from '@material-ui/core/CircularProgress';


import { Logo, Logo2 } from './styles';

import api from '../services/api';
import "@fontsource/roboto";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const ColorButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
    width: "80%"
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '80%',
  },
  bt: {
    marginTop: 40,
  },
  title: {
    fontFamily: 'roboto'
  }
}));

export default function Login({ history }) {
  const classes = useStyles();
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const useStyles1 = makeStyles(theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const [loadding, setLoadding] = useState(true);
  function onShow() {
    setLoadding({ show: true })
  }
  function onHide() {
    setLoadding({ show: false })
  }
  function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
  MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  };


  async function handleSubmit(e){
    e.preventDefault();
    var usuario = values.user;
    var senha = values.password;
    if(!usuario || !senha){
      handleOpen4();
    }else{
      onShow();
      const response = await api.post('/login/cilog', {
        usuario,
        senha
      })
      if(response.data.login){
        const { id_func, token, empresa, type, nivel, contrato, filial } = response.data;
        login(token);
        setMat(id_func);
        setEmpresa(empresa);
        setContrato2(contrato);
        setFilial2(filial);
        setusertype(type);
        setusernivel(nivel);
        setUser(usuario);
        history.push(`/home`)
      }else{
        onHide()
        handleOpen3();
      }
      
    }
  }
  const [values, setValues] = useState({
    user: '',
    password: '',
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {loadding.show ? (
        <Logo2>
          <img src={logo_icon} alt="" />
          <CircularProgress />
        </Logo2>
      ) : (
        <Logo>
            <form onSubmit={handleSubmit}>
                <img src={logo_icon} alt="CILog" />
                <h2 className={classes.title}>CILog</h2>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <InputLabel htmlFor="standard-adornment-password">Usuario</InputLabel>
                  <Input
                    id="login_user"
                    type="text"
                    value={values.user}
                    onChange={handleChange('user')}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
                  <Input
                    id="login_password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <LockOpenIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <ColorButton className={classes.bt} variant="contained" color="primary" type="submit">
                  Login
                </ColorButton>
            </form>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open3}
              autoHideDuration={6000}
              onClose={handleClose3}
            >
              <MySnackbarContentWrapper
                onClose={handleClose3}
                variant="error"
                message="Usuario ou senha invalidos!"
              />
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open4}
              autoHideDuration={6000}
              onClose={handleClose4}
            >
              <MySnackbarContentWrapper
                onClose={handleClose4}
                variant="warning"
                message="Preencha todos os campos!"
              />
            </Snackbar>
            <div style={{fontSize: "10px", marginTop: "50px", color: "#959595"}}>
              Desenvolvido por <a href='https://diegoguterresdasilva.github.io/portifolio/'>Diego Guterres da Silva</a>
            </div>
        </Logo>
      )}
      </>
  );
}
