import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    background: #FFF;
    border-radius: 5px;
    margin-bottom: 10px;
    float: left;
    min-width: 100%;
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
`;

export const Header = styled.div`
    position: relative;
    padding: 15px;
    border-bottom: 2px solid;
    border-color: #ecf1f8;
    color: #777
`;

