import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import Select from '@material-ui/core/Select';
import Styles from '../../Styles';

import DialogTitle from '../Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser} from "../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalEdit2(props) {
    const classes = Styles();
    var {id, consulta, onClose, empresa, ...rest} = props;
    const [open, setOpen] = useState(true);

    const [formMapa, setMapa] = useState({
        error: false,
        helperText: '',
    })
    const [formKm, setKm] = useState({
        error: false,
        helperText: '',
    })
    const [formMot, setMot] = useState({
        error: false,
        helperText: '',
    })

    useEffect (() => {
        async function carrega_dados(){
            setOpen(true);
            await api.get('/checkrelatorio/' + id,
                { 
                    headers: { 

                            user: getUser(),
                            tipo: 2,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                console.log(response.data)
                let { data, placa, tipo, mapa, km, motorista} = response.data;
                document.getElementById('check_data').value = data;
                document.getElementById('check_placa').value = placa;
                document.getElementById('check_tipo').value = tipo;
                document.getElementById('check_mapa').value = mapa;
                document.getElementById('check_km').value = km;
                document.getElementById('check_mot').value = motorista;
            });
        }
        carrega_dados();
      }, [id]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salva_alt_check(){
        var mapa = document.getElementById('check_mapa').value;
        var km = document.getElementById('check_km').value;
        var motorista = document.getElementById('check_mot').value;
        setMapa({
            error: false,
            helperText: '',
        })
        setKm({
            error: false,
            helperText: '',
        })
        setMot({
            error: false,
            helperText: '',
        })
       
        if(mapa === ""){
            setMapa({
                helperText: 'Digite um mapa ou dt.',
                error: true
            })
            return false;
        }
        if(km === ""){
            setKm({
                helperText: 'Digite o hodometro do carro.',
                error: true
            })
            return false;
        }
        if(motorista === ""){
            setMot({
                helperText: 'Digite a matricula do motorista.',
                error: true
            })
            return false;
        }
        setOpen(true);
        api.get('/checkrelatorio/'+id+'/edit',
        { 
            headers: { 
                empresa,
                user: getUser(),
                tipo: 4,
                mapa, 
                km, 
                motorista, 
            } 
        })
        .then(function(response){
            onClose();
            consulta();
            setOpen(false);
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle onClose={onClose} open4={false} open={open}>Alterar Check List</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="check_data">Data</InputLabel>
                               <TextField
                                size="small"
                                    id="check_data"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="check_placa">Placa</InputLabel>
                               <TextField
                                size="small"
                                    id="check_placa"
                                    type="text"
                                    disabled
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group className={classes.disabled}> 
                                <InputLabel className={classes.linha} htmlFor="check_tipo">Tipo</InputLabel>
                                <Select
                                native
                                className={classes.textField}
                                id="check_tipo"
                                size="small"
                                disabled
                                >
                                    <option value={1}>Saida</option>
                                    <option value={2}>Entrada</option>
                                </Select>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="check_mapa">Mapa/DT</InputLabel>
                               <TextField
                                size="small"
                                error={formMapa.error}
                                helperText={formMapa.helperText}
                                    id="check_mapa"
                                    type="number"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="check_km">Hodometro</InputLabel>
                               <TextField
                                size="small"
                                error={formKm.error}
                                helperText={formKm.helperText}
                                    id="check_km"
                                    type="number"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={4}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="check_mot">Mat Motorista</InputLabel>
                               <TextField
                                size="small"
                                error={formMot.error}
                                helperText={formMot.helperText}
                                    id="check_mot"
                                    type="number"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            {open ? false : (
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => {salva_alt_check()}}>Salvar</Button>
                </DialogActions>
            )}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalEdit2;