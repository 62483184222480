const prod = {
    url: {
        API_URL: 'https://www.cilog.com.br/backend/public',
    }
};
const dev = {
    url: {
        API_URL: 'https://www.cilog.com.br/backend/public'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;