import React, {useState, useEffect} from 'react';

import { Logo2 } from '../Treinamentos/styles';
import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';

import CircularProgress from '@material-ui/core/CircularProgress';
import logo_icon from '../../img/icon.png';

import BoardSocorro from '../../components/BoardSocorro';

import {getEmpresas, getContratos, getFilials} from "../../services/auth";

import Styles from '../../components/Styles'

function Socorro({history}) {
    const classes = Styles();
    const [loadding, setLoadding] = useState({ show: true });
    const [empresas, setEmpresas] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [filiais, setFiliais] = useState([]);
    useEffect (() => {
        setEmpresas(JSON.parse(getEmpresas()));
        setContratos(JSON.parse(getContratos()));
        setFiliais(JSON.parse(getFilials()));
        setLoadding( {show: false })
    }, []);
    
  return (
    <>
    {loadding.show ? (
        <Logo2>
            <img src={logo_icon} alt="" />
            <CircularProgress />
        </Logo2>
    ) : (
        <>
            <Hearder history={history} />
            <Menu history={history} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <BoardSocorro
                empresas={empresas}
                contratos={contratos}
                filiais={filiais}
                />
            </main>
        </>
    )}
</>
  );
}

export default Socorro;