import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

// import { Container } from './styles';

const styles2 = StyleSheet.create({
    tab: {
        width: "94%",
        flexDirection: "row",
        border: 1,
        borderColor: "#000",
        padding: 5,
        justifyContent: "space-between"
    },
    
    image: {
        width: 150,
        height: 30
    },
    text: {
        fontSize: 10,
    },
    first: {
        marginTop: 10
    }
});


function Botton({dados}) {
  return (
      <View style={styles2.first}>
        <View style={styles2.tab}>
            <View>
                <Text style={styles2.text}>Responsavel - Nome: {dados.responsavel}</Text>
            </View>
             <View>
             </View>
             <View>
             </View>
            <View >
                <Text style={styles2.text}>Matricula: {dados.funcao}</Text>
            </View>
            <View>
             </View>
             <View >
            </View>
        </View>
        <View style={styles2.tab}>
            <View >
                <Text style={styles2.text}>Observações:</Text>
            </View>
        </View>
    </View>
  );
}

export default Botton;