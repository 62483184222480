import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Logo2 } from '../../Treinamentos/styles';
import logo_icon from '../../../img/icon.png';
import Body from './components/Body';
import {getEmpresas, getContratos, getFilials} from "../../../services/auth";

function Funcionarios() {
    const [empresas, setEmpresas] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [filiais, setFiliais] = useState([]);
    const [loadding, setLoadding] = useState({ show: true });
    useEffect (() => {
        setEmpresas(JSON.parse(getEmpresas()));
        setContratos(JSON.parse(getContratos()));
        setFiliais(JSON.parse(getFilials()));
        setLoadding( {show: false })
    }, []);
    return (
        <>
            {loadding.show ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Body
                empresas={empresas}
                contratos={contratos}    
                filiais={filiais}
                />
            )}
        </>
  );
}

export default Funcionarios;