import React, { useState, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';

import { Logo2 } from "./styles";

import logo_icon from "../../../../../../img/icon.png";
import { getUser } from "../../../../../../services/auth";
import api from "../../../../../../services/api";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  ScheduleComponent,
  Year,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";

import portugues from "cldr-data/main/pt-PT/ca-gregorian.json";
import numbers from "cldr-data/main/pt-PT/numbers.json";
import timeZoneNames from "cldr-data/main/pt-PT/timeZoneNames.json";
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import weekData from "cldr-data/supplemental/weekData.json";

import { loadCldr, L10n } from "@syncfusion/ej2-base";

import moment from "moment";
import { Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

loadCldr(numberingSystems, portugues, numbers, timeZoneNames, weekData);

L10n.load({
  "pt-PT": {
    schedule: {
      today: "Hoje",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  linha: {
    fontSize: 12,
    padding: "5px !important",
  },
  linha2: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    fontSize: 12,
    minHeight: "0px !important",
    padding: 0,
  },
  botao: {
    padding: 0,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    userSelect: "none",
    top: 0,
    marginTop: 0,
    paddingTop: "40%",
    fontSize: 22,
  },
  indicator2: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    userSelect: "none",
    top: 0,
    marginTop: "-20px",
    fontSize: 16,
  },
  progresso: {
    marginTop: "-50px",
  },
}));

function Schedule({ empresas, contratos, filiais, tipos }) {
  const [open, setOpen] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const scheduleObj = createRef();
  const classes = useStyles();
  const [state, setState] = useState({
    empresa: empresas[0].id,
    contrato: contratos[0].id,
    filial: filiais[0].id,
    tipo: 0,
  });
  const handleChange2 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  async function carrega_lista() {
    setOpen(true)
    api
      .get("/treinamento/schedule", {
        params: {
          empresa: state.empresa,
          contrato: state.contrato,
          filial: state.filial,
          tipo: state.tipo,
          user: getUser(),
        },
      })
      .then(function (response) {
        var data = response.data;
        setdataSource(data);
        setOpen(false);
      });
  }

  function onEventRendered(args) {
    applyCategoryColor(args, scheduleObj.currentView);
  }
  function applyCategoryColor(args, currentView) {
    let categoryColor = args.data.CategoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    if (currentView === "Agenda") {
      args.element.firstChild.style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  }
  function onRenderCell1(args) {
    var cellEle = document.querySelectorAll(".e-work-cells");
    var dados = args.result;
    for (var i = 0; i < cellEle.length; i++) {
      var date = parseInt(cellEle[i].getAttribute("data-date"));
      var dateConvert = new Date(date).setHours(0, 0, 0, 0);
      var date4 = new Date(dateConvert).toLocaleDateString("pt-BR");
      var date_temp = date4.split("/");
      var datareal = date_temp[2] + "-" + date_temp[1] + "-" + date_temp[0];
      for (var j = 0; j < dados.length; j++) {
        var date2 = dados[j].EndTime;
        if (datareal === date2) {
          cellEle[i].style.background = dados[j].CategoryColor;
        }
      }
    }
    var cellapp = document.querySelectorAll(".e-appointment");
    for (var y = 0; y < cellapp.length; y++) {
      cellapp[y].remove();
    }
  }

  return (
    <>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs="auto">
            <InputLabel className={classes.linha} htmlFor="abast_empresa">
              Empresa
            </InputLabel>
            <Select
              native
              value={state.empresa}
              onChange={handleChange2}
              className={classes.textField}
              inputProps={{
                name: "empresa",
                id: "abast_empresa",
              }}
            >
              {empresas.map((dados, index) => (
                <option key={dados.id} value={dados.id}>
                  {dados.descr}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs="auto">
            <InputLabel className={classes.linha} htmlFor="abast_contrato">
              Contrato
            </InputLabel>
            <Select
              native
              value={state.contrato}
              onChange={handleChange2}
              className={classes.textField}
              inputProps={{
                name: "contrato",
                id: "abast_contrato",
              }}
            >
              {contratos.map((dados, index) => (
                <option key={dados.id} value={dados.id}>
                  {dados.descr}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs="auto">
            <InputLabel className={classes.linha} htmlFor="abast_filial">
              Filial
            </InputLabel>
            <Select
              native
              value={state.filial}
              onChange={handleChange2}
              className={classes.textField}
              inputProps={{
                name: "filial",
                id: "abast_filial",
              }}
            >
              {filiais.map((dados, index) => (
                <option key={dados.id} value={dados.id}>
                  {dados.descr}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs="auto">
            <InputLabel className={classes.linha} htmlFor="tipo_treina">
              Negócio
            </InputLabel>
            <Select
              native
              value={state.tipo}
              onChange={handleChange2}
              className={classes.textField}
              inputProps={{
                name: "tipo",
                id: "tipo_treina",
              }}
            >
              <option value={0}>Todos</option>
              {tipos.map((dados, index) => (
                <option key={dados.id} value={dados.id}>
                  {dados.descr}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Consultar">
              <IconButton
                aria-label="Consultar"
                onClick={() => carrega_lista()}
                color="primary"
                variant="contained"
              >
                <SearchIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Form>
      <Box component="span" m={1}>
        {open ? (
          <Logo2>
            <img src={logo_icon} alt="" />
            <CircularProgress />
          </Logo2>
        ) : (
          <ScheduleComponent
            width="100%"
            height="90%"
            selectedDate={new Date(moment().format("YYYY-MM-DD"))}
            eventSettings={{ dataSource }}
            eventRendered={onEventRendered.bind(this)}
            dataBinding={onRenderCell1.bind(this)}
            ref={scheduleObj}
            locale="pt-PT"
          >
            <ViewsDirective>
              <ViewDirective
                option="Year"
                showWeekNumber={true}
                readonly={true}
              />
            </ViewsDirective>
            <Inject services={[Year]} />
          </ScheduleComponent>
        )}
      </Box>
    </>
  );
}

export default Schedule;
