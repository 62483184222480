import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Form } from 'react-bootstrap';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBinoculars } from '@fortawesome/free-solid-svg-icons'

import DialogTitle from '../../../../../components/BoardCheck/Components/DialogTitle'
import api from '../../../../../services/api'

import { Logo2 } from './styles';
import logo_icon from '../../../../../img/icon.png';

import { getUser} from "../../../../../services/auth";

import Style from '../../../../../components/Styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModalAdd(props) {
    const classes = Style();
    const [funcionarios, setFuncionarios] = useState([]);
    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(false);
    var {empresa, contrato, filial, onClose, funcao, ...rest} = props;

    const [formFuncionario, setformFuncionario] = useState({
        error: false,
        helperText: '',
    })
    const [formUsuario, setformUsuario] = useState({
        error: false,
        helperText: '',
    })
    const [formEmail, setformEmail] = useState({
        error: false,
        helperText: '',
    })
    useEffect (() => {
        async function carrega_funcionarios(){
            setOpen(true);
            await api.get('/funcionarios/all',
                { 
                    headers: { 
                            empresa, 
                            contrato,
                            filial,
                            user: getUser(),
                            type: 1,
                        } 
                    }
                )
            .then(function (response) {
                setOpen(false);
                setFuncionarios(response.data);
            });
        }
        carrega_funcionarios();
      }, [empresa, 
        contrato,
        filial,]);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    function preencherUsuario(){
        var value = document.getElementById('funcionario_inclui').selectedIndex;
        let optionText = document.getElementById('funcionario_inclui')[value].innerText;
        let nome = optionText.toLowerCase();
        let temp = nome.split(" ");
        if(temp.length > 1){
            let user = temp[0] + "." + temp[1];
            document.getElementById('usuario_incluir').value = user;
            document.getElementById('email_incluir').value = user + "@";
        }
        
    }
    function salva_user(){
        var funcionario = document.getElementById('funcionario_inclui').value;
        var usuario = document.getElementById('usuario_incluir').value;
        var email = document.getElementById('email_incluir').value;
        setformFuncionario({
            error: false,
            helperText: '',
        })
        setformUsuario({
            error: false,
            helperText: '',
        })
        setformEmail({
            error: false,
            helperText: '',
        })
        
        if(funcionario === ""){
            setformFuncionario({
                helperText: 'Selecione um Funcionario.',
                error: true
            })
            return false;
        }
        if(usuario === ""){
            setformUsuario({
                helperText: 'Preencha o usuario.',
                error: true
            })
            return false;
        }
        if(email === ""){
            setformEmail({
                helperText: 'Preencha o e-mail.',
                error: true
            })
            return false;
        }
        setOpen2(true);
        api.post('/usuarios',
        { 
            user: getUser(),
            empresa,
            contrato,
            filial,
            email,
            usuario,
            funcionario
        })
        .then(function(response){
            var {status} = response.data;
            if(status){
                setOpen3({
                    show: true,
                    text: 'Usuário cadastrado com sucesso!',
                    type: "success"
                });
                onClose();
                funcao();
            }else{
                var { message } = response.data;
                if(message != null){
                    for (var [value] of Object.entries(message)) {
                        setOpen3({
                            show: true,
                            text: value,
                            type: "error"
                        });
                    }
                }
            }
            setOpen2(false);
        })
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false} open={open}>Incluir Usuario</DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="funcionario_inclui">Funcionario</InputLabel>
                                <Select
                                native
                                error={formFuncionario.error}
                                size="small"
                                id="funcionario_inclui"
                                onChange={preencherUsuario}
                                className={classes.textField}
                                >
                                    <option value={''}>Selecione...</option>
                                    {funcionarios.map((dados) => (
                                        <option key={dados.id} value={dados.id}>{dados['NOMEFUNC']}</option>
                                    ))}
                                </Select>
                                <FormHelperText  error={formFuncionario.error}>{formFuncionario.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={5}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="usuario_incluir">Usuario</InputLabel>
                               <TextField
                                    size="small"
                                    id="usuario_incluir"
                                    type="text"
                                    error={formUsuario.error}
                                    className={classes.textField}
                                />
                                <FormHelperText  error={formUsuario.error}>{formUsuario.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={7}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="email_incluir">E-mail</InputLabel>
                               <TextField
                                size="small"
                                    id="email_incluir"
                                    type="text"
                                    error={formEmail.error}
                                    className={classes.textField}
                                />
                                <FormHelperText  error={formEmail.error}>{formEmail.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </>
            )}
        </DialogContent>
        {open ? false : (
            <DialogActions>
                <Button color="secondary" disabled={open2} onClick={onClose}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={open2}
                        onClick={salva_user}
                        >
                        Salvar
                    </Button>
                    {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        </Dialog>
  );
}

export default ModalAdd;