import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';

import CircularProgress from '@material-ui/core/CircularProgress';
import logo_icon from '../../img/icon.png';
import { Logo2 } from '../Treinamentos/styles';

import {getEmpresas, getContratos, getFilials, getOpes} from "../../services/auth";
import Body from './Body';

const drawerWidth = 63;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        marginLeft: drawerWidth,
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function AtualizarOS({ history }) {
    const [loadding, setLoadding] = useState({ show: true });
    const classes = useStyles();
    const [empresas, setEmpresas] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [filiais, setFiliais] = useState([]);
    const [opes, setOpes] = useState([]);
    useEffect (() => {
        setEmpresas(JSON.parse(getEmpresas()));
        setContratos(JSON.parse(getContratos()));
        setFiliais(JSON.parse(getFilials()));
        setOpes(JSON.parse(getFilials(getOpes())));
        setLoadding( {show: false })
    }, []);
    return (
        <>
            {loadding.show ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    <Hearder history={history} />
                    <Menu history={history} />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Body 
                            empresas={empresas}
                            contratos={contratos}
                            filiais={filiais}
                            opes={opes}
                        />
                    </main>
                </>
            )}
        </>
    );
}

export default AtualizarOS;