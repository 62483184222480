import React, {useState, useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import api from '../../../../services/api'
import { getOrientation } from 'get-orientation/browser'
import Styles from '../../../Styles';

import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from './canvasUtils'

import { Logo2 } from './styles';

import logo_icon from '../../../../img/icon.png';

import { getUser} from "../../../../services/auth";

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }
  
function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
}

function ModalImage(props) {
    const classes = Styles();
    var {id, empresa, contrato, filial, onClose, setImage, ...rest} = props;
    const [open, setOpen] = useState(false);

    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          let imageDataUrl = await readFile(file)
    
          // apply rotation if needed
          const orientation = await getOrientation(file)
          const rotation = ORIENTATION_TO_ANGLE[orientation]
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
          }
    
          setImageSrc(imageDataUrl)
        }
    }

    function makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < 8; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    const enviar_image = useCallback(async () => {
        try {
            setOpen(true);
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            fetch(croppedImage)
            .then( response => response.blob() )
            .then( blob =>{
                var reader = new FileReader() ;
                reader.onload = function(){ 
                    fetch(this.result)
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], "File name",{ type: "image/png" })
                        var rand = makeid();
                        var end = "/fotos/temp/" + rand + ".jpg";
                        var formData = new FormData();
                        formData.append("image", file);
                        formData.append("empresa", empresa);
                        formData.append("contrato", contrato);
                        formData.append("filial", filial);
                        formData.append("endereco", end);
                        formData.append("user", getUser());
                        formData.append("id_ver", id);
                        api.post('http://www.cilog.com.br/backend/public/imagemitenschecklist',
                        formData,{
                            headers: {
                                'Content-Type': 'multipart/form-data'
                                }
                        })
                        .then(function(response){
                            setImage('http://www.cilog.com.br/backend/public/images/itens/' + response.data);
                            setOpen(false);
                            onClose2();
                        }).catch(error => {
                            console.log(error.response)
                        });
                    })
                } ; // <--- `this.result` contains a base64 data URI
                reader.readAsDataURL(blob) ;
            }) ;


        } catch (e) {
          console.error(e)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [imageSrc, croppedAreaPixels, rotation])

    const onClose2 = useCallback(() => {
        onClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Imagem</DialogTitle>
            <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <input
                                type='file'
                                accept='image/jpg'
                                onChange={onFileChange}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            {imageSrc ? (
                                <>
                                    <div className={classes.cropContainer}>
                                        <Cropper
                                            image={imageSrc}
                                            crop={crop}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={1 / 1}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onRotationChange={setRotation}
                                            onZoomChange={setZoom}
                                            />
                                    </div>
                                    <div className={classes.controls}>
                                        <div className={classes.sliderContainer}>
                                            <Typography
                                                variant="overline"
                                                classes={{ root: classes.sliderLabel }}
                                            >
                                                Zoom
                                            </Typography>
                                            <Slider
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                classes={{ container: classes.slider }}
                                                onChange={(e, zoom) => setZoom(zoom)}
                                            />
                                            </div>
                                        <div className={classes.sliderContainer}>
                                            <Typography
                                                variant="overline"
                                                classes={{ root: classes.sliderLabel }}
                                            >
                                                Rotation
                                            </Typography>
                                            <Slider
                                                value={rotation}
                                                min={0}
                                                max={360}
                                                step={1}
                                                aria-labelledby="Rotation"
                                                classes={{ container: classes.slider }}
                                                onChange={(e, rotation) => setRotation(rotation)}
                                            />
                                        </div>
                                    </div>
                                </>
                            ): null}
                        </Grid>
                    </Grid>
            )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose2} >Cancelar</Button>
                <Button color="primary" variant="contained" onClick={() => {enviar_image()}}>Enviar</Button>
            </DialogActions>
    </Dialog>
  );
}

export default ModalImage;