import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../services/api'
import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Styles from '../../Styles';
import Select from '@material-ui/core/Select';

import DialogTitle from '../Components/DialogTitle'

import { Logo2 } from './styles';

import logo_icon from '../../../img/icon.png';

import { getUser, getusertype} from "../../../services/auth";
import { Box, FormHelperText, IconButton, Tooltip } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ModalAddDocumentTipo(props) {
    const classes = Styles();
    var {empresa, contrato, filial, ope, consulta, onClose, ...rest} = props;
    const [open, setOpen] = useState(true);
    const [form, setForm] = useState({
        error: false,
        helperText: '',
    })
    const [formBloqueia, setFormBloqueia] = useState({
        error: false,
        helperText: '',
    })
    const [formAnexo, setFormAnexo] = useState({
        error: false,
        helperText: '',
    })
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState([]);
    const [lista, setLista] = useState(false);
    var type = getusertype();
    const loadData = () =>{
        setOpen(true);
        api.get('/checklist/documentotipo',
        { 
            headers: { 
                    empresa,
                    contrato,
                    filial,
                    user: getUser(),
                    ope,
                } 
            }
        )
        .then(function (response) {
            setOpen(false);
            let {status, data} = response.data;
            if(status === true){
                let where2 = [];
                let dados = data[0];
                for(let colunas in dados) {
                    let o2 = { 
                        dataField: colunas,
                        text: colunas, 
                    };
                    where2.push(o2);
                }
                console.log(where2)
                setColumns(where2);
                setProducts(data);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
        });
    }
    useEffect (() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };

    function salvar(){
        let nome = document.getElementById('nome_doc_add').value;
        let bloqueia = document.getElementById('bloqueia_tipo_add').value;
        let anexo = document.getElementById('anexo_tipo_add').value;
        setForm({
            error: false,
            helperText: '',
        })
        setFormBloqueia({
            error: false,
            helperText: '',
        })
        setFormAnexo({
            error: false,
            helperText: '',
        })
        if(nome == ''){
            setForm({
                helperText: 'Selecione a placa!',
                error: true
            })
            return false;
        }
        if(bloqueia == ''){
            setFormBloqueia({
                helperText: 'Selecione se bloqueia o checklist ou não!',
                error: true
            })
            return false;
        }
        if(anexo == ''){
            setFormAnexo({
                helperText: 'Selecione se o documento pode ter anexo!',
                error: true
            })
            return false;
        }
        setOpen(true);
        api.post('/checklist/documentotipo',
        {
            user: getUser(),
            empresa,
            contrato,
            filial,
            nome,
            bloqueia,
            anexo
        })
        .then(function(response){
            setOpen(false);
            let {status, message} = response.data;
            if(status === true){
                setOpen3({
                    show: true,
                    text: 'Cadastrado com sucesso!',
                    type: "success"
                });
                loadData();
            }else{
                setOpen3({
                    show: true,
                    text: message,
                    type: "error"
                });
            }
        })
    }
    return (
        <Dialog
            {...rest}
            aria-labelledby="form-dialog-title"
            >
                <DialogTitle onClose={onClose} open4={false} open={open}>Adicionar Tipo de Documento</DialogTitle>
                <DialogContent className={classes.root}>
                {open ? (
                    <Logo2>
                        <img src={logo_icon} alt="" />
                        <CircularProgress />
                    </Logo2>
                ) : (
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={6}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="nome_doc_add">Nome</InputLabel>
                               <TextField
                                size="small"
                                error={form.error}
                                helperText={form.helperText}
                                    id="nome_doc_add"
                                    type="text"
                                    className={classes.textField}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="bloqueia_tipo_add">Bloqueia</InputLabel>
                                <Select
                                    native
                                    error={formBloqueia.error}
                                    size="small"
                                    id="bloqueia_tipo_add"
                                    className={classes.textField}
                                    >
                                        <option value={0}>Não</option>
                                        <option value={1}>Sim</option>
                                </Select>
                                <FormHelperText  error={formBloqueia.error}>{formBloqueia.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                        <Form.Group> 
                                <InputLabel className={classes.linha} htmlFor="bloqueia_tipo_add">Anexo</InputLabel>
                                <Select
                                    native
                                    error={formAnexo.error}
                                    size="small"
                                    id="anexo_tipo_add"
                                    className={classes.textField}
                                    >
                                        <option value={0}>Não</option>
                                        <option value={1}>Sim</option>
                                </Select>
                                <FormHelperText  error={formAnexo.error}>{formAnexo.helperText}</FormHelperText>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <Button color="primary" variant="contained" onClick={() => {salvar()}}>Salvar</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="span" m={1}>
                                {lista && (
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                {columns.map((dados, index) => (
                                                    <Table.ColHeader className={classes.cabecario} key={dados.text}>{dados.text}</Table.ColHeader>
                                                ))}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {products.map((dados, index) => (
                                            <Table.Row key={dados.id}>
                                                {columns.map((column, index) => {
                                                    var name = column.text;
                                                    if(name !== "bloqueia" ){
                                                        if(name !== "anexo" ){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);      
                                                        }else{
                                                            if(dados[name] == 1){
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>Sim</Table.Col>);
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>Não</Table.Col>);
                                                            }
                                                        }
                                                    }else{
                                                        if(dados[name] == 1){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>Sim</Table.Col>);
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>Não</Table.Col>);
                                                        }
                                                    }
                                                })}
                                                {/* <Table.Col>
                                                    {type === "a" && (
                                                        <div key={dados.id + 'actiontipo' + index}>
                                                            <Tooltip title="Alterar">
                                                                <IconButton  size="small"  onClick={() => {}} aria-label="Edit" variant="contained">
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </Table.Col> */}
                                            </Table.Row>
                                        ))}
                                        </Table.Body>
                                    </Table>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                </DialogContent>
                {open ? false : (
                    <DialogActions>
                        <Button color="secondary"  onClick={onClose}>Fechar</Button>
                    </DialogActions>
                )}
                <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                    <Alert onClose={handleClose3} severity={open3.type}>
                        {open3.text}
                    </Alert>
                </Snackbar>
        </Dialog>
    );
}

export default ModalAddDocumentTipo;