import React, {useState} from 'react';
import { Container2, Logo2,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import Acompanhamento from './components/Acompanhamento'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { getUser} from "../../../../services/auth";


import { Form } from 'react-bootstrap';

import logo_icon from '../../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { IconContext } from "react-icons";
import { FaStar } from 'react-icons/fa';

import "tabler-react/dist/Tabler.css";
import { Table } from "tabler-react"; 
import { CSVLink } from "react-csv";

import ModalLista from './components/ModalLista';
import ModalAdd from '../ModalAdd';
import Schedule from './components/Schedule';
import ModalDetals from './components/ModalDetals';

import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Treinamento from './components/Treinamento';
import Execucao from './components/Execucao';
import Quiz from './components/Quiz';

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    botao: {
        padding: 0,
    }
  }));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Body({empresas, contratos, filiais, opes, tipos, anos}) {
    const classes = useStyles();
    const [columns, setColumns] = useState(null);
    const [newId, setNewId] = useState({
        'status': false,
        'id': 0,
        'new': 0,
        'idAgenda': 0,
    });
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState({
        show: false,
        id: 0,
    });
    const [modalAdd, setModalAdd] = useState(false);
    const [dateini, setDateini] = useState("");
    const [datefin, setDatefin] = useState("");
    const [open4, setOpen4] = useState(false);
    const [id_temp, setId_temp] = useState(0);
    const handleClickOpen4 = (id) => {
        setOpen4(true);
        setId_temp(id);
    };
    const handleClose4 = () => {
        setOpen4(false);
        setId_temp(0);
    };

    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
   
    function consultar_abastecimento(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
        if(dateini === "" || datefin === ""){
            setOpen3({
                show: true,
                text: 'Preencha todos os campos para consultar!',
                type: 'warning'
            });
            return false
        }
        handleToggle();
        var {empresa, contrato, filial, tipo, ope} = state;
        var dataini = dateini;
        dataini = dataini.split("-").reverse().join("/");
        var datafin = datefin;
        datafin = datafin.split("-").reverse().join("/");
        api.get('/treinamento/client',
            { 
                headers: { 
                        empresa, 
                        dataini, 
                        datafin,
                        contrato,
                        filial,
                        tipo,
                        ope,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            var data = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== ''  && json !== []){
                let where2 = [];
                json.forEach(dados => {
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                var uniques = [];
                var itemsFound = {};
                for(var i = 0, l = where2.length; i < l; i++) {
                    var stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns(uniques);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);


    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
        tipo: 0,
        ope: 0,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
        
    };
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    function listafunc(id){
        setModalShow({
            show: true,
            id
        })
    }
    function del_treina(){
        if(id_temp !== 0){
            handleToggle();
            api.get('/treinamento/ex-treina_' + id_temp + '/edit',
                { 
                    headers: { 
                            user: getUser(),
                        } 
                    }
                )
            .then(function (response) {
                // eslint-disable-next-line eqeqeq
                if(response.data != ""){
                    setOpen3({
                        show: true,
                        text: response.data,
                        type: 'error'
                    });
                }
                handleClose4();
                consultar_abastecimento();
            });
        }
    }
    function addAgendaFunc({idTreina, idAgenda}){
        setNewId({
            'status': true,
            'id': idTreina,
            'new': 1,
            'idAgenda': idAgenda,
        });
       
    }

    return (
    <Container2>
        <Header>
            <Grid container spacing={3}>
                <Grid item xs="auto">
                    Treinamentos
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title="Incluir Treinamento">
                        <Button
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            size="small"
                            startIcon={<AddCircleIcon fontSize="inherit" />}
                            onClick={() => setModalAdd(true)}
                        >
                            Cadastrar
                        </Button>
                        
                    </Tooltip>
                </Grid>
            </Grid>
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Relatorio"  {...a11yProps(0)} />
            <Tab label="Calendario"  {...a11yProps(1)} />
            <Tab label="% Treinamento"  {...a11yProps(2)} />
            <Tab label="Execução Treinamento"  {...a11yProps(3)} />
            <Tab label="Presença"  {...a11yProps(4)} />
            <Tab label="Quiz"  {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="tipo_treina">Negócio</InputLabel>
                        <Select
                        native
                        value={state.tipo}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'tipo',
                            id: 'tipo_treina',
                        }}
                        >
                            <option value={0}>Selecione...</option>
                            {tipos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="tipo_treina">Ope</InputLabel>
                        <Select
                        native
                        value={state.ope}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'ope',
                            id: 'ope_treina',
                        }}
                        >
                            <option value={0}>Selecione...</option>
                            {opes.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dataini">Data Inicio</InputLabel>
                        <TextField
                            type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="mesfim" 
                            value={dateini}
                            onChange={(event) => setDateini(event.target.value)}
                            inputProps={{
                                name: 'dataini',
                                id: 'dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="dataini">Data final</InputLabel>
                        <TextField
                            type="date"
                            className={classes.textField}
                            locale='pt-PT' 
                            id="mesfim" 
                            value={datefin}
                            onChange={(event) => setDatefin(event.target.value)}
                            inputProps={{
                                name: 'dataini',
                                id: 'dataini',
                            }}
                            placeholder='dd/mm/aaaa'
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar_abastecimento()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"treinamentos.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                </Grid>
            </Form>
            <Box component="span" m={1}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    {lista && (
                        <>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        {columns.map((dados, index) => {
                                            if(dados.text !== "id"){
                                                return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((dados, index) => (
                                        <Table.Row key={dados.id + dados.Data}>
                                            {columns.map((column, index) => {
                                                var name = column.text;
                                                if(name !== "id"){
                                                    if(name === "Av. Treina" || name === "Av. Instru"){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    {dados[name] && (
                                                                        <>
                                                                            {dados[name]}
                                                                            <IconContext.Provider value={{ size: "1.1em"}}>
                                                                                <FaStar/>
                                                                            </IconContext.Provider>
                                                                        </>
                                                                        )}
                                                                </Table.Col>); 
                                                    }else{
                                                        if(name === "Participantes"){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                    <div className={classes.linha2}>
                                                                        <Button className={classes.botao} aria-label="Participantes" size="small" onClick={() => listafunc(dados.id)}>
                                                                            {dados[name]}
                                                                            <GroupIcon fontSize="small"/>
                                                                        </Button>
                                                                    </div>
                                                            </Table.Col>);
                                                        }else{
                                                            if(name === "Excluir"){
                                                                // eslint-disable-next-line eqeqeq
                                                                if(dados[name] != 0 && dados[name] != null){
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                            <div className={classes.linha2}>
                                                                                <Button className={classes.botao} color="secondary" aria-label="Excluir" size="small" onClick={() => handleClickOpen4(dados[name])}>
                                                                                    <DeleteForeverIcon fontSize="small"/>
                                                                                </Button>
                                                                            </div>
                                                                    </Table.Col>);
                                                                }else{
                                                                    return (<Table.Col className={classes.linha} key={dados.id + column.text}></Table.Col>); 
                                                                }
                                                            }else{
                                                                return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>); 
                                                            }
                                                        }
                                                    }
                                                }
                                                return false;
                                            })}
                                              <Table.Col className={classes.linha} key={dados.id}>
                                                  <div className={classes.linha2}>
                                                        <Tooltip title="Detalhes">
                                                            <IconButton  
                                                            size="small"
                                                            color="primary" 
                                                            key={dados.id} 
                                                            onClick={() => setNewId({
                                                                'status': true,
                                                                'id': dados.id
                                                            })}
                                                            aria-label="Detalhes">
                                                                <FindInPageIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                            </Table.Col>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </>
            )}
            </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Schedule empresas={empresas} contratos={contratos} filiais={filiais} tipos={tipos} />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <Treinamento empresas={empresas} contratos={contratos} filiais={filiais} tipos={tipos} anos={anos} />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <Execucao empresas={empresas} contratos={contratos} filiais={filiais} tipos={tipos} anos={anos} />
        </TabPanel>
        <TabPanel value={value} index={4}>
            <Acompanhamento empresas={empresas} contratos={contratos} filiais={filiais} tipos={tipos} anos={anos} />
        </TabPanel>
        <TabPanel value={value} index={5}>
            <Quiz empresas={empresas} contratos={contratos} filiais={filiais} tipos={tipos} anos={anos} />
        </TabPanel>
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        {modalShow ? (
            <ModalLista
            open={modalShow.show}
            onClose={() => setModalShow({
                show: false,
                id: 0
            })}
            url="/treinamento/lista/"
            maxWidth="md"
            fullWidth={true}
            empresa={state.empresa}
            contrato={state.contrato}
            filial={state.filial}
            id={modalShow.id}
        />
        ):null}
        {modalAdd ? (
            <ModalAdd
            open={modalAdd}
            onClose={() => setModalAdd(false)}
            maxWidth="md"
            fullWidth={true}
            empresa={state.empresa}
            contrato={state.contrato}
            filial={state.filial}
            clientes={opes}
            areas={tipos}
            addAgendaFunc={addAgendaFunc}
        />
        ):null}
        {newId.status ? (
            <ModalDetals
            open={newId.status}
            onClose={() => setNewId({
                'status': false,
                'id': 0
            })}
            maxWidth="md"
            fullWidth={true}
            empresa={state.empresa}
            contrato={state.contrato}
            filial={state.filial}
            id={newId}
        />
        ):null}
        <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Essa operação não pode ser desfeita!
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose4} color="default">
                Cancelar
            </Button>
            <Button onClick={() => del_treina()} variant="contained" color="secondary" autoFocus>
                Excluir
            </Button>
        </DialogActions>
        </Dialog>
    
    </Container2>
    );
}

export default Body;