import React, {useState, useRef, useEffect} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import CustomizedProgressBars from './components/CustomizedProgressBars'

import {L10n} from '@syncfusion/ej2-base';

import DialogTitle from './../Body/components/DialogTitle'

import { getUser} from "../../../../services/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

L10n.load({
    "pt-PT": {
        "uploader": {
            "Browse"  : "Selecionar arquivo",
            "Clear" : "Limpar",
            "Upload" : "Enviar",
            "cancel": "Cancelar",
            "delete": "Apagar",
            "dropFilesHint" : "ou solte os arquivos aqui",
            "inProgress": "Téléchargement",
            "invalidFileType" : "Tipo de arquivo não permitido",
            "uploadFailedMessage" : "Não foi possivel enviar o aquivo!",
            "uploadSuccessMessage" : "Arquivo enviado com sucesso!",
            "readyToUploadMessage": "Pronto para Enviar!",
        }
    }
})

const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    }
  }));

function ModalAdd(props) {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    let uploadObj = useRef(UploaderComponent);
    var {empresa, contrato, filial, addAgendaFunc, onClose, clientes, areas,...rest} = props;

    const [formTitulo, setTitulo] = useState({
        error: false,
        helperText: '',
    })
    const [formObj, setObj] = useState({
        error: false,
        helperText: '',
    })
    const [formArea, setArea] = useState({
        error: false,
        helperText: '',
    })
    const [formCliente, setcliente] = useState({
        error: false,
        helperText: '',
    })
    const [formData, setData] = useState({
        error: false,
        helperText: '',
    })
    const [formHora, setHora] = useState({
        error: false,
        helperText: '',
    })
    const [formInstrutor, setInstrutor] = useState({
        error: false,
        helperText: '',
    })
    const [formTipo, setTipo] = useState({
        error: false,
        helperText: '',
    })



    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
   
    function get_func(){
        var mat_mot = document.getElementById('mat_incluir').value;
        if(mat_mot !== "" && mat_mot !== "0"){
            api.get('/funcionarios/' + mat_mot,
                { 
                    headers: { 
                            user: getUser(),
                            empresa, 
                        } 
                    }
                )
            .then(function (response) {
                var {NOMEFUNC} = response.data;
                document.getElementById('nome_incluir').value = NOMEFUNC;
            });
        }else{
            document.getElementById('nome_incluir').value = "";
        }
    }
    function salvar(){
        var titulo = document.getElementById('titulo_incluir').value;
        var objetivo = document.getElementById('objetivo_incluir').value;
        var tipo = document.getElementById('area_inclui').value;
        var ope = document.getElementById('cliente_inclui').value;
        var data = document.getElementById('data').value;
        var carga = document.getElementById('hora_incluir').value;
        var mat = document.getElementById('mat_incluir').value;
        var online = document.getElementById('tipo_inclui').value;
        setTitulo({
            error: false,
            helperText: ''
        });
        setObj({
            error: false,
            helperText: '',
        });
        setArea({
            error: false,
            helperText: '',
        });
        setcliente({
            error: false,
            helperText: '',
        });
        setData({
            error: false,
            helperText: '',
        });
        setHora({
            error: false,
            helperText: '',
        });
        setInstrutor({
            error: false,
            helperText: '',
        });
        setTipo({
            error: false,
            helperText: '',
        });
        if(titulo === 0 || titulo === ""){
            setTitulo({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(objetivo === 0 || objetivo === ""){
            setObj({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(tipo === 0 || tipo === ""){
            setArea({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(ope === 0 || ope === ""){
            setcliente({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(data === 0 || data === ""){
            setData({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(carga === 0 || carga === "" || carga < 0){
            setHora({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(mat === 0 || mat === ""){
            setInstrutor({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        if(online === 0 || online === ""){
            setTipo({
                error: true,
                helperText: 'Campo obrigatório!',
            })
            return false;
        }
        setVisivel2(false);
        var formData = new FormData();
        // eslint-disable-next-line eqeqeq
        if(online == 2){
            const filesData = uploadObj.getFilesData();
            // eslint-disable-next-line eqeqeq
            if(filesData.length == 0){
                setOpen3({
                    show: true,
                    text: "É obrigatorio enviar um arquivo de video para treinamentos online.",
                    type: 'error'
                });
                return false;
            }
            formData.append('video', filesData[0].rawFile);
        }
        let date = data.split("-").reverse().join("/");
        formData.append('empresa', empresa);
		formData.append('contrato', contrato);
		formData.append('filial', filial);
		formData.append('ope', ope);
		formData.append('titulo', titulo);
		formData.append('objetivo', objetivo);
		formData.append('data', date);
		formData.append('carga', carga);
		formData.append('mat', mat);
		formData.append('tipo', tipo);
		formData.append('user', getUser());
		formData.append('online', online);
        var config = {
            onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
				//nanobar.go( percentCompleted );
                setProgress(percentCompleted);
            }
		};
        
        api.post('/treinamento',
        formData, 
        config)
        .then(function(response){
            var {status, data} = response.data;
            if(status === 1){
                addAgendaFunc(data);
                onClose();
            }else{
                setOpen3({
                    show: true,
                    text: data,
                    type: 'error'
                });
                setVisivel2(true)
            }
        })
       
    }


    let onFileSelected = (args) => {
        let existingFiles = uploadObj.getFilesData();
        const filesData = uploadObj.getFilesData();
        if(filesData.length > 0){
            if(args.filesData[0].name !== filesData[0].name){
                uploadObj.filesData.splice(0, 1);
            }
        }
        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
        
    }
    const [visivel, setVisivel] = useState(false)
    const [visivel2, setVisivel2] = useState(true)
    const mudaOnline = (event, reason) => {
        // eslint-disable-next-line eqeqeq
        if(event.target.value == 1){
            setVisivel(false);
        }else{
            setVisivel(true);
        }
    }
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 500);
        return () => clearTimeout(timer);
    }, [debouncedTerm])
    useEffect(() => {
        if(term !== ''){
            get_func()
        }else{

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false} open={false}>Cadastrar Treinamento</DialogTitle>
        <DialogContent className={classes.root}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="titulo_incluir">Titulo</InputLabel>
                       <TextField
                            size="small"
                            error={formTitulo.error}
                            id="titulo_incluir"
                            type="text"
                            className={classes.textField}
                        />
                        <FormHelperText  error={formTitulo.error}>{formTitulo.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={9}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="objetivo_incluir">Objetivo</InputLabel>
                       <TextField
                            size="small"
                            id="objetivo_incluir"
                            type="text"
                            className={classes.textField}
                            error={formObj.error}
                        />
                        <FormHelperText  error={formObj.error}>{formObj.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={3}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="area_inclui">Área</InputLabel>
                        <Select
                        native
                        error={formArea.error}
                        size="small"
                        id="area_inclui"
                        className={classes.textField}
                        >
                            <option value={''}>Selecione...</option>
                            {areas.map((dados) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                        <FormHelperText  error={formArea.error}>{formArea.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                
                <Grid item xs={3}>
                    <Form.Group> 
                            <InputLabel className={classes.linha} htmlFor="cliente_inclui">Cliente</InputLabel>
                            <Select
                            native
                            error={formCliente.error}
                            size="small"
                            id="cliente_inclui"
                            className={classes.textField}
                            >
                                <option value={''}>Selecione...</option>
                                {clientes.map((dados) => (
                                    <option key={dados.id} value={dados.id}>{dados.descr}</option>
                                ))}
                            </Select>
                            <FormHelperText  error={formCliente.error}>{formCliente.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={2}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="data">Data</InputLabel>
                       <TextField
                            className={classes.textField}
                            locale='pt-PT' 
                            id="data" 
                            inputProps={{
                                name: 'data',
                                id: 'data',
                            }}
                            type="date"
                            placeholder='dd/mm/aaaa'
                            error={formData.error}
                        />
                        <FormHelperText  error={formData.error}>{formData.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={2}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="hora_incluir">Carga Horária (min)</InputLabel>
                       <TextField
                            size="small"
                            error={formHora.error}
                            id="hora_incluir"
                            type="number"
                            className={classes.textField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <AvTimerIcon />
                                    </InputAdornment>
                                ),
                                }}
                        />
                        <FormHelperText  error={formHora.error}>{formHora.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={2}>
                    <Form.Group> 
                        <InputLabel className={classes.linha} htmlFor="tipo_inclui">Tipo</InputLabel>
                        <Select
                        native
                        error={formTipo.error}
                        size="small"
                        id="tipo_inclui"
                        onChange={mudaOnline}
                        className={classes.textField}
                        >
                            <option value="">Selecione..</option>
                            <option value="1">Presencial</option>
                            <option value="2">Online</option>
                        </Select>
                        <FormHelperText  error={formTipo.error}>{formTipo.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                <Grid item xs={1}>
                    <Form.Group> 
                        <InputLabel className={classes.linha}  htmlFor="mat_incluir">Instrutor</InputLabel>
                       <TextField
                            size="small"
                            error={formInstrutor.error}
                            id="mat_incluir"
                            type="number"
                            className={classes.textField}
                            onChange={e => setDebouncedTerm(e.target.value)}
                            value={debouncedTerm}
                        />
                        <FormHelperText  error={formInstrutor.error}>{formInstrutor.helperText}</FormHelperText>
                    </Form.Group>
                </Grid>
                { /*<Grid item xs={1}>
                    <FontAwesomeIcon icon={faBinoculars} size="2x"/>
                </Grid>*/ }
                <Grid item xs={6}>
                    <Form.Group className={classes.disabled}> 
                        <InputLabel className={classes.linha} htmlFor="nome_incluir">Nome</InputLabel>
                       <TextField
                            size="small"
                            id="nome_incluir"
                            type="text"
                            className={classes.textField}
                            disabled
                        />
                    </Form.Group>
                </Grid>
                {visivel ? (
                    <Grid item xs={5}>
                        <Form.Group className={classes.disabled}> 
                            <UploaderComponent 
                            locale='pt-PT' 
                            id="myfiletreinamento" 
                            type = 'file'
                            ref = {upload => { uploadObj = upload; }}
                            selected={onFileSelected = onFileSelected.bind(this)}
                            allowedExtensions='.m4v, .avi, .mpg, .mp4'
                            maxFileSize= {62914560}
                            />
                        </Form.Group>
                    </Grid>
                ): null}
            </Grid>
        </DialogContent>
        {visivel2 ? (
            <DialogActions>
                <Button color="secondary" onClick={props.onClose}>Cancelar</Button>
                <Button color="primary" variant="contained" onClick={() => salvar()}>Salvar</Button>
            </DialogActions>
        ):(
            <CustomizedProgressBars progress={progress} />
        )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
        </Dialog>
  );
}

export default ModalAdd;