import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Input } from "reactstrap"

import api from '../../../../../../../../services/api'
import { getUser} from "../../../../../../../../services/auth";


import { Logo2 } from './styles';

import logo_icon from '../../../../../../../../img/icon.png';
import DialogTitle from '../../../DialogTitle';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const Filter = ({ column }) => {
    return (
      <div style={{ marginTop: 5 }}>
        {column.canFilter && column.render("Filter")}
      </div>
    )
}
export const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) => {
    return (
      <Input
        bsSize="sm"
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Procurar (${length}) ...`}
      />
    )
}


const useStyles = makeStyles((theme) => ({
    linha: {
        fontSize: 12,
        padding: "5px !important",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        fontSize: 12,
        minHeight: "0px !important",
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflowY: "initial"
    },
    disabled: {
        backgroundColor: '#F4F4F4',
    },
    linha2: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));



function ModalAddAgendaEdit(props) {
    const classes = useStyles();
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [open, setOpen] = useState(false);
   

    var {idTreina, onClose, altera, setAltera, empresa, contrato, filial, id, data, ...rest} = props;
    function salvar_pergunta(){
        var date = document.getElementById('date').value;
        if(date === ""){
            setOpen3({
                show: true,
                text: 'Obrigatorio preencher a data!',
                type: 'warning'
            });
            return false;
        }
        setOpen(true);
        api.get('treinamento/agenda/' + id + '/edit',
        { 
            params: {
                idTreina,
                data: date,
                user: getUser(),
            }
        })
        .then(function(response){
            setOpen(false);
            var data = response.data;
            var {status, msg} = data;
            if(status === true){
                var cont = altera;
                cont++;
                onClose();
                setAltera(cont);
                
            }else{
                setOpen3({
                    show: false,
                    text: msg,
                    type: 'warning'
                });
            }
        });
    }
    return (<Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false} open={open}>
            Alterar Data
        </DialogTitle>
        <DialogContent className={classes.root}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={2}>
                            <Form.Group> 
                            <InputLabel className={classes.linha} 
                            htmlFor="date">Data</InputLabel>
                           <TextField
                                type="date"
                                className={classes.textField}
                                locale='pt-PT' 
                                id="date" 
                                defaultValue={data.split('/').reverse().join('-')}
                                placeholder='dd/mm/aaaa'
                            />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </Grid> 
            )}
            </DialogContent>
            {open !== true ? (
                <DialogActions>
                    <Button color="secondary" onClick={props.onClose}>Cancelar</Button>
                    <Button color="primary" variant="contained" onClick={() => salvar_pergunta()} >Salvar</Button>
                </DialogActions>
            ): null}
            <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity={open3.type}>
                    {open3.text}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default ModalAddAgendaEdit;