import React, {useState} from 'react';
import Usuario from './Usuario'
import { Container2,  Header } from '../PreContra/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Hearder from '../../components/Hearder';
import Menu from '../../components/Menu';

import { makeStyles } from '@material-ui/core/styles';
import Funcionarios from './Funcionarios';

const drawerWidth = 63;
const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        marginLeft: drawerWidth,
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


function Cadastros({ history }) {
  const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <Hearder history={history} />
          <Menu history={history} />
          <Container2>
              <Header>
                  Cadastro
              </Header>
              <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              >
                  <Tab label="Usuarios"  {...a11yProps(0)} />
                  <Tab label="Funcionarios"  {...a11yProps(1)} />
              {/* <Tab label="Veiculos"  {...a11yProps(2)} /> */}
              </Tabs>
              <TabPanel value={value} index={0}>
                  <Usuario history={history} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                  <Funcionarios history={history} />
              </TabPanel>
          </Container2>
        </main>
    );
}

export default Cadastros;